const about_us = {
    title: "Acerca de Nosotros",
    name: "Tay’s Mundo",
    description: "Tay’s World Travel, ahora Tay’s Mundo Travel, fue fundado en Perú en 1998 por nuestra fundadora, la Sra. Dai Lixin, quien emigró a Perú en 1983. Basado en su buena educación en China y Perú, así como 41 años de experiencia de vida en Perú, con 26 años de rica experiencia en viajes, ya sea que visite la vibrante capital Lima o se establezca en Cusco y visite las profundas ruinas incas de Machu Picchu, o aborda un barco fluvial hecho a medida para explorar los misterios de la selva amazónica a lo largo de la poderosa selva. ¡Cada viaje te permitirá sentir la magia, enriquecer tu experiencia y divertirte!\n" +
        "El espíritu de servirle se mantiene inalterable desde hace 26 años: integridad, profesionalismo y seriedad." +
        "El apellido \"Dai\" es herencia y continuación del espíritu de su abuelo Dai Zonghan. En 1968, fue el primer peruano en recibir la \"Medalla Agrícola\" otorgada por el gobierno. Fue elogiado por el entonces presidente como el \"peruano\". Héroe agrícola\". En 1988, recibió el título de \"Ciudadano honorario de Guangzhou\"."
};

const awards = [
    {title: "Mejor Destino Cultural del Mundo", description: "Perú ha sido galardonado como el \"Mejor Destino Cultural del Mundo\" en los World Travel Awards. Con sus impresionantes sitios arqueológicos como Machu Picchu, las Líneas de Nazca y la ciudad sagrada de Caral, Perú es un paraíso para los amantes de la historia y la cultura. ¡Sumérgete en el legado de civilizaciones milenarias y vive la magia del pasado!"},
    {title: "Mejor Atracción Turística del Mundo", description: "Machu Picchu ha sido nombrado la \"Mejor Atracción Turística del Mundo\" en múltiples ocasiones. Este asombroso sitio arqueológico, situado en las alturas de los Andes, es una maravilla que deja sin aliento a todos sus visitantes. Camina por sus antiguas terrazas y descubre los secretos de la civilización inca en este destino mágico."},
    {title: "Mejor Destino de Aventura del Mundo", description: "Perú ha sido premiado como el \"Mejor Destino de Aventura del Mundo\" gracias a su increíble geografía y diversidad de actividades. Desde el senderismo en el Camino Inca y el surf en las playas de Máncora, hasta el sandboarding en las dunas de Huacachina y el rafting en el río Urubamba, Perú es el lugar perfecto para los amantes de la adrenalina."},
    {title: "Mejor Destino Histórico", description: "Perú ha ganado el título de \"Mejor Destino Histórico\" por sus numerosos y bien preservados sitios arqueológicos. Desde las ruinas de Chan Chan, la ciudad de barro más grande del mundo, hasta la misteriosa Fortaleza de Kuelap, cada rincón del país está lleno de historias por descubrir. ¡Revive la historia en el corazón de Sudamérica!"},
    {title: "Mejor Destino Gastronómico de Sudamérica", description: "Además de los premios globales, Perú ha sido nombrado el \"Mejor Destino Gastronómico de Sudamérica\". Su cocina fusiona tradiciones indígenas con influencias europeas, asiáticas y africanas, creando una experiencia culinaria única. ¡Degusta la riqueza de sabores que han puesto a Perú en el mapa gastronómico mundial!"},
    {title: "Mejor Destino para la Observación de Aves", description: "Perú es un paraíso para los observadores de aves y ha sido reconocido como el \"Mejor Destino para la Observación de Aves\". Con más de 1,800 especies de aves, incluidos colibríes, cóndores y aves endémicas, Perú ofrece increíbles oportunidades para los amantes de la ornitología. ¡Prepárate para un espectáculo de colores y cantos!"}
]

const destinations = [
    {title: "Cuzco", description: "Maravíllate con Cuzco, el corazón del antiguo Imperio Inca, una ciudad que es en sí misma una obra de arte viviente. Espléndido en su mezcla de arquitectura Inca y colonial, cada calle y cada plaza te cuentan historias de un pasado glorioso. Descubre el espectacular Templo del Sol, Qoricancha, y explora las ruinas de Sacsayhuamán, donde la historia se siente palpable."},
    {title: "Machu Picchu", description: "No te pierdas la joya de la corona, Machu Picchu, una maravilla del mundo que se alza majestuosamente entre montañas neblinosas, ofreciendo vistas que cortan la respiración y un ambiente mágico que pocas veces se puede experimentar en la vida. Este santuario sagrado, accesible desde Cuzco a través de un viaje espectacular en tren o siguiendo el inolvidable Camino Inca, promete una aventura que es, sin duda, inolvidable. Cuzco y Machu Picchu juntos no solo ofrecen una ventana al pasado Inca, sino también una experiencia profunda que toca el alma de cada visitante."},
    {title: "Puno", description: "Descubre el espléndido Puno, la puerta de entrada al místico Lago Titicaca, el lago navegable más alto del mundo. Sumérgete en una cultura rica y presencia festividades vibrantes que son simplemente inolvidables. Puno no es solo un destino, es una maravilla cultural viva donde el pasado y presente se encuentran en cada danza y tradición."},
    {title: "Lima", description: "La vibrante capital del Perú, Lima es un espléndido mosaico de historia, cultura y modernidad. Desde la majestuosidad de su centro histórico hasta la moderna costa de Miraflores y Barranco, Lima ofrece una experiencia urbana sin igual. Deléitate con su reconocida gastronomía, que te llevará en un viaje culinario espectacular."},
    {title: "Paracas - Ica", description: "Un oasis marino en la costa del desierto peruano, Paracas es simplemente maravilloso. Con su impresionante Reserva Nacional que alberga una diversidad biológica asombrosa y las misteriosas Líneas de Nazca cercanas, Paracas es perfecto para aquellos que buscan combinar aventura y relajación en un entorno natural único. \n" +
            "\n" +
            "A poca distancia, Ica te invita a descubrir sus vastos viñedos y a probar el pisco, el emblemático licor peruano. La ciudad es también la puerta de entrada a la misteriosa Laguna de Huacachina, un oasis espectacular rodeado de enormes dunas de arena donde puedes practicar sandboarding y paseos en buggy."},
    {title: "Iquitos", description: "Más allá de sus límites, embárcate en emocionantes excursiones ecológicas: navega por ríos misteriosos, observa delfines rosados, y conecta con comunidades indígenas que enriquecerán tu comprensión del ecosistema amazónico. Iquitos ofrece una experiencia esencial y espectacular para los aventureros y amantes de la naturaleza."},
    {title: "Huaraz", description: "El paraíso de los aventureros, Huaraz ofrece una experiencia natural espectacular con sus imponentes picos nevados y lagunas de color turquesa en el Parque Nacional Huascarán. Es un destino ideal para los amantes del trekking, montañismo y naturaleza, prometiendo aventuras inolvidables en cada sendero."}
]

const tours = [
    {title: "Programa Cusco ensueño + Tour Valle Sagrado", d: "3D", n: "2N", days: [
            {title: "Día 1: Excursión Machu Picchu", description: "Recepción en Cusco y traslado del aeropuerto a la Estación de Tren, para que pasajeros  puedan tomar el tren rumbo a Machupicchu \n" +
                    "Durante el recorrido apreciará la hermosa vegetación característica de la Selva Alta y  majestuosos nevados, atravesará túneles y finalmente llegara a la estación  de Aguas Calientes, nos dirigirnos a la estación de Buses donde abordaremos al bus de  transporte turístico que nos llevara hasta la ciudadela de Machupicchu¸ (½ horas de viaje  aprox.) posteriormente se les brindará el guiado dentro de Machu Picchu por aprox. 2hs,  dentro del sitio Arqueológico visitaremos los lugares más importantes como las Plazas,  templos, depósitos, escalinatas, fuentes de agua, andenes y puestos de vigilancias,  cementerios, así como el templo del sol, el Intiwatana (Reloj Solar) el templo de las tres  ventanas entre otros encantos. Después la de la visita guiada tendrán tiempo para el almuerzo  en restaurante turístico. A hora indicada abordar el tren de retorno y transfer a  su hotel en Cusco." },
            {title: "Día 2: Excursión Valle Sagrado de los Incas", description: "Este tour inicia con el recojo de los pasajeros de su hotel a las 7:30 am aprox. Luego nos  dirigimos al Valle Sagrado¸ (1 ½ horas de viaje) para visitar los lugares más importantes del  Valle, empezaremos con visitar el centro arqueológico de Pisac, desde donde se puede  apreciar los impresionantes andenes de la época incaica y el pueblo de la misma. Continuando  con el viaje por el Valle Sagrado de los Incas, hacemos una parada, para el almuerzo buffet en  restaurante turístico. Después del Almuerzo a unos (30 min. de viaje) disfrutaremos de la  fortaleza de Ollantaytambo y observaremos las áreas agrícolas; lo que más resalta es el cultivo  de maíz sin riego, también el cultivo de tubérculos andinos y de cereales. Luego nos  trasladaremos al mirador de Chinchero, el último punto del recorrido. Este pueblo es un bello  ejemplo del estilo mestizo y colonial, a hora indicada regreso a Cusco Arribando a las 18.00hrs  aproximadamente."},
            {title: "Día 3: AM City Tours", description: "Entre las 08:00 hrs a 08:20 hrs nuestro transfer lo visitara en su Hotel para trasladarlo al lugar  de inicio de su Tour,. El tour inicia en La Catedral del Cusco, Seguidamente realizaremos una  breve caminata por las calles del centro histórico rumbo al Qoricancha o Templo del Sol  considerado un santuario religioso indígena. Luego abordaremos la movilidad que nos llevara  a Sacsayhuaman ubicada a 3km. de la ciudad del Cusco, Sacsayhuaman nos ofrece una visión  panorámica de la ciudad de Cusco Luego nos dirigiremos a Qenqo, que significa laberinto, se  encuentra ubicado a un kilómetro de Sacsayhuaman, despues conoceremos Puca Pucara:  proveniente de dos palabras quechua que significa Puca (rojo) Pucara (fortaleza) “fortaleza  roja. Finalmente visitaremos Tambomachay. Finalizando en la ciudad del Cusco a las 13:45 hrs  aproximadamente."},
        ]},
    {
        title: "Cusco Encanto", d: "4D", n: "3N", days: [
            {
                title: "Día 1: Excursión city tours",
                description: "Recepción en Cusco y traslado del aeropuerto al hotel de su elección. Entre las 12:45 hrs y 13:00 hrs nuestro transfer lo visitara en su Hotel para trasladarlo al lugar  de inicio de su Tour,. El tour inicia en La Catedral del Cusco, Seguidamente realizaremos una  breve caminata por las calles del centro histórico rumbo al Qoricancha o Templo del Sol  considerado un santuario religioso indígena. Luego abordaremos la movilidad que nos llevara  a Sacsayhuaman ubicada a 3km. de la ciudad del Cusco, Sacsayhuaman nos ofrece una visión  panorámica de la ciudad de Cusco Luego nos dirigiremos a Qenqo, que significa laberinto, se  encuentra ubicado a un kilómetro de Sacsayhuaman, despues conoceremos Puca Pucara:  proveniente de dos palabras quechua que significa Puca (rojo) Pucara (fortaleza) “fortaleza  roja. Finalmente visitaremos Tambomachay. Finalizando en la ciudad del Cusco a las 18:30 hrs  aproximadamente."
            },
            {
                title: "Día 2: Montaña de colores",
                description: "• 04:30 hrs: Recojo desde su hotel, inicio del tour al Full Day Montaña de Colores . • 05:00 hrs: Traslado en bus turístico hacia el distrito de Cusipata. \n" +
                    "• 06:30 hrs: Arribo a Chirihuani (Cusipata), desayuno y receso. \n" +
                    "• 08:30 hrs: Arribo a Phulawasipata. \n" +
                    "• 08:45 hrs: Inicio de la caminata hacia la Montaña de Colores Vinicunca. • 10:30 hrs: Exploración como fotografias en Vinicunca, reseña y explicación del guía. • 11:30 hrs: Caminata hacia Phulawasipata y retorno a Cusipata. \n" +
                    "• 14:00 hrs: Tiempo libre para almorzar (incluido) . \n" +
                    "• 15:00 hrs: Hora de salida del bus desde Cusipata a Cusco. \n" +
                    "• 16:30 hrs: Arribo a la ciudad del Cusco.\n"
            },
            {
                title: "Día 3: Excursión Machu Picchu",
                description: "Desayuno en el Hotel. A hora indicada traslado de su Hotel a la estación de tren donde se inicia su viaje a Machu  Picchu en tren de su elección. Durante el recorrido apreciará la hermosa vegetación  característica de la Selva Alta y majestuosos nevados, atravesará túneles y finalmente llegara  a la estación de Aguas Calientes, nos dirigirnos a la estación de Buses donde abordaremos al  bus de transporte turístico que nos llevara hasta la ciudadela de Machupicchu¸ (½ horas de  viaje aprox.) posteriormente se les brindará el guiado dentro de Machu Picchu por aprox. 2hs,  dentro del sitio Arqueológico visitaremos los lugares más importantes como las Plazas,  templos, depósitos, escalinatas, fuentes de agua, andenes y puestos de vigilancias,  cementerios, así como el templo del sol, el Intiwatana (Reloj Solar) el templo de las tres  ventanas entre otros encantos. Después la de la visita guiada tendrán tiempo para el almuerzo  en restaurante turístico. A hora indicada abordar el tren de retorno y transfer a  su hotel en Cusco."
            },
            {
                title: "Día 4: Traslado al aeropuerto", description: "Desayuno en el Hotel  \n" +
                    "A hora indicada traslado de salida. \n" +
                    "Fin de los servicios.\n"
            }
        ]
    },
    {
        title: "Taquile Ensueño (Puno)", d: "3D", n: "2N", days: [
            {title: "Día 1: PM Exc. CHULLPAS DE SILLUSTANI", description: "Arribo a la ciudad de Puno y traslado a hotel elegido. CHULLPAS DE SILLUSTANI Son monumentales edificaciones de carácter funerario pre-incas  la elevación de algunas hasta de 12 Mts. Labradas en piedra volcánica, La Chullpa del Lagarto, La de  los 12 ángulos. Todo el complejo arqueológico se encuentra a orillas del Lago UMAYO, el mismo que  subterráneamente se comunica con el Lago Titicaca, a hora indicada regreso a Puno y transfer su  Hotel. "},
            {title: "Día 2: Exc. UROS Y TAQUILE", description: "07:00 Hrs. Traslado del hotel con dirección al puerto, para abordar nuestra lancha a motor y visitar las  islas flotantes de Los Uros, que están a 30 minutos aproximadamente. Después de una visita breve (40  min. apróx.), Continuaremos el viaje hacia la Isla Natural de Taquile. Cuyos pobladores se dedican a la  Agricultura, artesanía y pesca principalmente. Los lugareños continúan viviendo de acuerdo a sus  antiguas tradiciones y leyes Incas con manifestaciones costumbristas, rituales religiosos y folklóricas  como: El matrimonio (Sirvinacuy), el pago a la tierra (Pacha mama)." +
                "12.00 Hrs. (medio día), Almuerzo típico en la isla." +
                "Después del almuerzo: Los Pasajeros, tendrán dos horas libres para visitar el centro artesanal del  pueblo y tomar fotografías con vistas panorámicas y posteriormente retornar a la ciudad de Puno (a  sus respectivos hoteles)."},
            {title: "Día 3: Traslado al aeropuerto", description: "Desayuno en el Hotel  \n A hora indicada traslado al aeropuerto o estación de bus. "}

        ]
    },
    {
        title: "Aventura en la selva amazónica", d: "3D", n: "2N", days: [
            {title: "Día 1: Iquitos - Ceiba Tops", description: "Llegada al aeropuerto o hotel y traslado al muelle de Explorama. Un viaje en bote de 40 km a  lo largo del río Amazonas te lleva a Ceiba Tops, donde habitaciones con aire acondicionado y  baños privados con agua caliente brindan una comodidad similar a la de casa en la selva. Por \n" +
                    "la tarde, disfruta de una caminata por la jungla rodeada de bosque prístino, hogar de los  gigantescos árboles de ceiba, después de los cuales se nombra el lodge. Después de la cena,  realiza una caminata nocturna para observar insectos, ranas, tarántulas y escuchar los sonidos  de la jungla. \n" +
                    "• Comidas: Almuerzo, Cena \n" +
                    "• Alojamiento: Lodge de Lujo en la Selva \n"},
            {title: "Día 2: Ceiba Tops", description: "Observación de aves temprano en la mañana en bote y amanecer sobre el río Amazonas.  Regreso a Ceiba Tops para desayunar. Visita al punto de encuentro de dos ríos, los ríos gris y  rosa. Luego, visita una pequeña comunidad indígena Yagua donde el guía explicará la cultura  Yagua. Disfruta de demostraciones de artesanías locales y técnicas de caza con cerbatana. Por  la tarde, realiza un viaje de pesca y, si el clima lo permite, disfruta de una puesta de sol sobre  el río Amazonas. \n" +
                    "• Comidas: Desayuno, Almuerzo, Cena\n" +
                    "• Alojamiento: Lodge de Lujo en la Selva"},
            {title: "Día 3: Ceiba Tops - Iquitos", description: "Viaje en bote a un pueblo ribereño donde el guía explicará la cultura de los pueblos del río  Amazonas peruano y visitará la escuela del pueblo. Regreso a Iquitos, luego a Lima o al hotel  de su elección.\n" +
                    "• Comidas: Desayuno, Almuerzo (si tu vuelo es por la tarde)\n" +
                    "Nota: El itinerario puede cambiar debido a condiciones climáticas."}
            ]
    }
]

const footer = {
    contact_us: "Contáctenos",
    about_us: "Sobre Nosotros",
    destinations: "Destinos",
    email_us: "Escríbanos",
}

const gastronomy = {
    title: "Gastronomía",
    awards: [
        { title: "“THE WORLD’S BEST FEMALE CHEF AWARD 2021”", subtitle: "Pía León", description: "La copropietaria del exclusivo rest. Central y dueña de Kjolle en Lima, y Mil en Cuzco recibió el premio mejor chef en 2021, en la categoría femenina."}
    ],
    dishes: {
        dishes_costa: [
            {
                title: "Ceviche",
                description: "Sumérgete en la frescura del ceviche, el plato estrella de la costa peruana. Este manjar se prepara con pescado fresco, como corvina o lenguado, marinado en jugo de limón, que \"cocina\" el pescado, dándole una textura firme y un sabor ácido inigualable. Acompañado de cebolla roja, ají limo picante y cilantro fresco, cada bocado es una explosión de sabor. Servido con camote cocido y choclo, el ceviche es una experiencia culinaria refrescante y vibrante que no puedes dejar de probar en tu visita a Perú."
            },
            {
                title: "Lomo Saltado",
                description: "Descubre el lomo saltado, un plato icónico de la cocina peruana que combina lo mejor de la tradición criolla y china. Este delicioso manjar presenta trozos jugosos de lomo de res salteados con cebolla roja, tomate y ají amarillo, todo infusionado con salsa de soja y vinagre para un sabor irresistible. Servido con papas fritas crujientes y arroz blanco, cada bocado ofrece una explosión de texturas y sabores que encantará tu paladar."
            },
            {
                title: "Causa Limeña",
                description: "Disfruta de la causa limeña, una joya de la gastronomía peruana que combina tradición y sabor en cada bocado. Este colorido plato se prepara con capas de puré de papa amarilla sazonado con ají amarillo y limón, relleno de pollo o atún, aguacate y mayonesa. La causa limeña es una fiesta de texturas y sabores, donde la suavidad de la papa se mezcla con el relleno cremoso y el toque fresco del aguacate. Adornada con huevo duro y aceitunas, es tan deliciosa como vistosa."
            },
        ],
        dishes_sierra: [
            {
                title: "Rocoto Relleno",
                description: "Atrévete a probar el rocoto relleno, una explosión de sabor y picante típica de Arequipa. Este plato presenta rocotos rellenos con una sabrosa mezcla de carne molida, cebolla, maní, aceitunas y huevo duro. Horneados y gratinados con queso, estos pequeños volcanes de sabor son acompañados por pastel de papa, ofreciendo un contraste perfecto entre lo picante y lo suave. El rocoto relleno es una experiencia culinaria intensa que te hará enamorarte de la cocina arequipeña."
            },
            {
                title: "Pachamanca",
                description: "Sumérgete en la tradición ancestral con la pachamanca, un festín auténtico de la sierra peruana. Este plato se cocina en un horno subterráneo con piedras calientes, donde se colocan carnes marinadas (res, cordero, cerdo y pollo), papas, camote, habas y choclo, todo envuelto en hojas de plátano. El resultado es una comida rica en sabores ahumados y terrosos, con carnes jugosas y vegetales tiernos que absorben los aromas naturales de la cocción en tierra. La pachamanca no es solo un plato, es una experiencia cultural que celebra la conexión con la tierra y las antiguas tradiciones andinas."
            },
            {
                title: "Cuy Chactado",
                description: "El cuy chactado es una de las preparaciones más tradicionales y simbólicas de la sierra peruana. El cuy se aplasta y se fríe en abundante aceite hasta que queda crujiente por fuera y jugoso por dentro. Este plato es sazonado con ajo y comino, y se sirve típicamente con papas sancochadas y ensalada. En muchas comunidades andinas, el cuy es más que un alimento; es parte de celebraciones y rituales. Su sabor único y su textura crocante lo hacen un plato apreciado y buscado por aquellos que desean experimentar la auténtica cocina peruana de altura."
            },
        ],
        dishes_selva: [
            {
                title: "Tacacho con Cecina",
                description: "Deléitate con el tacacho con cecina, un plato robusto y lleno de sabor típico de la selva peruana. El tacacho se hace con plátanos verdes asados y machacados, mezclados con manteca de cerdo, y se sirve junto a cecina, una carne de cerdo salada y secada al sol. La combinación de la suavidad del tacacho y la intensidad de la cecina crea una experiencia culinaria inolvidable. Este plato es una representación auténtica de los sabores intensos y la herencia culinaria de la Amazonía."
            },
            {
                title: "Inchicapi",
                description: "Sumérgete en los sabores únicos del inchicapi, una sopa tradicional amazónica hecha con pollo, maíz molido, maní, yuca y cilantro. Este plato es espeso y cremoso, con un sabor que combina lo terroso del maní y la frescura del cilantro. El inchicapi es una comida reconfortante que refleja la rica biodiversidad y los ingredientes únicos de la selva peruana. Ideal para aquellos que buscan una experiencia culinaria auténtica y nutritiva."
            },
            {
                title: "Juane",
                description: "Descubre el juane, un plato emblemático de la selva peruana que deleitará tus sentidos. Este manjar se prepara con arroz sazonado con cúrcuma, mezclado con jugosos trozos de pollo, aceitunas y huevo duro, todo envuelto en hojas de bijao y cocido al vapor. El juane es una explosión de sabores y aromas que te transportará al corazón de la Amazonía. Las hojas de bijao infunden al arroz y al pollo un toque distintivo y aromático, creando una experiencia culinaria inolvidable. Este plato es tradicionalmente disfrutado durante la fiesta de San Juan, pero es tan delicioso que se disfruta todo el año."
            },
        ]
    },
    restaurants: {
        lima: {
            name: "Lima",
            data: [
                {
                    name: "La Rosa Náutica",
                    photo: "restaurants/image20.png",
                    description: "Icono de Lima, ubicado en el muelle de Miraflores, ofrece cocina peruana e internacional con vistas espectaculares del océano Pacífico."
                },
                {
                    name: "Huaca Pucllana",
                    photo: "restaurants/image26.png",
                    description: "Restaurante ubicado junto a la Huaca Pucllana, ofrece cocina peruana contemporánea con vistas a un sitio arqueológico precolombino."
                },
                {
                    name: "Panchita",
                    photo: "restaurants/image2.png",
                    description: "Parte del grupo de Gastón Acurio, ofrece comida criolla peruana con un toque casero, destacando anticuchos, tamales y chicharrones."
                },
                {
                    name: "Maido",
                    photo: "restaurants/image18.png",
                    description: "Un restaurante nikkei, liderado por el chef Mitsuharu Tsumura, reconocido mundialmente por su fusión de la cocina japonesa y peruana.\n"
                },
                {
                    name: "Osaka",
                    photo: "restaurants/image10.png",
                    description: "Especializado en cocina nikkei, combina técnicas japonesas con ingredientes peruanos, creando platos innovadores y sofisticados."
                },
                {
                    name: "Astrid & Gastón",
                    photo: "restaurants/image27.png",
                    description: "Fundado por Gastón Acurio, es un emblema de la alta cocina peruana, con un enfoque en ingredientes locales y técnicas modernas."
                },
                {
                    name: "Rafael Restaurante",
                    photo: "restaurants/image6.png",
                    description: "Dirigido por Rafael Osterling, ofrece una fusión de cocina peruana e internacional con presentaciones elegantes."
                },
                {
                    name: "La Mar",
                    photo: "restaurants/image15.png",
                    description: "Cebichería de Gastón Acurio, famosa por sus ceviches frescos y otros platos marinos, siendo un referente en la cocina peruana."
                },
                {
                    name: "Isolina Taberna Peruana",
                    photo: "restaurants/image12.png",
                    description: "Taberna en Barranco que ofrece comida criolla peruana tradicional en porciones generosas, con un ambiente familiar y acogedor."
                },
                {
                    name: "Mayta",
                    photo: "restaurants/image1.png",
                    description: "Restaurante de Jaime Pesaque, que fusiona la cocina peruana moderna con técnicas contemporáneas y presentaciones artísticas."
                },
                {
                    name: "El Charrúa Restaurante Parrilla",
                    photo: "restaurants/image14.png",
                    description: "Especializado en carnes a la parrilla al estilo uruguayo y argentino, ofrece cortes premium en un ambiente clásico."
                },
                {
                    name: "Cosme",
                    photo: "restaurants/image23.png",
                    description: "Restaurante contemporáneo que ofrece una fusión de sabores peruanos e internacionales, con un ambiente moderno y relajado."
                },
                {
                    name: "Amoramar",
                    photo: "restaurants/image13.png",
                    description: "Ubicado en Barranco, ofrece cocina peruana contemporánea con un enfoque en mariscos frescos y creativos, en un ambiente casual."
                },
                {
                    name: "Kjolle",
                    photo: "restaurants/image21.png",
                    description: "Liderado por Pía León, se centra en la biodiversidad peruana, utilizando ingredientes locales para crear platos únicos y modernos."
                },
                {
                    name: "Fiesta Restaurant Gourmet Lima",
                    photo: "restaurants/image29.png",
                    description: " Restaurante que trae la cocina tradicional norteña de Chiclayo a Lima, famoso por su arroz con pato y platos del norte."
                },
                {
                    name: "Troppo",
                    photo: "restaurants/image11.png",
                    description: "Restaurante casual que ofrece una fusión de cocina peruana e italiana, conocido por sus pastas, pizzas y platos peruanos."
                },
                {
                    name: "Titi",
                    photo: "restaurants/image22.png",
                    description: " Uno de los chifas más emblemáticos de Lima, combina sabores cantoneses con ingredientes peruanos, siendo un referente de la cocina fusión."
                },
                {
                    name: "Four Seas International",
                    photo: "restaurants/image4.png",
                    description: "Restaurante de china con un enfoque en platos cantoneses tradicionales."
                }
            ]
        },
        cusco: {
            name: "Cuzco",
            data: [
                {
                    name: "Cicciolina",
                    photo: "restaurants/image5.png",
                    description: "Conocido por su fusión de cocina peruana e internacional, ubicado en el corazón de Cusco.",
                },
                {
                    name: "Chicha por Gastón Acurio",
                    photo: "restaurants/image7.png",
                    description: "Un restaurante del famoso chef peruano que ofrece cocina tradicional andina con un toque moderno."
                },
                {
                    name: "MAP Café",
                    photo: "restaurants/image28.png",
                    description: "Ubicado en el Museo de Arte Precolombino, este restaurante ofrece platos elegantes inspirados en la cocina peruana."
                },
                {
                    name: "Limo Cocina Peruana & Pisco Bar",
                    photo: "restaurants/image19.png",
                    description: "Famoso por su oferta de platos peruanos con influencia japonesa y un excelente bar de pisco."
                },
                {
                    name: "Incanto",
                    photo: "restaurants/image8.png",
                    description: "Un restaurante que combina la cocina italiana con la peruana, utilizando ingredientes locales frescos."
                },
                {
                    name: "Uchu Peruvian Steakhouse",
                    photo: "restaurants/image3.png",
                    description: "Especializado en carnes y parrillas con un enfoque en técnicas de cocina peruana."
                },
                {
                    name: "Kion Peruvian Chinese",
                    photo: "restaurants/image25.png",
                    description: "Fusión de la cocina peruana y china (chifa), ubicada en el centro histórico de Cusco."
                }
            ]
        },
        arequipa: {
            name: "Arequipa",
            data: [
                {
                    name: "La Nueva Palomino",
                    photo: "restaurants/image17.png",
                    description: "Famoso por su comida tradicional arequipeña, como el rocoto relleno y la ocopa."
                },
                {
                    name: "KAO",
                    photo: "restaurants/image30.png",
                    description: "Conocido por su comida fusión peruana tailandesa, donde disfrutarás de una explosión de sabor."
                },
                {
                    name: "Zig Zag",
                    photo: "restaurants/image16.png",
                    description: "Especializado en carnes y parrillas, con una propuesta que incluye platos de alpaca, res, y cordero."
                },
                {
                    name: "Sol de Mayo",
                    photo: "restaurants/image24.png",
                    description: "Uno de los restaurantes más tradicionales de Arequipa, conocido por su ambiente familiar y comida típica arequipeña."
                },
                {
                    name: "La Trattoria del Monasterio",
                    photo: "restaurants/image9.png",
                    description: "Ubicado en el Monasterio de Santa Catalina, este restaurante ofrece una mezcla de cocina italiana y peruana."
                },
                {
                    name: "El Tío Darío",
                    photo: "restaurants/image31.png",
                    description: "Conocido por sus platos marinos y su ambiente agradable con vistas a los volcanes de Arequipa."
                }
            ]
        }
    }
}

const menu = [
    {name: "Inicio", url: "/?l=1"}, {name: "Destinos", url: "/destinations"}, {name: "Gastronomía", url: "/gastronomy"}
];

const world_destinations = {
    title: "Destinos",
    header: "Descubre nuestros destinos en ",
    destinations: [
        {name: 'Asia', value: 0},
        {name: 'Sudamérica', value: 1},
        {name: 'Europa', value: 2},
    ],
    asia: {
        name: "Asia",
        data: [
            {
                name: "China",
                flag: "china.png",
                data: [
                    {
                        name: "China Clasica",
                        duration: "12 días",
                        options: [
                            {
                                name: "Opción 1",
                                description: "",
                                days: [
                                    {
                                        name: "01 JUE. Shanghái",
                                        description: "Llegada. ¡Bienvenido a China! Traslado al hotel y tiempo libre. Recibirás información sobre el inicio del circuito durante la tarde."
                                    },
                                    {
                                        name: "02 VIE. Shanghái",
                                        description: "Visita de Shanghái. Visitamos la zona de Pudong y cruzaremos el río Huangpu en ferry. Visita al Jardín Yuyuan, ceremonia del té y paseo por el barrio de Xintiandi. Almuerzo incluido. Visitaremos el animado barrio de Tianzifang y el Bund. Tiempo libre."
                                    },
                                    {
                                        name: "3 SÁB. Shanghái - Luoyang",
                                        description: "Traslado a la estación. Tren hacia Luoyang entre hermosos paisajes. Almuerzo incluido. Visita a las Cuevas Budistas de Longmen y paseo en barco. Posteriormente, paseo por la antigua ciudad de Luoyang."
                                    },
                                    {
                                        name: "04 DOM. Luoyang - Monasterio del Caballo Blanco - Sanmexia - Xian",
                                        description: "Visita al Monasterio del Caballo Blanco. Continuamos hacia Sanmexia, junto al río Amarillo. Visita al Templo Boulon y al Museo Guo. Almuerzo incluido. Llegada a Xian al final de la tarde."
                                    },
                                    {
                                        name: "05 LUN. Xian",
                                        description: "Salida para visitar el Museo donde se encuentra el Ejército de Terracota, con sus miles de soldados de arcilla. Posteriormente, visita a Xian, con la Pagoda del Ganso Salvaje, curso de caligrafía china, Barrio Musulmán, Mezquita y Torre del Tambor."
                                    },
                                    {
                                        name: "06 MAR. Xian",
                                        description: "Después del desayuno, fin de nuestros servicios."
                                    }
                                ]
                            },
                            {
                                name: "(Opt. 2) China Clásica",
                                description: "Días 1 - 5 como en la Opción 1",
                                days: [
                                    {
                                        name: "06 MAR. Xian, Lingfen, Mansión del Clan Wang, Pingyao",
                                        description: "Tren hacia Lingfen y visita a la Mansión del Clan Wang. Almuerzo incluido. Llegada y visita a Pingyao, la ciudad histórica mejor conservada de China."
                                    },
                                    {
                                        name: "07 MIÉ. Pingyao - Wutai",
                                        description: "Viajamos a uno de los lugares más espirituales de China: la Montaña Wutai, visitando el Templo Nanshan. Montañas verdes y bosques. Tiempo libre. Cena incluida."
                                    },
                                    {
                                        name: "08 JUE. Wutai - Yanmenguan - Yinkxian - Templo Colgante - Datong",
                                        description: "Un camino de gran belleza escénica. Llegada a Yanmenguan y visita a una parte de la Gran Muralla. Salida hacia Yingxian, con su gran pagoda de madera. Almuerzo incluido. Visita al Templo Colgante, una increíble construcción en un acantilado. Llegada a Datong al final del día."
                                    },
                                    {
                                        name: "09 VIE. Datong - Yungang - Jiming Post - Pekín",
                                        description: "Visitamos las Cuevas de Yungang, el conjunto de cuevas más impresionante de China. Salida hacia Jiming Post, un pueblo tradicional protegido por poderosas murallas. Continuamos hacia Pekín. Llegada a media tarde. Fin de nuestros servicios. Revisa el horario de tu vuelo en caso de necesitar una noche extra. El traslado de salida al aeropuerto no está incluido."
                                    },
                                    {
                                        name: "10 SÁB. Pekín",
                                        description: "Visita de día completo a Pekín, donde visitaremos el Palacio Imperial, el Parque Jingshan, la Colina de Carbón, la Plaza Tian An Men, y el Palacio de Verano. Recorrido en triciclo por los barrios tradicionales, conocidos como Hutong. Almuerzo incluido."
                                    },
                                    {
                                        name: "11 DOM. Pekín",
                                        description: "Excursión a la impresionante Gran Muralla China (sector Mutianyu). Posteriormente, continuamos con las visitas en Pekín del Distrito 798 y el Templo del Cielo. Experiencia de masaje de pies y clase de Tai Chi. Cena de pato lacado incluida."
                                    },
                                    {
                                        name: "12 LUN. Pekín",
                                        description: "Después del desayuno, fin de nuestros servicios."
                                    }
                                ]
                            }
                        ],
                        services_included: "Viaje en autobús con guía de habla inglesa, seguro de viaje básico y desayuno buffet.\n" +
                            "Incluye traslado de llegada\n" +
                            "Barco: Paseo en barco en Longmen.\n" +
                            "Tour por la ciudad en: Shanghái, Xian.\n" +
                            "Traslado nocturno: Ciudad antigua en Luoyang.\n" +
                            "Entradas incluidas: Jardín Yuyuan y demostración de la ceremonia del té en Shanghái, Templo del Caballo Blanco en Luoyang, Cuevas budistas de Longmen en Longmen, Templo Baoulon, Museo Guo en Sanmexia, Museo del Ejército de Terracota, Pagoda del Pequeño Ganso Salvaje, curso de caligrafía china, mezquita, espectáculo de instrumentos tradicionales en la Torre del Tambor (no siempre garantizado) en Xian.\n" +
                            "Ferry: Río Huangpu en Shanghái.\n" +
                            "Tren de alta velocidad: Shanghái - Luoyang.\n" +
                            "3 almuerzos o cenas incluidos en: Shanghái, Luoyang, Sanmexia.\n" +
                            "(Servicios adicionales Opción 2)\n" +
                            "Excursión: Paseo en triciclo por el barrio tradicional en Pekín, Gran Muralla China en el sector de Mutianyu en Pekín.\n" +
                            "Tour por la ciudad en: Pekín.\n" +
                            "Traslado nocturno: Calle Wangfujing en Pekín.\n" +
                            "Entradas incluidas: Mansión del Clan Wang, templos, mansiones y murallas en Pingyao, Templo Nanshan en Wutai, Gran Muralla en Yanmenguan, Templo Colgante, Grutas de Yungang en Datong, pequeño asentamiento tradicional chino en Jiming Post, Ciudad Prohibida, Parque Jingshan, Palacio de Verano, Gran Muralla China en Mutianyu, Templo del Cielo, masaje de pies, clase de Tai Chi en Pekín.\n" +
                            "Funicular: Gran Muralla China en Pekín.\n" +
                            "Tren de alta velocidad: Xian - Lingfen.\n" +
                            "5 almuerzos o cenas incluidos en: Mansión del Clan Wang, Wutai, Yinkxian, Pekín, Pekín.\n"
                    },
                    {
                        name: "China’s Beauty",
                        duration: "14 días",
                        days: [
                            {
                                name: "01 - VIE. Pekín.",
                                description: "Bienvenidos a China. Traslado al hotel. Al final de la tarde, vamos a tener el primer contacto con esta impresionante ciudad. Incluimos un traslado a la famosa calle de Wangfujing. Esta calle es una arteria peatonal y comercial llena de vida con muchas tiendas y restaurantes. Cena incluida. Posteriormente, regreso al hotel."
                            },
                            {
                                name: "02 - SÁB. Pekín.",
                                description: "Durante este día visitaremos: El Palacio Imperial, más conocido como “la Ciudad Prohibida”, uno de los patrimonios mundiales. También tendremos la oportunidad de conocer el Parque Jingshan donde se encuentra la Colina del Carbón, uno de los jardines imperiales mejor conservados de Pekín. Ascenderemos a lo alto de la colina para tener las mejores vistas (si el día está despejado) sobre la Ciudad Prohibida. El esfuerzo merecerá la pena. Continuamos hacia la Plaza Tiananmen, una de las mayores del mundo, y desde fuera, apreciaremos el Gran Teatro Nacional, teatro de la ópera de Pekín. Almuerzo incluido. Por la tarde vamos a visitar el Palacio de Verano, que era un jardín veraniego para los de la casa imperial de la Dinastía Qing. Incluimos en la tarde una excursión en triciclo (bicicleta típica de tres ruedas) por los barrios tradicionales de Pekín, conocidos como Hutong. En esta zona podremos ver el estilo tan característico de vida de los residentes en estos barrios populares, visitar tiendas típicas e incluso podremos visitar una casa tradicional de un Hutong, un Siheyuan, con todas sus estancias alrededor de un patio. Regreso al hotel y alojamiento."
                            },
                            {
                                name: "03 - DOM. Pekín - Xian.",
                                description: "Excursión a la Gran Muralla (sección Mutianyu), espectacular y grandiosa obra arquitectónica, su historia cubre más de 2.000 años. Incluimos funicular de subida y bajada a la Gran Muralla. Regreso a la ciudad. Sobre las 15:45 hrs tomamos uno de los modernísimos trenes de alta velocidad de China. A 300 km/hora, en algo más de 5 horas efectuamos los casi 1100 km que separan las dos ciudades. XIAN – Llegada al final de la tarde – traslado al hotel."
                            },
                            {
                                name: "04 - LUN. Xian.",
                                description: "Nos encontramos en la fascinante ciudad donde se originaba la ruta de la seda. A primera hora iremos a conocer el EJÉRCITO DE TERRACOTA con sus miles de soldados de arcilla de tamaño natural que fueron encontrados bajo tierra. Regresamos a XIAN. Posteriormente, visitaremos la Pequeña Pagoda de la Oca Salvaje, donde tendremos ocasión de realizar un breve curso de caligrafía china, un paseo por el centro amurallado, atravesaremos su barrio musulmán y entraremos en la mezquita, podremos con ello observar las múltiples culturas y religiones que han dejado su huella en este país. Incluiremos también la entrada a la Torre del Tambor donde disfrutaremos de un corto espectáculo tocando instrumentos tradicionales (no siempre garantizado). Tiempo libre."
                            },
                            {
                                name: "05 - MAR. Xian - Chengdu.",
                                description: "Salimos de Xian en modernísimo tren de alta velocidad, en algo más de tres horas cubrimos los 700 km que nos separan de CHENGDU. Nada más llegar, iremos al centro de investigación y cría de Osos Pandas. En esta reserva viven 120 pandas gigantes y 76 pandas rojos. Almuerzo incluido. Por la tarde daremos un paseo por el Parque del Pueblo, con su pequeño lago, la población local efectuando Tai Chi y sus casas de té."
                            },
                            {
                                name: "06 - MIÉ. Chengdu.",
                                description: "Hoy incluimos excursión de día completo en la provincia de Sichuan, de agradables paisajes y pueblos tradicionales. En DUJIANGYAN conocemos el tradicional sistema de irrigación, que lleva funcionando más de 2000 años y es patrimonio de la humanidad. QUINGCHENG SHAN, “montaña de la ciudad verde”, es una montaña sagrada taoísta, cuyos senderos entre bosques nos permiten descubrir cuevas, pabellones y centenarios templos de madera. Almuerzo incluido. Finalmente, visitaremos la ciudad antigua de JIEZI con sus callejones con más de mil años de historia. Regreso a CHENGDU."
                            },
                            {
                                name: "07 - JUE. Chengdu - Miao Village - Zhaoxing.",
                                description: "Saldremos en tren, madrugamos (salida prevista de nuestro tren a las 7:38 hrs), nos separan casi mil kilómetros que efectuamos en algo más de cinco horas y media entre magníficos paisajes de montaña (almuerzo picnic en el tren). Llegada a Congjiang, nos encontramos en una magnífica y desconocida zona de China habitada por minorías étnicas. Conocemos el BASHA MIAO Village, en el bosque, con sus casas de madera. Es la única etnia autorizada a llevar armas de fuego, al haber sido tradicionales cazadores de osos. Continuamos a ZHAOXING, la población más importante de la minoría Dong, bellísimo lugar con sus más de 800 viviendas tradicionales. Muchos lo definen como el pueblo más bonito de China. Alojamiento."
                            },
                            {
                                name: "08 - VIE. Zhaoxing - Guanxiao - Chengyang - Longsheng - Guilin.",
                                description: "Hoy efectuaremos una de las etapas más hermosas y emocionantes de su viaje. GUANXIAO, entre sorprendentes pueblos de etnia Dong con su hermosa arquitectura de madera. Este pueblo lleno de encanto y parado en el tiempo es poco frecuentado por el turismo internacional. Veremos su puente colgante, la torre del tambor, y el teatro de la ópera. Paramos también en CHENGYANG, con su magnífico “puente del viento y la lluvia”. Almuerzo incluido. En LONGSHENG iremos a los arrozales de LONJI, patrimonio de la Humanidad. Con un autobús pequeño subiremos a los miradores y podrá bajar un tramo caminando por sendas entre arrozales y maravillosos paisajes. Tras ello, seguimos a GUILIN - Llegada al final de la tarde."
                            },
                            {
                                name: "09 - SÁB. Guilin.",
                                description: "Día completo en GUILIN, una de las ciudades más populares de China con un ambiente muy agradable a orillas de su lago, junto al río Li, y con sus muy comerciales calles del centro urbano. Incluimos visita a la Gruta de las Flautas de Caña, una magnífica cueva en la cual hay numerosas estalactitas y estalagmitas de fantásticas formas que son iluminadas con bellos colores, creando un mundo espectacular en las entrañas de la tierra. Tarde libre, le recomendamos conocer el palacio Jingjiang, “ciudad prohibida en miniatura”. Alojamiento."
                            },
                            {
                                name: "10 - DOM. Guilin - Crucero por el río Li - Yangshuo - Huangyao - Cantón.",
                                description: "Saldremos de Guilin en barco, efectuando un crucero fluvial por el río Li. Los paisajes son espectaculares con sus verdes montañas, los pueblos a las orillas, las rocas sorprendentes y sus picos de formas asombrosas. Almuerzo incluido. Llegamos a YANGSHUO, una ciudad muy turística llena de vida. Damos un paseo antes de seguir por el interior de China hacia HUANGYAO, en un entorno rural. La ciudad antigua se conserva magníficamente con sus pequeñas calles, edificios antiguos, templos; la vida y la actividad de sus habitantes también nos llevarán a otra época. Tiempo para pasear. Continuamos nuestra ruta hacia CANTÓN - Llegada al final del día."
                            },
                            {
                                name: "11 - LUN. Cantón - Zhuhai - Macao.",
                                description: "Cantón, incluimos una visita de unas dos horas en esta pujante metrópolis del sur de China. Pasearemos por la calle Shangiaxju con su mercado tradicional, conoceremos la Casa del Clan Chen de finales del siglo XIX y recorreremos la zona ribereña del río. Tiempo libre y almuerzo incluido. Por la tarde viajamos a ZHUHAI, junto al mar de China, nos detenemos junto al impresionante teatro de la Ópera, construido en un terreno ganado al mar, inaugurado en 2016. Su impresionante forma simboliza el sol y la luna. PASO DE FRONTERA y entrada en MACAO, donde conocemos la zona de los casinos, muchos de ellos del mismo estilo que los existentes en Las Vegas. Su actividad es impresionante. MUY IMPORTANTE: Si en su viaje vuelve a entrar posteriormente en China continental tras su estancia en Macao y Hong Kong, requiere de una visa de doble entrada."
                            },
                            {
                                name: "12 - MAR. Macao - Hong Kong.",
                                description: "MACAO. Incluimos visita de la ciudad, el centro histórico ha sido declarado Patrimonio de la Humanidad. Fue administrado por los portugueses desde el siglo XVI hasta 1999, sus antiguas iglesias, los azulejos en los edificios civiles, su fortaleza y el faro más antiguo de China lo atestiguan. Tiempo libre y almuerzo incluido. Por la tarde tomamos el impresionante puente que comunica Hong Kong con Macao, inaugurado a finales de 2018, una proeza de ingeniería con más de 30 kilómetros de puentes sobre el mar de China, 6 kilómetros de túnel bajo el mar, islas artificiales. Nota: Si el número de participantes en el grupo es menor a 06 personas, el paso de Macao a Hong Kong podrá realizarse en ferry. HONG KONG – Llegada. Tras el alojamiento, tomaremos el Star Ferry para cruzar la bahía. Daremos un breve paseo para ver los edificios iluminados desde la otra orilla. Regreso al área de nuestro hotel. Cena incluida. Alojamiento."
                            },
                            {
                                name: "13 - MIÉ. Hong Kong.",
                                description: "Tras el desayuno efectuamos visita de la ciudad. Durante la visita conoceremos el centro financiero con sus altos rascacielos, visitaremos el Museo de Historia que nos ilustra sobre la historia de la ciudad (periodo colonial, guerra mundial...) y la Avenida de las Estrellas junto al muelle, que nos muestra las celebridades de la industria cinematográfica. Tomaremos el Star Ferry, cruzando la bahía de Hong Kong en barco, y subiremos al Pico Victoria (552 m) desde donde se divisa toda la ciudad. Bajada en Peak Tram. Tras nuestra visita, tarde libre y alojamiento."
                            },
                            {
                                name: "14 - JUE. Hong Kong.",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada\n" +
                            "Visita panorámica en: Pekín, Xian, Cantón, Macao, Hong Kong\n" +
                            "Excursiones: Triciclo por los barrios tradicionales, Gran Muralla China (sector Mutianyu), Dujiangyan, Montaña de Qingcheng y ciudad antigua de Jiezi, Gruta de las Flautas de Caña\n" +
                            "Barco: Paseo en barco por el lago en la Montaña de Qingcheng, Crucero por el río Li\n" +
                            "Traslado nocturno: Calle de Wangfujing, Hong Kong\n" +
                            "Entradas: Ciudad Prohibida, Parque Jingshan, Palacio de Verano, Gran Muralla China (Mutianyu), Museo del Ejército de Terracota, Pequeña Pagoda de la Oca Salvaje, demostración de caligrafía china, mezquita y espectáculo de tambores tradicionales en la Torre del Tambor (no siempre garantizado), Sistema de irrigación de Dujiangyan, Montaña Sagrada de Qingcheng, Pueblo Basha Miao, Visita a la etnia Dong en Zhaoxing, Visita al pueblo Guangxiao de la etnia Dong, Puente del Viento y la Lluvia, Terrazas de Arroz, Gruta de las Flautas de Caña, Ciudad antigua, Casa del Clan Chen, Museo de Historia\n" +
                            "Ferry: Star Ferry en la Bahía de Hong Kong\n" +
                            "Funicular: Gran Muralla China, Montaña de Qingcheng, Pico Victoria (bajada en tranvía)\n" +
                            "Tren de alta velocidad: Pekín - Xian, Chengdu - Congjiang\n" +
                            "8 Almuerzos incluidos en: Pekín, Chengdu, Chengdu, Pueblo Miao, Chengyang, Crucero por el Río Li, Cantón, Macao\n" +
                            "2 Cenas incluidas en: Pekín, Hong Kong"
                    },
                    {
                        name: "China Explorer",
                        duration: "15 días",
                        days: [
                            {
                                name: "01 JUE. Shanghái.-",
                                description: "Llegada. ¡Bienvenido a China! Traslado al hotel y tiempo libre. Recibirá información sobre el inicio del circuito durante la tarde."
                            },
                            {
                                name: "02 VIE. Shanghái.-",
                                description: "Visita de Shanghái. Visitaremos la zona de Pudong y cruzaremos el río Huangpu en ferry. Visita al Jardín Yuyuan, ceremonia del té y paseo por el barrio de Xintiandi. Almuerzo incluido. Visitaremos el animado barrio de Tianzifang y el Bund. Tiempo libre."
                            },
                            {
                                name: "03 SÁB. Shanghái - Luoyang.-",
                                description: "Traslado a la estación. Tren hacia Luoyang entre hermosos paisajes. Almuerzo incluido. Visita a las Cuevas Budistas de Longmen y paseo en barco. Posteriormente, paseo por la antigua ciudad de Luoyang."
                            },
                            {
                                name: "04 DOM. Luoyang - Monasterio del Caballo Blanco - Sanmexia - Xian.-",
                                description: "Visita al Monasterio del Caballo Blanco. Continuamos hacia Sanmexia, junto al río Amarillo. Visita al Templo Boulon y al Museo Guo. Almuerzo incluido. Llegada a Xian al final de la tarde."
                            },
                            {
                                name: "05 LUN. Xian.-",
                                description: "Salida para visitar el Museo donde se encuentra el Ejército de Terracota, con sus miles de soldados de arcilla. Posteriormente, visita a Xian, incluyendo la Pagoda del Ganso Salvaje, un curso de caligrafía china, el Barrio Musulmán, la Mezquita y la Torre del Tambor."
                            },
                            {
                                name: "06 MAR. Xian - Chengdu.-",
                                description: "Tren de alta velocidad hacia Chengdu. Visita al Centro de Investigación y Cría de Osos Panda. Almuerzo incluido. Paseo por el Parque del Pueblo."
                            },
                            {
                                name: "07 MIÉ. Chengdu - Dujiangyan - Qingcheng Shan - Jiezi - Chengdu.-",
                                description: "Excursión de día completo en la provincia de Sichuan, con paisajes agradables y pueblos tradicionales. Visitaremos Dujiangyan y la montaña sagrada de Qingcheng Shan. Almuerzo incluido. Visita a la antigua ciudad de Jiezi."
                            },
                            {
                                name: "08 JUE. Chengdu - Pueblo Miao - Zhaoxing.-",
                                description: "Tren hacia Congjiang (almuerzo tipo picnic en el tren), una magnífica ciudad habitada por minorías étnicas. Visita a las aldeas de Basha Miao, con sus casas de madera, y Zhaoxing, la ciudad más importante de la minoría Dong."
                            },
                            {
                                name: "09 VIE. Zhaoxing - Guangxiao - Chengyang - Longsheng - Guilin.-",
                                description: "Una de las etapas más hermosas y emocionantes. Descubriremos los pueblos de Guanxiao y Chengyang, con su hermosa arquitectura de madera. Almuerzo incluido. En Longsheng iremos a los arrozales de Lonji. Llegada a Guilin al final de la tarde."
                            },
                            {
                                name: "10 SÁB. Guilin.-",
                                description: "Día completo en Guilin. Visita a la Gruta de las Flautas de Caña y tarde libre. Alojamiento."
                            },
                            {
                                name: "11 DOM. Guilin - Crucero por el río Li - Yangshuo - Huangyao - Cantón.-",
                                description: "Crucero por el río Li. Paisajes espectaculares con montañas verdes. Almuerzo incluido. Llegada y paseo en Yangshuo. Salida hacia Huangyao, en un entorno rural, y tiempo para un paseo. Llegada a Cantón."
                            },
                            {
                                name: "12 LUN. Cantón.-",
                                description: "Incluimos una visita de unas dos horas en esta próspera metrópolis del sur de China, visitaremos la Casa del Clan Chen y recorreremos la zona ribereña. Tiempo libre y almuerzo incluido. Fin de nuestros servicios. Verifique el horario de su vuelo en caso de que necesite una noche adicional."
                            },
                            {
                                name: "13 MAR. Cantón - Zhuhai - Macao.-",
                                description: "Visita a Cantón: mercado, Casa del Clan Chen y paseo por la ribera. Tiempo libre y almuerzo incluido. Visita a Zhuhai, junto al mar de China Meridional. Cruce de frontera y entrada en Macao. Paseo por la zona de los casinos."
                            },
                            {
                                name: "14 MIÉ. Macao - Hong Kong.-",
                                description: "Incluimos una visita a Macao. Almuerzo incluido. Cruzaremos el impresionante puente que conecta Hong Kong con Macao sobre el mar de China Meridional. Llegada a Hong Kong. Después de la llegada, tomaremos el Star Ferry para cruzar la bahía. Cena incluida."
                            },
                            {
                                name: "15 JUE. Hong Kong.-",
                                description: "Visita al Centro Financiero, Museo de Historia, Avenida de las Estrellas y cruce de la bahía en ferry. Ascenso al Pico Victoria y descenso en el tranvía del Pico. Tarde libre."
                            },
                            {
                                name: "16 VIE. Hong Kong.-",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Viaje en autobús con guía de habla inglés, seguro de viaje básico y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada\n" +
                            "Excursión: Dujiangyan, Montaña Qingcheng y la ciudad antigua de Jiezi en Chengdu.\n" +
                            "Barco: Paseo en barco en Longmen, Paseo en barco en la Montaña Qingcheng en Chengdu.\n" +
                            "Tour por la ciudad en: Shanghái, Xian.\n" +
                            "Traslado nocturno: Ciudad Antigua en Luoyang.\n" +
                            "Entradas: Jardín Yuyuan y demostración de la ceremonia del té en Shanghái, Templo del Caballo Blanco en Luoyang, Cuevas budistas de Longmen en Longmen, Templo Baoulon, Museo Guo en Sanmexia, Museo del Ejército de Terracota, Pequeña Pagoda de la Oca Salvaje, curso de caligrafía china, mezquita, espectáculo de instrumentos tradicionales en la Torre del Tambor (no siempre garantizado) en Xian, sistema de irrigación tradicional en Dujiangyan, Montaña Sagrada de Qingcheng Shan en Chengdu.\n" +
                            "Ferry: Río Huangpu en Shanghái.\n" +
                            "Funicular: Montaña Qingcheng en Chengdu.\n" +
                            "Tren de Alta Velocidad: Shanghái - Luoyang.\n" +
                            "5 Almuerzos o Cenas Incluidos en: Shanghái, Luoyang, Sanmexia, Chengdu, Chengdu.\n" +
                            "(Servicios adicionales)\n" +
                            "Excursión: Cueva de la Flauta de Caña.\n" +
                            "Barco: Crucero por el río Li.\n" +
                            "Tour por la ciudad en: Cantón.\n" +
                            "Entradas: Pueblo Basha Miao, Pueblo Zhaoxing de la etnia Dong, Pueblo Guangxiao de la etnia Dong, Puente del Viento en Chengyang, Arrozales en Longsheng, Cueva de la Flauta de Caña, Ciudad Antigua de Huangyao en Huangyao, Casa del Clan Chen en Cantón.\n" +
                            "Tren de Alta Velocidad: Chengdu - Congjiang.\n" +
                            "4 Almuerzos o Cenas Incluidos en: Pueblo Miao, Chengyang, Crucero por el río Li, Cantón.\n" +
                            "Tour por la ciudad en: Cantón, Macao, Hong Kong.\n" +
                            "Traslado nocturno: Bahía de Hong Kong en Hong Kong.\n" +
                            "Entradas: Casa del Clan Chen en Cantón, Museo de Historia en Hong Kong.\n" +
                            "Ferry: Star ferry en la Bahía de Hong Kong.\n" +
                            "Funicular: Tranvía del Pico (un solo trayecto) en Hong Kong.\n" +
                            "3 Almuerzos o Cenas Incluidos en: Cantón, Macao, Hong Kong."
                    },
                    {
                        name: "China y Japón",
                        duration: "16 días",
                        options: [
                            {
                                days: [
                                    {
                                        name: "01 DOM. Pekín.",
                                        description: "Bienvenidos a China. Traslado a nuestro hotel en Pekín. A media tarde, traslado a la famosa calle Wangfujing. Cena incluida."
                                    },
                                    {
                                        name: "02 LUN. Pekín.",
                                        description: "Excursión a la impresionante Gran Muralla China (sector Mutianyu). Luego, continuamos con las visitas en Pekín al Distrito 798 y el Templo del Cielo. Experiencia de masaje de pies y clase de Tai Chi. Cena de pato laqueado incluida."
                                    },
                                    {
                                        name: "03 MAR. Pekín.",
                                        description: "Día completo de visita a Pekín, donde visitaremos el Palacio Imperial, el Parque Jingshan, la Colina de Carbón, la Plaza de Tiananmen y el Palacio de Verano. Paseo en triciclo por los barrios tradicionales, conocidos como Hutong. Almuerzo incluido."
                                    },
                                    {
                                        name: "04 MIÉ. Pekín - Nankín.",
                                        description: "Traslado a la estación y tren hacia Nankín. Visita al Mausoleo de Sun Yat-sen y al Templo Linggu. Tiempo libre y alojamiento."
                                    },
                                    {
                                        name: "05 JUE. Nankín - Suzhou - Tongli - Shanghái.",
                                        description: "Llegada a la hermosa y elegante Suzhou y visita al Jardín del Administrador Humilde. Almuerzo incluido. Luego, continuamos hacia la encantadora ciudad de Tongli y paseo en barco. Llegada a Shanghái al final de la tarde."
                                    },
                                    {
                                        name: "06 VIE. Shanghái.",
                                        description: "Visita a Shanghái. Visitaremos la zona de Pudong y cruzaremos el río Huangpu en ferry. Visita al Jardín Yuyuan, ceremonia del té y paseo por el barrio de Xintiandi. Almuerzo incluido. Visitaremos el animado barrio de Tianzifang y el Bund. Tiempo libre."
                                    },
                                    {
                                        name: "07 SÁB. Shanghái - Tokio.",
                                        description: "Después del desayuno, traslado al aeropuerto para tomar su vuelo a Tokio. A última hora de la tarde, tomaremos un viaje en metro al distrito de Shinjuku. Cena incluida."
                                    },
                                    {
                                        name: "08 DOM. Tokio.",
                                        description: "Tour de la ciudad de cinco horas. Almuerzo incluido. Tarde libre."
                                    },
                                    {
                                        name: "09 LUN. Tokio.",
                                        description: "Después del desayuno, fin de nuestros servicios."
                                    }
                                ]
                            },
                            {
                                name: "(Opción 2) Pekín, Shanghái y Japón Esencial",
                                description: "Días 1 - 8 como en la Opción 1.",
                                days: [
                                    {
                                        name: "09 LUN. Tokio - Monte Fuji - Kawaguchiko.",
                                        description: "Visita al Parque Arakurayama Sengen, con las mejores vistas del Monte Fuji. Llegada a Kawaguchiko, una idílica ciudad junto al lago, y paseo en barco. Ascenso al Monte Fuji en autobús. Cena tradicional japonesa. Noche en un Ryokan."
                                    },
                                    {
                                        name: "10 MAR. Kawaguchiko - Iyasi no Sato Nenba - Toyota - Kioto.",
                                        description: "Visita al hermoso pueblo tradicional de Iyasi no Sato Nenba. Después, admiraremos las Cataratas de Shiraito. En la región de Nagoya, incluimos una visita al Museo de Toyota. Llegada a Kioto al final de la tarde."
                                    },
                                    {
                                        name: "11 MIÉ. Kioto.",
                                        description: "Tour por la ciudad de Kioto. Visitaremos el Bosque de Bambú de Arashiyama. Traslado nocturno al barrio de Gion, popular por sus geishas."
                                    },
                                    {
                                        name: "12 JUE. Kioto - Nara - Osaka.",
                                        description: "En Nara visitamos el Templo Todaiji, con su gran Buda. Almuerzo incluido. Luego, visitaremos el Templo Budista de Horyuji. Continuando hacia Osaka, pasearemos por Dotonbori, un barrio lleno de luces de neón."
                                    },
                                    {
                                        name: "13 VIE. Osaka.",
                                        description: "Después del desayuno, fin de nuestros servicios."
                                    }
                                ]
                            },
                            {
                                name: "(Opción 3) Leyendas de China y Japón",
                                description: "Días 1 - 12 como en la Opción 2.",
                                days: [
                                    {
                                        name: "13 VIE. Osaka - Himeji - Jardín Korakuen - Okayama.",
                                        description: "Visitaremos el inmenso Castillo de Himeji. Almuerzo incluido. En Okayama, visitaremos el Jardín Korakuen, uno de los jardines más hermosos de Japón, así como el santuario sintoísta de Kibitsu-Jinja."
                                    },
                                    {
                                        name: "14 SÁB. Okayama - Kurashiki - Hiroshima - Itsukushima - Hiroshima.",
                                        description: "Visita a Kurashiki, con sus canales de agua. Al llegar a Hiroshima, visitaremos el Memorial de la Paz. Tomaremos un ferry a la isla de Miyajima, con su templo parcialmente construido sobre el mar. Regreso a nuestro hotel. Cena incluida."
                                    },
                                    {
                                        name: "15 DOM. Hiroshima.",
                                        description: "Después del desayuno, fin de nuestros servicios."
                                    }
                                ]
                            }
                        ],
                        services_included: "Viaje en autobús con guía en inglés, seguro de viaje básico y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada\n" +
                            "Excursión: Gran Muralla China, sector Mutianyu en Pekín, paseo en triciclo por el barrio tradicional en Pekín.\n" +
                            "Barco: Paseo en barco en Tongli.\n" +
                            "Tour por la ciudad en: Pekín, Shanghái, Tokio.\n" +
                            "Traslado nocturno: Calle Wangfujing en Pekín, Shinjuku en Tokio.\n" +
                            "Entradas: Gran Muralla China, sector Mutianyu; Templo del Cielo; masaje de pies; clase de Tai Chi en Pekín; la Ciudad Prohibida; Parque Jingshan; Palacio de Verano en Pekín; Mausoleo de Sun Yat-sen y Templo Linggu en Nankín; Jardín del Administrador Humilde en Suzhou; Jardín Yuyuan y demostración de la ceremonia del té en Shanghái; Metro en Tokio; Templo Zojoji, Templo Meiji y Templo Sensoji en Tokio.\n" +
                            "Ferry: Río Huangpu en Shanghái.\n" +
                            "Funicular: Gran Muralla China en Pekín.\n" +
                            "Tren de alta velocidad: Pekín - Nankín.\n" +
                            "Vuelos incluidos: Shanghái - Tokio.\n" +
                            "7 almuerzos o cenas incluidos en: Pekín, Pekín, Pekín, Suzhou, Shanghái, Tokio, Tokio.\n",
                        services_additional: "Barco: Lago Kawaguchiko.\n" +
                            "Tour por la ciudad en: Kioto.\n" +
                            "Traslado nocturno: Barrio tradicional de Gion en Kioto, Dotonbori en Osaka.\n" +
                            "Entradas: Parque Arakurayama Sengen en el Monte Fuji; Museo del Pueblo en Iyashi No Sato Nenba; Museo Toyota; Templo Tenryu-ji; Bosque de Bambú de Arashiyama en Kioto; Fushimi Inari; Palacio Imperial; Templo Kinkakuji en Kioto; Templo Todai-ji y Templo Horyu-ji en Nara.\n" +
                            "2 almuerzos o cenas incluidos en: Kawaguchiko, Nara.\n" +
                            "Entradas: Castillo de Himeji; Jardines Korakuen en Okayama; Templo Itsukushima; Museo de la Paz en Hiroshima.\n" +
                            "Ferry: Hiroshima - Miyajima.\n" +
                            "2 almuerzos o cenas incluidos en: Himeji, Hiroshima.\n"
                    },
                    {
                        name: "China Clásica + Japón",
                        duration: "14 días",
                        days: [
                            {
                                name: "01 - JUE.Shanghái.",
                                description: "Bienvenidos a China. Traslado al hotel. Bienvenidos a SHANGHÁI, ciudad cosmopolita con más de 15 millones de habitantes, el mayor puerto comercial y la metrópoli más internacional de China."
                            },
                            {
                                name: "02 - VIE.Shanghái",
                                description: "En nuestra visita incluida, conoceremos el área de Pudong, situada al otro lado del río Huangpu. Hoy es el distrito financiero, y su silueta más famosa es la Torre de la Perla. Incluimos un cruce en ferry por el río de Shanghái desde donde obtendremos magníficas vistas sobre ambas partes de la ciudad. Viajaremos al pasado recorriendo las calles de la Ciudad Vieja y el Jardín Yuyuan, un jardín privado construido durante la dinastía Ming. Disfrutaremos de una demostración sobre la ceremonia del té, una bebida que, a través de su tradición, olor y sabor, desempeña un papel cultural importantísimo en China. Pasaremos por uno de los barrios más de moda de la capital, Xintiandi. Almuerzo incluido. Recorreremos el barrio de Tianzifang, una antigua zona residencial ubicada en el área de la Concesión Francesa, conocida por sus estrechas y laberínticas callejuelas tradicionales, con sus pequeñas tiendas de artesanía y galerías de arte. Finalizamos la visita en el malecón, más conocido como el Bund, con sus edificios decimonónicos de estilo europeo. Recomendamos, en su tiempo libre, un paseo en barco por la noche para ver Shanghái iluminado; será una experiencia inolvidable."
                            },
                            {
                                name: "03 - SAB.Shanghái - Luoyang.",
                                description: "Traslado a la estación. Tomamos un tren de alta velocidad (previsto tren con salida a las 7:17 hrs), en algo más de cinco horas, cubriendo los 1000 km que nos separan de LUOYANG. Almuerzo incluido. Conoceremos las impresionantes cuevas budistas de LONGMEN, donde, a lo largo de más de un kilómetro, en la pared rocosa del río, se tallaron más de 100,000 imágenes de Buda. Incluiremos un paseo en barco. Tras ello, traslado a la ciudad vieja, penetrando a través de su impresionante muralla, y podremos disfrutar de la gran actividad tradicional y sus iluminadas calles."
                            },
                            {
                                name: "04 - DOM.Luoyang - Monasterio del Caballo Blanco - Sanmenxia - Xian",
                                description:"A primera hora, a las afueras de Luoyang, visitaremos el MONASTERIO BUDISTA DEL CABALLO BLANCO, un extenso monasterio y lugar de estudio al cual llegan budistas de todos los países del mundo. Continuamos nuestra ruta hacia SANMENXIA, donde veremos el río Amarillo, conoceremos el templo Baoulon con su elevada pagoda, y visitaremos uno de los mejores museos de China: el museo Guo, con los carros funerarios del antiguo estado de Guo. Almuerzo incluido. Continuamos hacia el este, pasando al pie del monte Hua, una montaña sagrada. XIAN, llegada al final de la tarde."
                            },
                            {
                                name: "05 - LUN.Xian",
                                description: "Nos encontramos en la fascinante ciudad donde se originaba la ruta de la seda. A primera hora, conoceremos el EJÉRCITO DE TERRACOTA, con sus miles de soldados de arcilla de tamaño natural que fueron encontrados bajo tierra. Regresamos a XIAN. Posteriormente, visitaremos la Pequeña Pagoda de la Oca Salvaje, donde tendremos ocasión de realizar un breve curso de caligrafía china. Luego, daremos un paseo por el centro amurallado, atravesaremos su barrio musulmán y entraremos en la mezquita, pudiendo observar las múltiples culturas y religiones que han dejado su huella en este país. Incluiremos también la entrada a la Torre del Tambor, donde disfrutaremos de un corto espectáculo con instrumentos tradicionales (no siempre garantizado). Tiempo libre."
                            },
                            {
                                name: "06 - MAR.Xian - Lingfen - Mansión del Clan Wang - Pingyao",
                                description: "Salimos de Xian en un modernísimo tren de alta velocidad, y en menos de tres horas llegamos a Lingfen, desde donde nos dirigimos en nuestro autocar a la MANSIÓN DEL CLAN WANG, construida entre los siglos XVII y XIX por una adinerada familia. Almuerzo incluido. Continuamos hacia PINGYAO, la ciudad histórica mejor conservada de China, con 4000 edificios antiguos, patrimonio de la humanidad. Disfrutaremos de esta encantadora ciudad tradicional. Incluimos una entrada que permite el acceso a todos los templos, mansiones y la muralla de la ciudad."
                            },
                            {
                                name: "07 - MIE.Pingyao - Wutai",
                                description: "Viajamos a uno de los lugares espirituales más emocionantes de China: la MONTAÑA WUTAI. Entre verdes montañas y bosques, se encuentra uno de los centros del budismo lamaísta, con más de 40 monasterios antiguos (y un número similar de monasterios construidos en los últimos años) que albergan una numerosa comunidad de monjes. Visitaremos con nuestro guía el templo de Nanshan. Posteriormente, dejaremos tiempo libre para que usted pueda buscar templos menos visitados donde podrá, con respeto, observar a los monjes. Cena incluida y alojamiento."
                            },
                            {
                                name: "08 - JUE.Wutai - Yanmenguan - Yingxian - Templo Colgante - Datong",
                                description: "Siguiendo una carretera de gran belleza paisajística entre montañas, continuamos hacia YANMENGUAN. No lejos de los límites de Mongolia, en un paso de montaña, conocemos aquí una parte de la GRAN MURALLA, donde podremos caminar tranquilamente, al ser una zona con un número muy limitado de turistas. Continuamos hacia YINGXIAN, una tranquila población pintoresca donde destaca su gran pagoda de madera. Almuerzo incluido. Luego, seguimos hacia el TEMPLO COLGANTE, el templo de Xuancong, una increíble construcción en la pared de un acantilado. DATONG, llegada al final del día. Podrá pasear por esta ciudad de impresionantes murallas, que ha reconstruido todo su centro histórico."
                            },
                            {
                                name: "09 - VIE.Datong - Yungang - Jiming Post - Pekín",
                                description: "A las afueras de Datong, conoceremos las CUEVAS DE YUNGANG, el recinto de arte en cuevas más impresionante de China, con más de 51,000 estatuas en sus 252 cuevas excavadas en el siglo V. Continuamos hacia JIMING POST, una pequeña población tradicional china que conserva su vida apacible y provinciana. Fue creada como un punto de descanso para los correos que enviaba el emperador, protegida por potentes murallas. Continuación hacia PEKÍN, llegada a media tarde."
                            },
                            {
                                name: "10 - SAB.Pekín",
                                description: "Durante este día visitaremos: El Palacio Imperial, más conocido como la Ciudad Prohibida, uno de los patrimonios mundiales. También tendremos la oportunidad de conocer el Parque Jingshan, donde se encuentra la Colina del Carbón, uno de los jardines imperiales mejor conservados de Pekín. Ascenderemos a lo alto de la colina para tener las mejores vistas (si el día está despejado) sobre la Ciudad Prohibida. El esfuerzo valdrá la pena. Continuamos hacia la Plaza Tian An Men, una de las más grandes del mundo, y desde fuera, apreciaremos el Gran Teatro Nacional, teatro de la ópera de Pekín. Almuerzo incluido. Por la tarde, vamos a visitar el Palacio de Verano, que era un jardín veraniego para los de la casa imperial de la Dinastía Qing. Incluimos una excursión en triciclo (bicicleta típica de tres ruedas) por los barrios tradicionales de Pekín, conocidos como Hutong. En esta zona, podremos ver el estilo tan característico de vida de los residentes en estos barrios populares, visitar tiendas típicas e incluso podremos visitar una casa tradicional de un hutong, un Siheyuan, con todas sus estancias alrededor de un patio. Regreso al hotel y alojamiento."
                            },
                            {
                                name: "11 - DOM.Pekín",
                                description: "Excursión a La Gran Muralla (sección Mutianyu), espectacular y grandiosa obra arquitectónica cuya historia cubre más de 2,000 años. Incluimos el funicular de subida y bajada a la Gran Muralla. Regresando a Beijing, pararemos en el distrito 798, con su arte contemporáneo ubicado en una antigua fábrica militar. A continuación, recorreremos el Templo del Cielo, el mayor conjunto de templos de China y una de las construcciones más originales e impresionantes de Beijing. Conoceremos la medicina tradicional china, que lleva siglos apoyando la teoría de que nuestra salud está ligada a la salud de los pies. Nos relajaremos disfrutando de un masaje en los pies. También recibiremos una clase de Tai Chi. Posteriormente, regreso al hotel y alojamiento. Cena incluida de pato laqueado."
                            },
                            {
                                name: "12 - LUN.Pekín - Tokio",
                                description: "Tras el desayuno, traslado al aeropuerto para tomar un vuelo con destino a Tokio (vuelo incluido). Tokio ¡Bienvenidos a Japón! Tras los trámites de frontera y aduana, un asistente (normalmente de habla española, aunque en ocasiones puede ser en inglés) le estará esperando. Traslado en transporte colectivo (SHUTTLE BUS) a su hotel. A las 18:30 hrs, nos encontramos con nuestro guía en la recepción del hotel. Conoceremos a los integrantes de nuestro grupo y tras ello, daremos un paseo nocturno. Al final de la tarde, nos introduciremos en este fascinante país yendo en metro (billetes incluidos) a la zona de Shinjuku. Podrá ver las masas de personas que utilizan el modernísimo y eficaz transporte colectivo. A continuación, daremos un paseo para descubrir esta zona, que cuenta con una animada vida nocturna. Cena de bienvenida incluida en un restaurante local. Regreso al hotel nuevamente en metro, acompañados por nuestro guía. NOTA: En alguna ocasión, y dependiendo de los vuelos de llegada de los viajeros, el traslado en metro y la cena se podrán realizar durante la tarde-noche del día siguiente."
                            },
                            {
                                name: "13 - MAR.Tokio",
                                description: "Incluimos por la mañana una larga visita con nuestro guía de unas cinco horas en esta impresionante ciudad. Su frenética actividad y sus barrios ultramodernos conviven con zonas cargadas de paz. Breve parada en el Templo de Zojoji para fotografiar la imagen más bonita de la Torre de Tokio. Destacan los cientos y cientos de Jizos, deidades en piedra guardianas de los niños, a los que se colocan gorritos de lana, baberos y molinillos de viento. A continuación, nos dirigiremos hacia el famoso Cruce de Shibuya, del cual se dice que es el más abarrotado del mundo. Seguimos hacia el Templo de Meiji, dedicado a los espíritus del emperador Meiji y su esposa. Continuamos con nuestro autobús por la elegante calle de Omotesando. Bordearemos la parte más bonita del Palacio Imperial y de los jardines. Haremos una parada para visitar el famoso Puente de Nijubashi. Atravesaremos el barrio de Akihabara, la “Ciudad Eléctrica”, el centro del Manga y Anime japonés. Pasaremos junto al Kokugikan, el estadio de Sumo, el deporte japonés. Terminaremos nuestra visita en Asakusa, visitando el templo Sensoji y la famosa calle Nakamise. Almuerzo incluido en un restaurante local. Regreso al hotel. Tarde libre."
                            },
                            {
                                name: "14 - MIE.Tokio",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ]
                    }
                ]
            },
            {
                name: "Japón",
                flag: "japan.png",
                data: [
                    {
                        name: "Japón Clásico",
                        duration: "08 días",
                        options: [
                            {
                                name: "Opción 1: Tokio, Kioto y Alpes (Lunes durante todo el año)",
                                description: "",
                                days: [
                                    {
                                        name: "1 (Lunes)",
                                        description: "Llegada a Tokio,traslado al hotel, tarde en Shinjuku (cena incluida)."
                                    },
                                    {
                                        name: "2 (Martes)",
                                        description: "Tour por la ciudad de Tokio (almuerzo incluido), tarde libre."
                                    },
                                    {
                                        name: "3 (Miércoles)",
                                        description: "Tren bala a Kioto, tour por la ciudad de Kioto, noche en el Barrio Gion."
                                    },
                                    {
                                        name: "4 (Jueves)",
                                        description: "Visita a Tsumago, Castillo de Matsumoto, y llegada a Nagano (almuerzo incluido)."
                                    },
                                    {
                                        name: "5 (Viernes)",
                                        description: "Visita al Templo de Nagano, Parque de Monos Jigokudani, Kusatsu, y estancia en Ikaho Onsen (cena tradicional incluida)."
                                    },
                                    {
                                        name: "6 (Sábado)",
                                        description: "Visita a los templos de Nikko, regreso a Tokio. Fin de los servicios."
                                    }
                                ]
                            },
                            {
                                name: "Opción 2: Tokio, Kioto, Alpes y Fuji",
                                description: "Días 1-5: Igual que la Opción 1.",
                                days: [
                                    {
                                        name: "6 (Sábado)",
                                        description: "Visita a los templos de Nikko, regreso a Tokio."
                                    },
                                    {
                                        name: "7 (Domingo)",
                                        description: "Visita a Kamakura, Enoshima y Odawara."
                                    },
                                    {
                                        name: "8 (Lunes)",
                                        description: "Visita a Owakudani, Oshino Hakkai, Kawaguchiko, Monte Fuji, y regreso a Tokio. Fin de los servicios."
                                    }
                                ]
                            }
                        ],
                        services_included: "Traslado de llegada\n" +
                            "Tours por la ciudad\n" +
                            "Tren de alta velocidad, y varias entradas a templos y parques.\n" +
                            "5 comidas incluidas en la Opción 1\n" +
                            "Servicios adicionales de barco y entradas en la Opción 2.\n"
                    }
                ]
            },
            {
                name: "Corea",
                flag: "korea.png",
                data: [
                    {
                        name: "Corea Express",
                        duration: "06 dias",
                        days: [
                            {
                                name: "01 - LUN. Seúl.",
                                description: "¡Bienvenidos a Corea! Traslado al hotel. Encuentro con su guía. Al final de la tarde nos introduciremos en este fascinante país. Con nuestro guía, utilizaremos el moderno y eficiente Metro (nos enseñará a usar este transporte público) para dirigirnos al City Hall, un edificio con forma de tsunami. Frente al ayuntamiento, veremos el Palacio Deoksugung, iluminado por la noche, lo que nos permitirá apreciar la tradición y la modernidad juntos en este país. Luego, caminaremos hacia el barrio de Myeongdong, la mayor zona comercial y de vida en Corea, cuyo nombre significa túnel brillante. Cena incluida en un restaurante local. Alojamiento."
                            },
                            {
                                name: "02 - MAR. Seúl.",
                                description: "Hoy incluimos una larga visita de unas cinco horas por Seúl. Conoceremos el Palacio Changdeokgung, un lugar declarado Patrimonio de la Humanidad, compuesto por un conjunto de palacios dentro de un gran parque. Luego, pasearemos por Bukchon Hanok Village, un barrio histórico tradicional de pequeñas viviendas. Posteriormente, visitaremos el Memorial de la Guerra, un impresionante museo que nos ilustra sobre los conflictos vividos en Corea a lo largo de la historia, especialmente sobre la Guerra de Corea en la que participaron soldados de numerosos países bajo las Naciones Unidas. Almuerzo incluido en un restaurante local. Continuamos hacia el Distrito de Gangnam, una de las zonas más concurridas de la ciudad, donde se encuentran numerosos centros comerciales y de entretenimiento. Para finalizar, pararemos en la Torre Lotte World, un impresionante edificio de 550 metros de altura, donde tendrá tiempo libre para subir a la torre (entrada no incluida) o bien pasear por su parque o centros comerciales de lujo. Regreso al hotel y tiempo libre."
                            },
                            {
                                name: "03 - MIE. Seúl - Suwon - Jeonju.",
                                description: "Hoy conoceremos el Palacio de Gyeongbokgung, un impresionante palacio con 600 años de historia, donde asistiremos, si se realiza, a la ceremonia del cambio de guardia. Salimos de Seúl hacia el sur de Corea. Llegamos a Suwon, antigua capital de Corea, conocida por sus impresionantes murallas, declaradas Patrimonio de la Humanidad. Conoceremos la impresionante muralla con sus fortificaciones y visitaremos Hwaseong Haenggung, el palacio de verano. Almuerzo incluido. Continuamos nuestra ruta hacia Jeonju, donde conoceremos su fascinante casco histórico (hanok village), con todas sus casitas, tiendas tradicionales y galerías de arte."
                            },
                            {
                                name: "04 - JUE. Jeonju - Haeinsa - Daegu - Busan.",
                                description: "Entre impresionantes paisajes de montaña cubiertos de bosques, llegamos a Haeinsa, un templo budista construido en el año 802, donde se guarda el Tripitaka, la colección más completa de textos budistas grabada en 80,000 bloques de madera. Visitaremos el templo y el museo sobre el Tripitaka. Continuamos hacia Daegu, donde visitaremos el mercado de medicinas tradicionales, famoso por sus diferentes hierbas, destacando el ginseng, y el museo de medicina oriental. Almuerzo incluido. Continuamos hacia Busan, la segunda ciudad de Corea, llena de vida. A la llegada, haremos una parada en el fantástico complejo de Doosan Haeundae, con sus rascacielos de más de 300 metros de altura y su puerto deportivo. Alojamiento."
                            },
                            {
                                name: "05 - VIE. Busan - Haedong Yonggung - Seúl.",
                                description: "Por la mañana, saldremos de Busan para conocer Haedong Yonggung, un templo budista del siglo XIV ubicado junto al mar. Regresaremos a Busan. Conoceremos el Memorial de las Naciones Unidas, con su cementerio donde están enterradas personas de numerosos países. Luego, visitaremos el mayor mercado de pescado de este país, que se encuentra junto al puerto de Nampo. Almuerzo incluido. Posteriormente, caminaremos desde allí al parque Yongdusan, con su inmensa pagoda de 120 metros. Alrededor de las 17:30 tomaremos un moderno tren de alta velocidad, y en unas tres horas regresaremos a Seúl. Llegada, traslado al hotel y alojamiento."
                            },
                            {
                                name: "06 - SAB. Seúl.",
                                description: "Día libre en Seúl para explorar la ciudad a su ritmo o realizar actividades opcionales. Alojamiento en Seúl."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada.\n" +
                            "Visita panorámica en: SEÚL.\n" +
                            "Traslado nocturno: Barrio de Myeongdong en Seúl.\n" +
                            "Entradas incluidas para: Metro, Palacio Changdeokgung, Memorial de la Guerra en Seúl, Palacio de Gyeongbokgung, Hanok Village, Templo Budista, Templo Haedong Yonggung, Memorial de las Naciones Unidas y Parque Yongdusan.\n" +
                            "Tren de alta velocidad: Busan-Seúl.\n" +
                            "4 Almuerzos incluidos en: SEÚL, SUWON, DAEGU, BUSAN.\n" +
                            "1 Cena incluida en: SEÚL.\n"
                    },
                    {
                        name: "Corea + Japón Clásico",
                        duration: "13 días",
                        days: [
                            {
                                name: "01 - LUN. Seúl.",
                                description: "¡Bienvenidos a Corea! Traslado a su hotel. Encuentro con su guía. Al final de la tarde, nos introduciremos en este fascinante país. Con nuestro guía, tomaremos el moderno y eficiente Metro (quien nos enseñará cómo usar este transporte público) hacia el Ayuntamiento, un edificio con forma de tsunami. También veremos el Palacio Deoksugung, iluminado por la noche, lo que nos permitirá apreciar juntos la tradición y la modernidad en este país. Luego, caminaremos hacia el barrio de Myeongdong, la mayor zona comercial y de vida en Corea, cuyo nombre significa túnel brillante. Cena incluida en un restaurante local. Alojamiento."
                            },
                            {
                                name: "02 - MAR. Seúl.",
                                description: "Hoy incluimos una larga visita de unas cinco horas por Seúl. Conoceremos el Palacio Changdeokgung, un lugar declarado Patrimonio de la Humanidad, compuesto por un conjunto de palacios dentro de un gran parque. Luego, pasearemos por Bukchon Hanok Village, un barrio histórico tradicional de pequeñas viviendas. Posteriormente, visitaremos el Memorial de la Guerra, un impresionante museo que nos ilustra sobre los conflictos vividos en Corea a lo largo de la historia, especialmente sobre la Guerra de Corea en la que participaron soldados de numerosos países bajo las Naciones Unidas. Almuerzo incluido en un restaurante local. Continuamos hacia el Distrito de Gangnam, una de las zonas más concurridas de la ciudad, donde se encuentran numerosos centros comerciales y de entretenimiento. Para finalizar, pararemos en la Torre Lotte World, un impresionante edificio de 550 metros de altura, donde tendrá tiempo libre para subir a la torre (entrada no incluida) o bien pasear por su parque o centros comerciales de lujo. Regreso al hotel y tiempo libre."
                            },
                            {
                                name: "03 - MIE. Seúl - Suwon - Jeonju.",
                                description: "Hoy conoceremos el Palacio de Gyeongbokgung, un impresionante palacio con 600 años de historia, donde asistiremos, si se realiza, a la ceremonia del cambio de guardia. Salimos de Seúl hacia el sur de Corea. Llegamos a Suwon, antigua capital de Corea, conocida por sus impresionantes murallas, declaradas Patrimonio de la Humanidad. Conoceremos la impresionante muralla con sus fortificaciones y visitaremos Hwaseong Haenggung, el palacio de verano. Almuerzo incluido. Continuamos nuestra ruta hacia Jeonju, donde conoceremos su fascinante casco histórico (hanok village), con todas sus casitas, tiendas tradicionales y galerías de arte."
                            },
                            {
                                name: "04 - JUE. Jeonju - Haeinsa - Daegu - Busan.",
                                description: "Entre impresionantes paisajes de montaña cubiertos de bosques, llegamos a Haeinsa, un templo budista construido en el año 802, donde se guarda el Tripitaka, la colección más completa de textos budistas grabada en 80,000 bloques de madera. Visitaremos el templo y el museo sobre el Tripitaka. Continuamos hacia Daegu, donde visitaremos el mercado de medicinas tradicionales, famoso por sus diferentes hierbas, destacando el ginseng, y el museo de medicina oriental. Almuerzo incluido. Continuamos hacia Busan, la segunda ciudad de Corea, llena de vida. A la llegada, haremos una parada en el fantástico complejo de Doosan Haeundae, con sus rascacielos de más de 300 metros de altura y su puerto deportivo. Alojamiento."
                            },
                            {
                                name: "05 - VIE. Busan - Haedong Yonggung - Busan - Ferry Busan Shimonoseki.",
                                description: "Por la mañana, saldremos de Busan para conocer Haedong Yonggung, un templo budista del siglo XIV ubicado junto al mar. Regresaremos a Busan y conoceremos el Memorial de Naciones Unidas, con su cementerio donde están enterradas personas de numerosos países. Nos acercaremos al mayor mercado de pescado de este país, que se encuentra junto al puerto de Nampo. Almuerzo incluido. Posteriormente, caminaremos hacia el parque Yongdusan, con su inmensa pagoda de 120 metros. Luego, traslado al puerto para realizar los trámites de embarque (Nota: El tiempo límite para el check-in en el ferry es a las 17:30 horas). La salida de Busan está prevista para las 21:00 horas, cruzando el mar de Japón. Pasaremos la noche a bordo del ferry, en camarotes dobles con baño privado. Nota: Debido a la limitada capacidad de camarotes dobles en el ferry, en ocasiones tomaremos un ferry rápido con salida de Busan a las 15:00 horas, llegando a Fukuoka (Japón) alrededor de las 18:30 horas (paso de frontera, tiempo libre en Fukuoka y alojamiento en un hotel)."
                            },
                            {
                                name: "06 - SAB. Ferry Busan Shimonoseki - Shimonoseki - Iwakuni - Itsukushima - Hiroshima.",
                                description: "Llegada a las 8:00 AM al puerto de Shimonoseki (desayuno no incluido), paso de frontera. Viajamos hacia Iwakuni, una pequeña ciudad donde visitaremos el hermoso e histórico puente Kintai-Kyo. Continuamos hacia Miyajima y embarcamos en un ferry hacia la isla de Miyajima, la isla donde conviven dioses y hombres, con el santuario de Itsukushima, dedicado a la guardiana de los mares, construido parcialmente sobre el mar. Tendremos tiempo para visitar el templo, pasear por su bonito centro y almorzar. Continuamos hacia Hiroshima, una ciudad tristemente conocida por el bombardeo atómico que sufrió en 1945 y que destruyó por completo la ciudad. Pasearemos por el Memorial de la Paz, la Cúpula de la Bomba y visitaremos el impresionante Museo de la Paz. Cena incluida."
                            },
                            {
                                name: "07 - DOM. Hiroshima - Matsuyama.",
                                description: "Hoy tomaremos un ferry hacia la isla de Shikoku, un agradable viaje de unas dos horas y media. Comprenderemos mejor Japón, un país formado por múltiples islas. En Matsuyama, tomaremos un teleférico para subir a su impresionante castillo. Después, visitaremos Ishiteji, un hermoso templo budista y lugar de peregrinación. Almuerzo incluido en un restaurante local. Al final de la tarde, incluiremos la entrada a Dogo Onsen, un tradicional baño japonés construido en el siglo XIX."
                            },
                            {
                                name: "08 - LUN. Matsuyama - Kotohira - Takamatsu - Naruto - Kobe.",
                                description: "Partiremos hacia Kotohira, un santuario sintoísta dedicado al dios de los marineros, un lugar de peregrinación construido en el Monte Zosu, al cual se accede subiendo 785 escalones. Posteriormente, en Takamatsu, visitaremos Ritsurin, uno de los jardines más bellos de Japón. Almuerzo incluido en un restaurante local. Luego, en Naruto, cruzaremos un impresionante puente sobre el mar y caminaremos por su suelo acristalado para observar los remolinos impresionantes del mar a 45 metros de altura. Nuestra ruta continuará por paisajes de gran belleza, cruzando grandes puentes entre islas sobre el mar. Llegada a Kobe, alojamiento."
                            },
                            {
                                name: "09 - MAR. Kobe - Osaka - Monte Koya.",
                                description: "En Kobe, visitaremos el Museo Memorial del Terremoto, que conmemora el trágico seísmo que asoló la ciudad en 1995 y su impecable reconstrucción. Nota: En algunas fechas puntuales, la entrada al Museo del Terremoto podría ser sustituida por la visita al Museo del Sake o la subida a la Torre de Kobe. Continuamos hacia Osaka. Luego, saldremos de la ciudad hacia la montaña sagrada de Koya, el centro más importante del budismo Shingon en Japón. En esta montaña, un lugar de peregrinación y magia, existen 120 templos y monasterios. Visitaremos el Mausoleo de Okuno, un lugar espiritual e inmenso cementerio en la naturaleza con más de 200,000 tumbas. También visitaremos el templo de Kongobuji, construido en 1593. Esta noche nos alojaremos en un monasterio budista. Los monjes nos ofrecerán una práctica de meditación zen (Ajikan). Cena incluida (comida vegetariana). Si lo desea, podrá asistir de madrugada a la ceremonia religiosa del templo. Nota: Consideramos el alojamiento en el monasterio una experiencia de gran valor vivencial. Las habitaciones son sencillas, sin baños, de estilo tradicional japonés con tatamis. Los baños son comunes."
                            },
                            {
                                name: "10 - MIE. Monte Koya - Kioto.",
                                description: "Desayuno japonés en el templo. Saldremos a primera hora hacia Kioto, donde pasaremos todo el día en la ciudad, que fue capital de Japón desde el año 794 hasta 1868, albergando la sede de la corte imperial. Esta ciudad fue la única gran ciudad japonesa que no fue bombardeada durante la Segunda Guerra Mundial, por lo que conserva su rico patrimonio artístico. En Kioto se firmó en 1997 el Protocolo que busca limitar las emisiones de gases de efecto invernadero. Incluiremos una visita a los principales puntos de la ciudad. Conoceremos el maravilloso santuario sintoísta de Fushimi Inari, el Palacio Imperial y el templo de Kinkakuji, conocido como el pabellón de oro, con sus magníficos jardines. Después, tendremos tiempo antes de realizar un paseo por Gion, el tradicional barrio lleno de vida, conocido por sus geishas. Tiempo libre."
                            },
                            {
                                name: "11 - JUE. Kioto - Tokio.",
                                description: "Después del desayuno, viajaremos a Tokio en tren bala. Nuestro tren saldrá temprano, y en poco más de dos horas cubriremos los 500 kilómetros que separan ambas ciudades. Llegada a Tokio. Al final de la tarde, iremos en metro (billetes incluidos) a la zona de Shinjuku. Podrá ver las masas de personas que utilizan el moderno y eficiente transporte colectivo. En Shinjuku, nos encontraremos en una de las zonas con más vida nocturna. Cena incluida en un restaurante local. Regreso al hotel."
                            },
                            {
                                name: "12 - VIE. Tokio.",
                                description: "Por la mañana, incluimos una larga visita con nuestro guía de unas cinco horas en esta impresionante ciudad. Su frenética actividad y sus barrios ultramodernos conviven con zonas llenas de paz. Haremos una breve parada en el Templo de Zojoji para fotografiar la imagen más bonita de la Torre de Tokio. Destacan los cientos de Jizos, deidades en piedra guardianas de los niños, a los que se les colocan gorritos de lana, baberos y molinillos de viento. A continuación, nos dirigiremos hacia el famoso Cruce de Shibuya, del cual se dice que es el más abarrotado del mundo. Seguiremos hacia el Templo de Meiji, dedicado a los espíritus del emperador Meiji y su esposa. Continuaremos con nuestro bus por la elegante calle de Omotesando, bordeando la parte más bonita del Palacio Imperial y sus jardines. Pararemos para visitar el famoso Puente de Nijubashi. Atravesaremos el barrio de Akihabara, la “Ciudad Eléctrica” y el centro del Manga y Anime japonés; pasaremos junto al Kokugikan, el estadio de Sumo, el deporte nacional de Japón. Terminaremos nuestra visita en Asakusa, visitando el Templo Sensoji y la famosa calle Nakamise. Almuerzo incluido en un restaurante local. Regreso al hotel. Tarde libre."
                            },
                            {
                                name: "13 - SAB. Tokio.",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    america: {
        name: "Sudamérica",
        data: [
            {
                name: "Argentina",
                flag: "argentina.png",
                data: [
                    {
                        name: "Iguazú magico",
                        duration: "06 dias",
                        background: "packages/argentina.jpg",
                        days: [
                            {
                                name: "1 - Llegada a Iguazú",
                                description: "Al llegar al aeropuerto de Iguazú (IGU o IGR), serás trasladado al hotel. Tendrás el resto del día libre para descansar o explorar la ciudad a tu ritmo. Alojamiento en Iguazú."
                            },
                            {
                                name: "2 - Cataratas del Iguazú (Lado Brasilero)",
                                description: "Después del desayuno, disfrutarás de una excursión a las impresionantes Cataratas del Iguazú del lado brasileño. Este recorrido te permitirá admirar las vistas panorámicas de las cataratas. Tarde libre para actividades opcionales o descanso. Alojamiento en Iguazú."
                            },
                            {
                                name: "3 - Cataratas del Iguazú (Lado Argentino) y Traslado a Buenos Aires",
                                description: "Desayuno en el hotel y visita a las Cataratas del Iguazú del lado argentino, donde podrás acercarte aún más a la fuerza del agua. Opcionalmente, podrás elegir entre una parada en el Parque de Aves o una visita a Dreamland, con un ticket incluido para una atracción a elección. Además, disfrutarás de una parada gratuita en el centro de artesanía Chocolate Caseiro. Por la tarde, serás trasladado al aeropuerto para tomar tu vuelo hacia Buenos Aires. A la llegada, traslado al hotel. Alojamiento en Buenos Aires."
                            },
                            {
                                name: "4 - City Tour en Buenos Aires y Tour de Compras",
                                description: "Después del desayuno, participarás en una excursión de medio día por Buenos Aires, recorriendo los puntos más emblemáticos de la ciudad. Por la tarde, disfrutarás de un tour de compras, visitando fábricas de cuero, ropa y otros productos locales. Alojamiento en Buenos Aires."
                            },
                            {
                                name: "5 - Día Libre en Buenos Aires",
                                description: "Día libre para explorar Buenos Aires por tu cuenta. Podrás aprovechar el 10% de descuento en el restaurante Puerto Cristal en Puerto Madero para disfrutar de una deliciosa cena. Además, si lo deseas, puedes visitar el Casino de Puerto Madero, con entrada y traslado gratuito proporcionado por el casino (para mayores de 18 años). Alojamiento en Buenos Aires."
                            },
                            {
                                name: "6 - Salida de Buenos Aires",
                                description: "Después del desayuno, tendrás tiempo libre antes de ser trasladado al aeropuerto para tu vuelo de regreso. Fin de los servicios."
                            }
                        ],
                        services_included: "En Iguazú:\n" +
                            "Traslados aeropuerto/hotel/aeropuerto.\n" +
                            "2 noches de alojamiento con desayuno.\n" +
                            "Excursiones a las Cataratas del Iguazú (lados brasileño y argentino).\n" +
                            "Elección entre parada en el Parque de Aves o visita a Dreamland con un ticket incluido.\n" +
                            "Parada en el centro de artesanía Chocolate Caseiro.\n" +
                            "En Buenos Aires:\n" +
                            "Traslados aeropuerto/hotel/aeropuerto en servicio semi-privado.\n" +
                            "3 noches de alojamiento con desayuno.\n" +
                            "Excursión de medio día de city tour en servicio regular.\n" +
                            "Tour de compras en fábricas de cuero y ropa.\n" +
                            "10% de descuento en el restaurante Puerto Cristal.\n" +
                            "Entrada y traslado gratuito al Casino Puerto Madero.\n" +
                            "Impuestos hoteleros.\n",
                        services_additional: "Cena show en la Churrasquería Rafain en Iguazú (con costo adicional)."
                    }
                ]
            },
            {
                name: "Brasil",
                flag: "brasil.png",
                data: [
                    {
                        name: "Iguazú magico",
                        duration: "06 dias",
                        background: "packages/brasil.webp",
                        days: [
                            {
                                name: "1 - Llegada a Iguazú",
                                description: "Al llegar al aeropuerto de Iguazú (IGU o IGR), serás trasladado al hotel. Tendrás el resto del día libre para descansar o explorar la ciudad a tu ritmo. Alojamiento en Iguazú."
                            },
                            {
                                name: "2 - Cataratas del Iguazú (Lado Brasilero)",
                                description: "Después del desayuno, disfrutarás de una excursión a las impresionantes Cataratas del Iguazú del lado brasileño. Este recorrido te permitirá admirar las vistas panorámicas de las cataratas. Tarde libre para actividades opcionales o descanso. Alojamiento en Iguazú."
                            },
                            {
                                name: "3 - Cataratas del Iguazú (Lado Argentino) y Traslado a Buenos Aires",
                                description: "Desayuno en el hotel y visita a las Cataratas del Iguazú del lado argentino, donde podrás acercarte aún más a la fuerza del agua. Opcionalmente, podrás elegir entre una parada en el Parque de Aves o una visita a Dreamland, con un ticket incluido para una atracción a elección. Además, disfrutarás de una parada gratuita en el centro de artesanía Chocolate Caseiro. Por la tarde, serás trasladado al aeropuerto para tomar tu vuelo hacia Buenos Aires. A la llegada, traslado al hotel. Alojamiento en Buenos Aires."
                            },
                            {
                                name: "4 - City Tour en Buenos Aires y Tour de Compras",
                                description: "Después del desayuno, participarás en una excursión de medio día por Buenos Aires, recorriendo los puntos más emblemáticos de la ciudad. Por la tarde, disfrutarás de un tour de compras, visitando fábricas de cuero, ropa y otros productos locales. Alojamiento en Buenos Aires."
                            },
                            {
                                name: "5 - Día Libre en Buenos Aires",
                                description: "Día libre para explorar Buenos Aires por tu cuenta. Podrás aprovechar el 10% de descuento en el restaurante Puerto Cristal en Puerto Madero para disfrutar de una deliciosa cena. Además, si lo deseas, puedes visitar el Casino de Puerto Madero, con entrada y traslado gratuito proporcionado por el casino (para mayores de 18 años). Alojamiento en Buenos Aires."
                            },
                            {
                                name: "6 - Salida de Buenos Aires",
                                description: "Después del desayuno, tendrás tiempo libre antes de ser trasladado al aeropuerto para tu vuelo de regreso. Fin de los servicios."
                            }
                        ],
                        services_included: "En Iguazú:\n" +
                            "Traslados aeropuerto/hotel/aeropuerto.\n" +
                            "2 noches de alojamiento con desayuno.\n" +
                            "Excursiones a las Cataratas del Iguazú (lados brasileño y argentino).\n" +
                            "Elección entre parada en el Parque de Aves o visita a Dreamland con un ticket incluido.\n" +
                            "Parada en el centro de artesanía Chocolate Caseiro.\n" +
                            "En Buenos Aires:\n" +
                            "Traslados aeropuerto/hotel/aeropuerto en servicio semi-privado.\n" +
                            "3 noches de alojamiento con desayuno.\n" +
                            "Excursión de medio día de city tour en servicio regular.\n" +
                            "Tour de compras en fábricas de cuero y ropa.\n" +
                            "10% de descuento en el restaurante Puerto Cristal.\n" +
                            "Entrada y traslado gratuito al Casino Puerto Madero.\n" +
                            "Impuestos hoteleros.\n",
                        services_additional: "Cena show en la Churrasquería Rafain en Iguazú (con costo adicional).\n"
                    }
                ]
            },
            {
                name: "Ecuador",
                flag: "ecuador.png",
                data: [
                    {
                        name: "Galápagos mágico",
                        duration: "4 días",
                        background: "packages/pinguinos_galapagos.webp",
                        days: [
                            {
                                name: "1 - Recepción en el aeropuerto + Estación Científica",
                                description: "A tu llegada al aeropuerto de Baltra, nuestro guía te acompañará en el traslado a Santa Cruz. Por la tarde, visitarás la Estación Científica Charles Darwin y el Centro de Interpretación del Parque Nacional Galápagos, donde podrás conocer más sobre las tortugas terrestres y las iguanas. Pasarás la noche en Puerto Ayora."
                            },
                            {
                                name: "2 - Bahía Tortuga + Altiplano",
                                description: "Por la mañana, explorarás la hermosa Bahía Tortuga, comenzando con una caminata de 2.5 km por un sendero pavimentado donde el guía explicará la flora local. Primero, visitarás la playa Brava, ideal para surfistas debido a sus fuertes corrientes y olas de hasta 2 metros. Luego, te dirigirás a la playa Mansa, perfecta para relajarse y disfrutar del sol en sus aguas cristalinas y arena blanca. Después, tomarás un transporte compartido desde Puerto Ayora hacia los cráteres Los Gemelos y luego continuarás hacia un rancho privado para almorzar. Por la tarde, realizarás una caminata para observar las gigantes tortugas Galápagos en su hábitat natural y explorarás un túnel de lava antes de regresar al puerto."
                            },
                            {
                                name: "3 - Las Grietas + Playa de los Alemanes",
                                description: "En el horario establecido por el guía, te reunirás en el lobby del hotel y caminarás hasta el muelle para tomar un taxi acuático hacia la zona de Punta Estrada. Allí, realizarás una caminata corta hasta llegar a Las Grietas, una formación volcánica impresionante, ideal para nadar en aguas tranquilas y cristalinas, y para aquellos que disfrutan del buceo. Luego, visitarás la Playa de los Alemanes, un lugar popular por su arena blanca y aguas transparentes, perfecto para relajarse cerca de Puerto Ayora."
                            },
                            {
                                name: "4 - Salida y traslado al aeropuerto",
                                description: "Después de un delicioso desayuno, harás el check-out en el hotel y te trasladarás al aeropuerto para tomar tu vuelo de regreso. Fin de los servicios."
                            }
                        ]
                    },
                    {
                        name: "Gálapagos explorador",
                        duration: "5 días",
                        background: "packages/pinguinos_galapagos.webp",
                        days: [
                            {
                                name: "1 - Llegada a Galápagos + Estación Científica Charles Darwin y Tierras Altas",
                                description: "El 17 de febrero, tras llegar al aeropuerto de Baltra, nuestro guía te llevará a visitar la Estación Científica Charles Darwin y el Centro de Interpretación del Parque Nacional Galápagos, donde podrás observar tortugas terrestres y iguanas. Luego, viajarás desde Puerto Ayora hacia los cráteres Los Gemelos y un rancho privado, donde tendrás la oportunidad de realizar una caminata para ver tortugas gigantes en su hábitat natural y explorar un túnel de lava. Finalizarás el día regresando al puerto y alojándote en un hotel de lujo."
                            },
                            {
                                name: "2 - Excursión a Isabela + Tintoreras",
                                description: "El 18 de febrero, partirás al amanecer en un bote hacia la Isla Isabela, donde visitarás Tintoreras, un lugar conocido por su abundante fauna marina, incluyendo tiburones de aleta blanca, leones marinos, iguanas marinas, piqueros de patas azules, cangrejos Sally Lightfoot, tortugas marinas, pelícanos y los únicos pingüinos de Galápagos. Tras la visita, podrás disfrutar de una sesión de snorkel en una bahía cercana, antes de regresar al muelle de Isabela. Alojamiento en un hotel de lujo."
                            },
                            {
                                name: "3 - Volcán Sierra Negra + Isla Santa Cruz",
                                description: "El 19 de febrero, te dirigirás a las tierras altas de Isabela para iniciar una caminata hacia el volcán Sierra Negra, el segundo cráter más grande del mundo. Si lo deseas, podrás continuar la caminata hacia el Volcán Chico, donde verás fumarolas y formaciones de lava espectaculares. Por la tarde, regresarás a Santa Cruz para tu alojamiento."
                            },
                            {
                                name: "4 - Excursión a Seymour Norte",
                                description: "El 20 de febrero, después del desayuno, partirás hacia Seymour Norte, donde podrás hacer caminatas, nadar y practicar snorkel. En la isla, verás árboles Palo Santo, piqueros de patas azules, gaviotas de cola bifurcada, y leones marinos. También visitarás la playa Las Bachas, conocida por sus aguas cristalinas y arena blanca. Regresarás al hotel para pasar la noche."
                            },
                            {
                                name: "5 - Salida de Galápagos",
                                description: "El 21 de febrero, disfrutarás de un desayuno en el hotel antes de tener tiempo libre para explorar o relajarte. Después del almuerzo, serás trasladado al aeropuerto para tomar tu vuelo de regreso a Lima."
                            }
                        ],
                        services_included: "4 noches en hoteles de lujo con desayuno incluido.\n" +
                            "Guía profesional en Galápagos.\n" +
                            "Transporte durante las excursiones y traslados entre islas.\n",
                        services_not_included: "Vuelos ida y vuelta desde Lima a Galápagos.\n" +
                            "Comidas fuera del desayuno.\n" +
                            "Entradas al Parque Nacional Galápagos.\n" +
                            "Tarjeta de Control de Tránsito (TCT).\n" +
                            "Tarifas adicionales como el transporte en bote, taxis acuáticos, y otros servicios no especificados.\n",
                        recommendations: "Llevar protector solar, repelente de insectos, traje de baño, y seguro de viaje."
                    }
                ]
            },
            {
                name: "Perú",
                flag: "peru.png",
                data: [
                    {
                        name: "Lima Express",
                        duration: "3 días",
                        description: "¡Descubre lo Mejor de Lima y Paracas en 3 Días con Nuestro Exclusivo Paquete de Viaje! ¿Buscas una aventura única que combine historia, naturaleza y cultura? ¡Tenemos el itinerario perfecto para ti! Sumérgete en la vibrante ciudad de Lima, explora las enigmáticas Islas Ballestas y relájate en la belleza de Paracas con nuestro Paquete de 3 Días: Lima y Paracas. Diseñado para viajeros que desean lo mejor de ambos mundos, este tour combina experiencias culturales, historia precolombina, paisajes naturales impresionantes y delicias culinarias.",
                        days: [
                            {
                                name: "Día 1 - Exploración Cultural en Lima",
                                description: "¡Bienvenido a Lima! A tu llegada al Aeropuerto Internacional Jorge Chávez, nuestro equipo te estará esperando para llevarte a tu cómodo hotel en Miraflores o San Isidro. Después de un pequeño descanso, prepárate para un día lleno de historia y cultura. Empieza tu aventura con una visita al Museo Larco, una joya escondida que alberga la colección más grande de arte precolombino en Lima. Admira las impactantes cerámicas, textiles y objetos de oro y plata de antiguas civilizaciones peruanas. Luego disfruta de un delicioso almuerzo peruano en el encantador restaurante del museo, rodeado de jardines. Descubre la riqueza histórica de Perú con una visita al Museo de Oro, donde encontrarás joyas y artefactos de gran valor cultural. Finaliza el día con una cena en uno de los renombrados restaurantes de Miraflores y un relajante paseo por Larcomar, un centro comercial al aire libre con impresionantes vistas al océano Pacífico."
                            },
                            {
                                name: "Día 2 - Naturaleza y Aventura en Paracas",
                                description: "¡Prepárate para un día emocionante! Salimos temprano hacia la hermosa bahía de Paracas, un destino conocido por su rica biodiversidad y paisajes sorprendentes. Una vez en Paracas, abordarás una lancha para explorar las impresionantes Islas Ballestas. Observa de cerca lobos marinos, pingüinos de Humboldt y una gran variedad de aves marinas en su hábitat natural. Después del tour, tendrás la oportunidad de recorrer la Reserva Nacional de Paracas, un paraíso desértico con playas únicas como la Playa Roja y vistas panorámicas que te dejarán sin aliento. Disfruta de una deliciosa cena con vistas al mar y pasa la noche en un hotel acogedor en Paracas, relajándote después de un día lleno de aventuras."
                            },
                            {
                                name: "Día 3 - Regreso a Lima y Últimos Toques",
                                description: "Después de una noche relajante en Paracas, regresaremos a Lima para finalizar tu viaje con estilo. Viajaremos de vuelta a Lima en la mañana, justo a tiempo para un delicioso almuerzo en Miraflores o San Isidro, donde podrás degustar los sabores locales. Disfruta de la tarde explorando Miraflores a tu propio ritmo. Puedes pasear por el Malecón, visitar el Parque Kennedy o simplemente relajarte en un café con vista al mar. Termina tu viaje con una cena de despedida en Lima antes de que nuestro equipo te lleve al Aeropuerto Internacional Jorge Chávez para tu vuelo de regreso."
                            }
                        ],
                        services_included: "Traslados ida y vuelta al aeropuerto.\n" +
                            "Transporte privado de Lima a Paracas y viceversa.\n" +
                            "Entradas y tours guiados al Museo Larco, Museo de Oro, Islas Ballestas y Reserva Nacional de Paracas.\n" +
                            "1 noche de alojamiento en Paracas con desayuno.\n" +
                            "Asistencia personalizada durante todo el viaje.\n",
                        services_not_included: "Boletos de avión a Lima.\n" +
                            "Almuerzos y cenas no mencionados en el itinerario.\n"
                    },
                    {
                        name: "Lima y Paracas Express",
                        duration: "4 días",
                        description: "Descubre lo Mejor de Lima y Paracas en 4 Días con Nuestro Exclusivo Paquete de Viaje. ¿Buscas una experiencia que combine historia, naturaleza, cultura y gastronomía? Tenemos el itinerario perfecto para ti. Sumérgete en la vibrante ciudad de Lima, explora las enigmáticas Islas Ballestas, relájate en la belleza de Paracas, y aprende a preparar los famosos ceviche y pisco sour peruanos con nuestro Paquete de 4 Días: Lima y Paracas. Diseñado para viajeros que desean explorar lo mejor de ambos destinos, este tour incluye experiencias culturales, historia precolombina, paisajes naturales impresionantes, delicias culinarias, y una clase de cocina única.",
                        days: [
                            {
                                name: "Día 1 - Exploración Cultural en Lima",
                                description: "¡Bienvenido a Lima! A tu llegada al Aeropuerto Internacional Jorge Chávez, nuestro equipo te estará esperando para llevarte a tu cómodo hotel en Miraflores o San Isidro. Después de un pequeño descanso, prepárate para un día lleno de historia y cultura. Empieza tu aventura con una visita al Museo Larco, una joya escondida que alberga la colección más grande de arte precolombino en Lima. Admira las impactantes cerámicas, textiles y objetos de oro y plata de antiguas civilizaciones peruanas. Disfruta de un delicioso almuerzo peruano en el encantador restaurante del museo, rodeado de jardines. Descubre la riqueza histórica de Perú con una visita al Museo de Oro, donde encontrarás joyas y artefactos de gran valor cultural. Finaliza el día con una cena en uno de los renombrados restaurantes de Miraflores y un relajante paseo por Larcomar, un centro comercial al aire libre con impresionantes vistas al océano Pacífico."
                            },
                            {
                                name: "Día 2 - Clase de Cocina Peruana y Circuito Mágico del Agua",
                                description: "Hoy aprenderás a preparar algunos de los platillos más emblemáticos de Perú y disfrutarás de un espectáculo nocturno único. En la mañana, participa en una clase de cocina peruana donde aprenderás a preparar el famoso ceviche y el pisco sour, la bebida bandera del país. Bajo la guía de un chef local, descubrirás los secretos detrás de estos platos icónicos y disfrutarás de tus propias creaciones para el almuerzo. Después de la clase, disfruta de tiempo libre explorando Miraflores. Puedes pasear por el Malecón, visitar el Parque Kennedy o relajarte en un café con vista al mar. Por la noche, disfruta de un espectáculo inolvidable en el Circuito Mágico del Agua, el parque de fuentes más grande del mundo, donde podrás admirar un despliegue de luces, agua y música."
                            },
                            {
                                name: "Día 3 - Naturaleza y Aventura en Paracas",
                                description: "¡Prepárate para un día emocionante! Salimos temprano hacia la hermosa bahía de Paracas, un destino conocido por su rica biodiversidad y paisajes sorprendentes. Una vez en Paracas, abordarás una lancha para explorar las impresionantes Islas Ballestas. Observa de cerca lobos marinos, pingüinos de Humboldt y una gran variedad de aves marinas en su hábitat natural. Después del tour, tendrás la oportunidad de recorrer la Reserva Nacional de Paracas, un paraíso desértico con playas únicas como la Playa Roja y vistas panorámicas que te dejarán sin aliento. Disfruta de una deliciosa cena con vistas al mar y pasa la noche en un hotel acogedor en Paracas, relajándote después de un día lleno de aventuras."
                            },
                            {
                                name: "Día 4 - Regreso a Lima y Últimos Toques",
                                description: "Después de una noche relajante en Paracas, regresaremos a Lima para finalizar tu viaje con estilo. Viajaremos de vuelta a Lima en la mañana, justo a tiempo para un delicioso almuerzo en Miraflores o San Isidro, donde podrás degustar los sabores locales. Disfruta de la tarde explorando Miraflores a tu propio ritmo. Puedes pasear por el Malecón, visitar el Parque Kennedy o simplemente relajarte en un café con vista al mar. Termina tu viaje con una cena de despedida en Lima antes de que nuestro equipo te lleve al Aeropuerto Internacional Jorge Chávez para tu vuelo de regreso."
                            }
                        ],
                        services_included: "Traslados ida y vuelta al aeropuerto.\n" +
                            "Transporte privado de Lima a Paracas y viceversa.\n" +
                            "Entradas y tours guiados al Museo Larco, Museo de Oro, Islas Ballestas, Reserva Nacional de Paracas y Circuito Mágico del Agua.\n" +
                            "Clase de cocina peruana con almuerzo.\n" +
                            "1 noche de alojamiento en Paracas con desayuno.\n" +
                            "Asistencia personalizada durante todo el viaje.\n",
                        services_not_included: "Boletos de avión a Lima.\n" +
                            "Almuerzos y cenas no mencionados en el itinerario.\n"
                    }
                ]
            },
            {
                name: "Estados Unidos",
                flag: "usa.png",
                data: [
                    {
                        name: "New York City",
                        duration: "9 días",
                        background: "packages/new_york.webp",
                        days: [
                            {
                                name: "1 - SAB - 5 de octubre",
                                description: "Llegada a New York. Check-in en el hotel. Día libre para descansar y explorar la ciudad"
                            },
                            {
                                name: "2 - DOM - 6 de octubre",
                                description: "Admision Crucero a la Estatua de la Libertad. Horario sugerido: 10:00 AM. Disfruta del ferry a la Isla de la Libertad, visita el museo y accede al pedestal de la Estatua de la Libertad, resto del día libre para explorar Battery Park y los alrededores."
                            },
                            {
                                name: "3 - LUN - 7 de octubre",
                                description: "Excursión a Boston desde NY. Hora de salida: 06:00 AM. Visita los puntos más emblemáticos de Boston, como el Faneuil Hall, Quincy Market, Beacon Hill, y la Universidad de Harvard. Hora de regreso a Nueva York: 8:00 PM aproximadamente."
                            },
                            {
                                name: "4 - MAR - 8  de octubre",
                                description: "Tour del Alto y Bajo Manhattan. Hora de inicio: 09:00 AM. Recorrido por el Alto Manhattan pasando por el Central Park, Lincoln Center, Harlem, y la 5ta Avenida, continuación hacia el Bajo Manhattan con paradas en Greenwich Village, Soho, Chinatown, Little Italy, Wall Street, Memorial 911, y Battery Park, duración: 4 horas. Tarde libre para seguir explorando la ciudad."
                            },
                            {
                                name: "5 - MIE - 9 de octubre",
                                description: "Excursión a Washington desde NY. Hora de salida: 06:00 AM. Visita el Cementerio de Arlington, el Pentágono, la Casa Blanca, el Monumento a Lincoln, entre otros, visita al Museo del Aire y Espacio: Reserva entre las 14:00 y 15:30. Hora de regreso a Nueva York: 9:00 PM aproximadamente."
                            },
                            {
                                name: "6 - JUE - 10 de octubre",
                                description: "Tour de Contrastes. Hora de inicio: 08:00 AM. Recorre los barrios de Nueva Jersey, Bronx, Queens y Brooklyn, opción de cruzar el Puente de Brooklyn caminando al final del tour, duración: 5 horas. Tarde libre para explorar la ciudad."
                            },
                            {
                                name: "7 - VIE - 11 de octubre",
                                description: "Día libre en Nueva York, recomendaciones: Visitar Central Park, Times Square, algún museo como el MET o el MOMA."
                            },
                            {
                                name: "8 - SAB - 12 de octubre",
                                description: "Tour de Contrastes (Opción 2). Si decides repetir el tour o si no realizaste el tour el jueves, esta es otra oportunidad."
                            },
                            {
                                name: "9 - DOM - 13 de octubre",
                                description: "Salida de Nueva York. Check-out del hotel, traslado al aeropuerto."
                            }
                        ],
                        hotels: ["Hotel Edison", "Hotel Sheraton New York Times Square", "Hotel Hampton Inn Manhattan Times Square Central (Con desayunos)"],
                        notes: "Toma en cuenta que hay tours que NO acaban en el mismo hotel, sino que los pasajeros deben regresar al hotel por su cuenta. Los puntos de encuentro para los tours son los siguientes, NO recogen de todos los hoteles en Manhattan.\n" +
                            "Puntos de recogida: Sheraton New York Times Square,RIU plaza New York Times Square y The new Yorker hotel, los horarios de recojo varían dependiendo del tour.\n"

                    },
                    {
                        name: "Itinerario Disney World",
                        duration: "8 días",
                        background: "packages/dosney.webp",
                        days: [
                            {
                                name: "DÍA 1",
                                description: "Llegada a Orlando. Traslado compartido diurno desde el aeropuerto al hotel seleccionado y check-in. Alojamiento en Orlando."
                            },
                            {
                                name: "DÍAS 2 AL 5",
                                description: "Visita a los Parques de Disney (1 parque por día): Animal Kingdom, Magic Kingdom, Hollywood Studios, Epcot. Alojamiento en Orlando."
                            },
                            {
                                name: "DÍAS 5 Y 6",
                                description: "Visita a Universal Studios e Isla de la Aventura (con entrada Park-to-Park). Alojamiento en Orlando."
                            },
                            {
                                name: "DÍA 7",
                                description: "Día libre para actividades personales. Alojamiento en Orlando."
                            },
                            {
                                name: "DÍA 8",
                                description: "Check-out del hotel. Traslado compartido diurno al aeropuerto, salida de Orlando."
                            }
                        ],
                        hotels: ["Hotel Disney's All-Star Sports", "Hotel Residence Inn Orlando Lake Buena Vista (con desayuno)", "Hotel Hampton Inn Orlando Lake Buena Vista (con desayuno)"]
                    },
                ]
            },
            {
                name: "Estados Unidos Cruceros",
                flag: "usa.png",
                data: [
                    {
                        name: "Crucero - Opción 1 Nassau, Bahamas",
                        duration: "5 días",
                        departure: "Fort Lauderdale",
                        route: "Nassau, Bahamas / Disney Castaway Cay (Isla Privada) / Disney Lookout Cay At Lighthouse Point (Isla Privada)",
                        services_included: "Crucero, alimentación completa, té, café, leche, refrescos de dispensador, espectáculos, entretenimiento diario, acceso a diversas áreas públicas tales como gimnasio, impuestos, cargos de puerto.",
                        services_not_included: "Boletos aéreos, gaseosas, jugos recién exprimidos, seguro de asistencia y cancelación, excursiones, wifi, traslados, compras, propinas (usd 14.50 por persona/por noche), casino, servicios en el spa, y todo ítem no detallado como incluido, bebidas alcohólicas."
                    },
                    {
                        name: "Crucero - Opción 2 Nassau, Bahamas",
                        duration: "5 días",
                        departure: "Puerto Canaveral",
                        route: "Nassau, Bahamas / Disney Castaway Cay (Isla Privada) / Disney Lookout Cay At Lighthouse Point (Isla Privada)",
                        services_included: "Crucero, alimentación completa, té, café, leche, refrescos de dispensador, espectáculos, entretenimiento diario, acceso a diversas áreas públicas tales como gimnasio, impuestos, cargos de puerto.",
                        services_not_included: "Boletos aéreos, gaseosas, jugos recién exprimidos, seguro de asistencia y cancelación, excursiones, wifi, traslados, compras, propinas (usd 14.50 por persona/por noche), casino, servicios en el spa, y todo ítem no detallado como incluido, bebidas alcohólicas."
                    }
                ]
            }
        ]
    },
    europe: {
        name: "Europa",
        data: [
            {
                name: "Europa Express",
                data: [
                    {
                        name: "Sicilia Express",
                        duration: "4 días",
                        days: [
                            {
                                name: "01 - LUN. Palermo - Cefalú - Castelbuono - Tindaris - Milazzo",
                                description: "Saldremos de Palermo siguiendo la costa del mar Tirreno, disfrutando de bonitos paisajes. Nuestra primera parada es CEFALÚ, una encantadora ciudad costera. Su catedral Normanda, con su arquitectura original y mosaicos, ha sido declarada Patrimonio de la Humanidad. El centro histórico es de gran belleza, con sus pequeñas callejuelas, vistas al mar y su vida comercial y turística. Tras la hora del almuerzo, seguimos hacia el interior de la isla hasta CASTELBUONO, una pequeña y pintoresca población entre montañas que nos muestra la tranquilidad de los pueblos del interior, con sus murallas, castillo y pintorescas calles del centro. Continuamos nuevamente por la costa hacia TINDARIS, en lo alto de una montaña dominando el mar, donde se levanta el Santuario de Tindari, un famoso lugar de peregrinación con su imagen de la Virgen Negra y sus magníficas vistas. Muy próximo al santuario, se hallan los restos de la antigua polis griega. Continuamos hacia MILAZZO, una ciudad portuaria con bastante vida frente a las volcánicas islas Eolias."
                            },
                            {
                                name: "02 - MAR. Milazzo - Lipari - Vulcano - Taormina",
                                description: "Un día cargado de emociones mientras conocemos dos de las islas del ARCHIPIÉLAGO DE LAS EOLIAS, declaradas Patrimonio de la Humanidad por su continua actividad volcánica. Tomaremos un ferry que nos llevará a LIPARI, la mayor de las islas Eolias y la ciudad más agradable del archipiélago, con su casco histórico, su castillo y su vida turística. Tiempo para almorzar y pasear. Tras ello, tomaremos un nuevo ferry que en menos de 30 minutos nos llevará a VULCANO, donde nos impresionará el olor a azufre que impregna toda la isla y las activas fumarolas. En esta isla, dominada por su cráter, nos sorprenderán sus playas de aguas calientes, y recomendamos experimentar los baños en las aguas termales sulfurosas (su lodo se considera muy beneficioso para la piel). No deje de vivir esta experiencia única. Tras ello, nuevamente en ferry, regresamos a Milazzo (algo más de una hora de travesía) para desde allí continuar hacia TAORMINA, llegada al final del día. Nota: En verano o en fechas señaladas, el alojamiento podrá ser brindado en Messina en lugar de Taormina."
                            },
                            {
                                name: "03 - MIE. Taormina - Caltagirone - Villa Romana del Casale - Palermo",
                                description: "Al pie del volcán Etna, la hermosísima ciudad de TAORMINA fue definida por Churchill como el lugar más bonito de la tierra. Tiempo para pasear por el casco antiguo de la ciudad, lleno de vida; su teatro griego domina paisajes inolvidables. A media mañana continuamos nuestra ruta hacia el centro de la isla. CALTAGIRONE, ciudad declarada Patrimonio de la Humanidad por su gran legado barroco. Incluimos un paseo en trencito turístico para recibir explicaciones de la ciudad y conocer sus principales puntos. La ciudad es el centro de la cerámica en Sicilia, como lo atestiguan sus numerosas tiendas y talleres y algunos de sus lugares emblemáticos, el más conocido, la escalinata de 142 escalones, cada uno decorado con diferentes azulejos. Tras la hora del almuerzo continuamos a la VILLA ROMANA DEL CASALE (entrada incluida), Patrimonio de la Humanidad. Las villas, en el Imperio Romano, eran el símbolo de la explotación agraria. En El Casale, no solo se ha conservado la configuración arquitectónica del siglo IV, sino que, por su abundancia y calidad, los mosaicos que ornamentan casi todas las estancias son los más bellos del mundo romano. Atravesando el centro montañoso de la isla, seguimos hacia PALERMO, llegada al final del día."
                            },
                            {
                                name: "04 - JUE. Palermo",
                                description: "Incluimos una visita con guía local de la ciudad de PALERMO. Destaca la catedral (entrada incluida) con su mezcla de estilos y sus tumbas reales en su interior; el palacio de los Normandos (el antiguo palacio real), sus fuentes, mercados y jardines. Durante la visita, iremos también a MONREALE (entrada incluida), cuya catedral ha sido declarada Patrimonio de la Humanidad por sus impresionantes mosaicos dorados que cubren el interior. Tarde libre. Nota: En ocasiones, si el número de viajeros es muy limitado, la visita podrá no ser privada, sino realizarse con una excursión regular de compañías locales. Fin de nuestros servicios. Compruebe la hora de su vuelo por si necesitase una noche adicional."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro de viaje básico, alojamiento en hotel y desayuno tipo buffet incluido.\n" +
                            "Visita panorámica en: Palermo.\n" +
                            "Excursión a: Monreale desde Palermo.\n" +
                            "Entradas incluidas para: Villa Romana del Casale, Catedral de Palermo, y Catedral de Monreale.\n" +
                            "Paseo en tren por: Caltagirone.\n" +
                            "Ferry por: las Islas Eolias."
                    },
                    {
                        name: "Itinerario de Viaje: Barcelona y Valencia ",
                        duration: "4 días",
                        days: [
                            {
                                name: "Día 1 - Lunes / Jueves. Madrid - Zaragoza - Poblet - Barcelona",
                                description: "Salimos desde Madrid hacia Aragón atravesando las tierras de Castilla. Haremos una breve parada en Zaragoza para conocer la Basílica del Pilar. Tras la hora del almuerzo, continuamos hacia el Monasterio de Poblet, rodeado de murallas en un hermoso paraje; es uno de los monasterios medievales mejor conservados del mundo (entrada incluida). Continuación a Barcelona, con llegada al final de la tarde."
                            },
                            {
                                name: "Día 2 - Martes / Viernes. Barcelona",
                                description: "Por la mañana, incluimos una visita panorámica de la ciudad condal, considerada una de las capitales europeas más interesantes, abierta al mar Mediterráneo, famosa por Gaudí y su arquitectura modernista, sin olvidar sus ramblas y los recuerdos de las Exposiciones Universales y Olimpiadas que tuvieron lugar allí. Tarde libre. Por la noche, incluimos un traslado al Maremagnum, un vanguardista centro de ocio y restaurantes frente al mar, junto a la Plaza Colón, donde podrá pasear por esta animada zona y cenar."
                            },
                            {
                                name: "Día 3 - Miércoles / Sábado. Barcelona - Peñíscola - Valencia - Madrid",
                                description: "Viajamos hacia el sur siguiendo la costa mediterránea. Haremos una parada en Peñíscola, una pequeña ciudad medieval amurallada rodeada por hermosas playas. Tiempo libre para un paseo. Continuamos hacia Valencia, una ciudad portuaria de la costa levantina, donde llegaremos a la hora del almuerzo. ¿Probará su famosa paella? Sobre las 16:30 h, partimos y, tras realizar una breve parada en la vanguardista Ciudad de las Artes y las Ciencias de Valencia, continuaremos hacia Madrid. Llegada prevista alrededor de las 20:30 / 21:00 hrs. Nota: Si el número de viajeros de Valencia a Madrid es muy limitado, el viaje podrá efectuarse en tren de alta velocidad."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Visitas panorámicas en: Poblet, Barcelona.\n" +
                            "Traslado nocturno: Maremagnum en Barcelona.\n" +
                            "Entradas: Monasterio de Poblet.\n",
                    },
                    {
                        name: "País Vasco",
                        duration: "3 días",
                        days: [
                            {
                                name: "01 - SÁB. Madrid - Segovia - Pedraza - Bilbao",
                                description: "Salimos hacia tierras de Castilla, cruzando montañas y disfrutando de bonitos paisajes. Nuestra primera parada es SEGOVIA. Al circunvalar la ciudad, llegamos al Mirador de la Pradera de San Marcos, desde donde se obtienen las mejores vistas del Alcázar de Segovia y sus murallas, el cual inspiró a Walt Disney para el castillo de la bruja de la Bella Durmiente. Posteriormente, aparcamos junto al acueducto romano de Segovia, construido en el siglo I y en uso hasta 1973, actualmente declarado Patrimonio de la Humanidad por la UNESCO. Tendrá tiempo libre para caminar por el casco viejo de la ciudad, llegar a la Plaza Mayor y conocer la catedral y el Alcázar. Continuamos nuestra ruta por pequeñas carreteras que nos permiten apreciar los pueblos y paisajes en dirección a PEDRAZA, considerado uno de los pueblos más bonitos de España. Esta villa medieval amurallada cuenta con calles adoquinadas, edificios históricos, una Plaza Mayor y una fortaleza que data del siglo XIII. Tendrá tiempo para almorzar aquí. Por la tarde, seguimos nuestra ruta hacia el País Vasco y llegamos a BILBAO. Se recomienda un paseo por el casco viejo, conocido por sus numerosas tabernas y bares donde podrá degustar los famosos pintxos."
                            },
                            {
                                name: "02 - DOM. Bilbao - Portugalete - Guernica - San Sebastián",
                                description: "Hoy exploramos Bilbao y su ría, destacando el Museo Guggenheim con su impresionante arquitectura exterior, el casco antiguo con sus calles llenas de vida y la catedral. También visitaremos en Portugalete el Puente de Vizcaya (entrada incluida), declarado Patrimonio de la Humanidad por la UNESCO y conocido por ser el primer puente transbordador del mundo para peatones y vehículos. Tendrá tiempo libre para pasear y almorzar. Recomendamos el Mercado de La Ribera, que ofrece puestos con la típica gastronomía vasca en una estructura similar a un barco frente al río. Después del almuerzo, nos dirigimos a GUERNICA, conocido por ser el escenario de una de las peores masacres de la Guerra Civil Española, inmortalizada en la pintura de Pablo Picasso. Aquí se encuentra el Árbol de Guernika, bajo cuyas ramas se otorgó autonomía al País Vasco en la Edad Media. Continuamos hacia HONDARRIBIA (Fuenterrabía), un lugar con playas, murallas, un ambiente marinero y un centro histórico donde sorprende el trazado cuadriculado de sus calles angostas y adoquinadas. Tiempo para explorar el casco histórico. Finalmente, nos dirigimos a SAN SEBASTIÁN, llegando al final de la tarde. Se sugiere explorar la Parte Vieja, famosa por sus animados bares de pintxos y su ambiente vibrante, un destino popular para los amantes de la gastronomía. Cada establecimiento tiene su propia especialidad y estilo, lo que hace que probar diferentes pintxos sea una experiencia emocionante y deliciosa."
                            },
                            {
                                name: "03 - LUN. San Sebastián - Burgos - Madrid",
                                description: "Por la mañana en San Sebastián, incluimos la subida en funicular al Monte Igueldo, donde disfrutará de una magnífica vista sobre la bahía. Después, tendrá tiempo libre para disfrutar de la gran playa o pasear por sus bulevares. Después del almuerzo, viajaremos por una autopista con paisajes de gran belleza hacia Castilla, llegando a BURGOS. Tendrá tiempo para visitar el centro histórico, donde destaca la Catedral de Burgos, una joya del estilo gótico. Continuamos hacia MADRID, llegando al final del día. Fin de nuestros servicios. Compruebe la hora de su vuelo por si necesitase una noche adicional."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Visita panorámica en: Bilbao.\n" +
                            "Traslado: Mirador de la Pradera de San Marcos en Segovia.\n" +
                            "Entradas: Puente de Vizcaya en Portugalete.\n" +
                            "Funicular: Monte Igueldo en San Sebastián.\n"
                    },
                    {
                        name: "Cataluña y Francia",
                        duration: "4 días",
                        days: [
                            {
                                name: "Día 1 - Martes. Barcelona - Gerona - Besalú - Colliure - Carcasona",
                                description: "Viajamos hacia el norte de Cataluña. Llegada a Gerona, donde tendremos tiempo para explorar esta ciudad con un magnífico casco histórico, dominado por construcciones medievales y reminiscencias romanas, árabes y hebreas. Recomendamos visitar la Catedral de Santa María, un impresionante edificio gótico en la parte alta de la ciudad, y el antiguo barrio judío con sus calles estrechas y pintorescas. Continuamos hacia la pequeña ciudad medieval de Besalú, con tiempo para almorzar y pasear por sus callejas, su impresionante puente de piedra fortificado, su castillo, los baños judíos y el antiguo hospital de peregrinos. Después del almuerzo, continuamos hacia la Cataluña francesa. Llegada a Colliure, una pequeña población costera llena de encanto, con su playa, puerto, callejas y su impresionante castillo construido sobre un antiguo castrum romano, que alojó la corte de los Reyes de Mallorca. Si lo deseas, puedes visitar la tumba del poeta Antonio Machado. Al final de la tarde, llegada a Carcasona, considerada la ciudad mejor amurallada de Europa. Te sugerimos acercarte para ver las murallas iluminadas por la noche y cenar en alguno de sus cientos de pequeños restaurantes en la cité."
                            },
                            {
                                name: "Día 2 - Miércoles. Carcasona - Narbona - Marsella",
                                description: "Mañana en Carcasona. Con nuestro guía, visitaremos su impresionante castillo condal (entrada incluida) y sus murallas medievales. Tendrás tiempo libre posteriormente para pasear. Tras la hora del almuerzo, continuaremos hacia Narbona, donde tendremos tiempo para conocer esta histórica ciudad occitana con su pintoresco centro histórico. Al final de la tarde, llegamos a Marsella, la segunda ciudad de Francia. Recomendamos un paseo por su Vieux Port, lleno de vida y pequeños restaurantes."
                            },
                            {
                                name: "Día 3 - Jueves. Marsella - Aix en Provence - Avignon - Pont du Gard - Barcelona",
                                description: "Hoy descubriremos la región de Provenza. En Aix en Provence, ciudad natal del pintor Paul Cézanne, tendremos tiempo para pasear por su magnífico centro histórico, con sus pequeñas calles, fuentes y plazas pintorescas. Continuamos hacia Avignon, donde tendremos tiempo para caminar y almorzar en esta ciudad amurallada a orillas del Ródano, que fue sede de los papas católicos, convirtiéndose en la segunda Roma hasta que en 1791 se integró a Francia. Su inmenso Palacio de los Papas revela su pasado histórico. Seguimos hacia Pont du Gard (entrada incluida), un impresionante acueducto romano, Patrimonio Mundial de la UNESCO, uno de los monumentos romanos más famosos y mejor conservados del mundo. Continuamos nuestra ruta hacia Cataluña. Llegada a Barcelona al final del día."
                            },
                            {
                                name: "Día 4 - Viernes. Barcelona",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Visita panorámica en: Barcelona.\n" +
                            "Entradas: Castillo de Carcasona, Acueducto del Pont du Gard.\n"
                    },
                    {
                        name: "Oporto y Lisboa",
                        duration: "4 días",
                        days: [
                            {
                                name: "Día 1 - Sábado. Madrid - Ciudad Rodrigo - Oporto",
                                description: "Salida temprano desde Madrid hacia Castilla. Haremos una breve parada en el Mirador de los 4 Postes con una vista espectacular sobre las murallas de Ávila. Casi en la frontera con Portugal, nos detenemos en Ciudad Rodrigo para un paseo donde podremos conocer su catedral, muralla y calles medievales. Continuamos hacia Portugal. Llegada a Oporto y alojamiento. Realizaremos una visita panorámica de esta ciudad, la segunda más poblada de Portugal, conocida por sus vinos. Al final de la visita, conoceremos una bodega centenaria. Tiempo libre. Nota: Si el número de pasajeros entre Madrid y Oporto es muy reducido, este tramo podrá realizarse en avión, anulando la parada en Ciudad Rodrigo."
                            },
                            {
                                name: "Día 2 - Domingo. Oporto - Coimbra - Batalha - Nazaré - Óbidos - Lisboa",
                                description: "Viajamos hacia el sur. Tiempo en Coimbra, la tercera ciudad de Portugal, conocida por su antigua universidad y sus calles empinadas llenas de encanto. Después, nos detendremos junto al Monasterio de Batalha, un impresionante edificio medieval donde están enterrados algunos reyes. Tiempo para almorzar y pasear en Nazaré, una ciudad pesquera y balnearia. Incluimos un recorrido en el “elevador” que nos llevará a la ciudad antigua con vistas impresionantes del océano. Continuando hacia Lisboa, pararemos en Óbidos, uno de los pueblos más bonitos de Portugal, con sus calles blancas llenas de flores. Llegada a Lisboa al final de la tarde."
                            },
                            {
                                name: "Día 3 - Lunes. Lisboa",
                                description: "Por la mañana, realizamos una visita panorámica de la ciudad para conocer la capital portuguesa de las 7 colinas, sus callejuelas, azulejos, la “saudade” y la luz atlántica. También visitaremos el barrio de Belém, desde donde salieron los grandes navegantes portugueses que cruzaron los océanos en el siglo XV. Tarde libre para pasear e ir al Barrio Alto y su mirador. Si lo deseas, puedes realizar una excursión opcional a los pueblos de Sintra, Cascais y Estoril. Por la noche, regresaremos desde la Plaza de los Restauradores, llena de ambiente con sus bares y cafés, hasta el hotel."
                            },
                            {
                                name: "Día 4 - Martes. Lisboa",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Visitas panorámicas en Oporto y Lisboa.\n" +
                            "Traslado nocturno: Regreso desde la Plaza de los Restauradores en Lisboa.\n" +
                            "Entradas a las bodegas de vino en Oporto.\n" +
                            "Funicular en la Ciudad Antigua de Nazaré.\n"
                    },
                    {
                        name: "Triángulo Nórdico",
                        duration: "6 días",
                        days: [
                            {
                                name: "Día 1 - Domingo. Oslo",
                                description: "¡Bienvenidos! Tiempo libre. Al final de la tarde, recibirá información para el inicio de su circuito a través de los carteles informativos ubicados en la recepción del hotel."
                            },
                            {
                                name: "Día 2 - Lunes. Oslo - Orebro - Estocolmo",
                                description: "Incluimos una visita panorámica con guía local de la capital de Noruega, Oslo. Esta ciudad combina vibrante arquitectura moderna con edificios clásicos, un magnífico entorno paisajístico, y grandes parques con esculturas. Oslo ha sido nominada como la capital verde europea. Tras la visita, tiempo libre. Salida hacia Suecia. Durante el recorrido, disfrutaremos de paisajes de bosques y lagos. Haremos una parada en Orebro, una histórica ciudad junto a un hermoso lago, con un castillo y un bonito centro histórico. Llegada a Estocolmo al final del día, la capital de Suecia, una hermosa ciudad situada en el archipiélago del mar Báltico, conectada por numerosos puentes y transbordadores, conocida por su rica historia, impresionante arquitectura y su combinación de modernidad y tradición."
                            },
                            {
                                name: "Día 3 - Martes. Estocolmo",
                                description: "Realizaremos una visita panorámica de esta bellísima capital construida sobre 14 islas unidas entre sí. Visitaremos Gamla Stan o “Ciudad entre los Puentes”, el casco antiguo de la ciudad lleno de vida, donde se encuentran muchos de los edificios monumentales como el Museo Nobel, ubicado junto a la Plaza Mayor de Stortorget, que homenajea a los ganadores de los Premios Nobel, la Catedral y el Palacio Real. Cuando sea posible, podremos tener una vista espectacular desde el mirador de Fjällgatan. Tiempo libre. Recomendamos visitar sus museos y parques. Opcionalmente, sugerimos una visita al ayuntamiento y al buque-museo Vasa."
                            },
                            {
                                name: "Día 4 - Miércoles. Estocolmo - Helsingborg - Helsingor - Copenhague",
                                description: "Viajamos hacia el sur. Al comienzo de la tarde, tras un tiempo en la activa ciudad costera de Helsingborg, tomamos un pequeño ferry desde Suecia a Helsingor en Dinamarca. Tiempo libre en Helsingor, la ciudad de Hamlet, donde destaca el gran Castillo de Kronborg, antes de continuar a la próxima ciudad de Copenhague, llegada al final de la tarde."
                            },
                            {
                                name: "Día 5 - Jueves. Copenhague",
                                description: "Por la mañana, incluimos una visita panorámica con guía local de la capital danesa, conocida por su calidad de vida, respeto por la sostenibilidad y rica vida cultural. Visitaremos La Sirenita, el puerto de Nyhavn con sus edificios históricos coloridos, el exterior del Palacio de Amalienborg donde reside la familia real danesa, la Plaza del Ayuntamiento y la zona del Parque Tivoli. Tarde libre."
                            },
                            {
                                name: "Día 6 - Viernes. Copenhague",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Visitas panorámicas en: Oslo, Estocolmo, Copenhague.\n" +
                            "Ferry: de Suecia a Dinamarca.\n"
                    }
                ],
            },
            {
                name: "Europa 7 a 11 días",
                data: [
                    {
                        name: "Bellezas Italianas",
                        duration: "11 días",
                        days: [
                            {
                                name: "01 - Viernes. Roma - Termoli - Monte Sant'Angelo - Trani - Bari",
                                description: "¡Bienvenidos a su circuito de Europa! Hoy viajamos hacia el mar Adriático, con magníficos paisajes atravesando la cordillera de los Apeninos. Parada para conocer la Abadía San Giovanni in Venere, una bonita abadía del siglo XI construida sobre las ruinas de un antiguo templo romano. Llegada a Termoli, encantadora ciudad junto al mar cuya playa está dominada por el casco antiguo amurallado y donde destaca su castillo del siglo XIII y su catedral románica. Tiempo para almorzar y pasear. Continuamos a Trani, “la perla del Adriático”, magnífica ciudad que abraza el mar y muestra su rosada catedral románica. Llegada a Bari, con tiempo antes de ir al hotel para un paseo por el importante puerto, paseo marítimo y callejuelas del casco antiguo."
                            },
                            {
                                name: "02 - Sábado. Bari - Brindisi - Lecce - Tarento - Brindisi",
                                description: "Tras el desayuno, tenemos tiempo para pasear por el casco antiguo de Bari, ciudad portuaria habitada desde antes de la época romana. Sus calles estrechas, sus iglesias y su animada vida hacen de ella una ciudad muy especial. Continuamos hacia Brindisi, ciudad portuaria amurallada, donde podrá dar un agradable paseo junto al mar, conocer la impresionante columna romana y pasear por su barrio viejo. Nuestra siguiente parada es Lecce, encantadora ciudad conocida por su impresionante arquitectura barroca, que le ha valido el apodo de “Florencia del sur”. El centro histórico está repleto de edificios e iglesias barrocas bellamente ornamentados. Tiempo para pasear y almorzar. Por la tarde, conocemos Tarento, ciudad vibrante, imagen del sur de Italia (en sus costumbres, la vida, las personas, también en cierto abandono), con su gran castillo aragonés, su barrio antiguo y su catedral. Regresamos a Brindisi al final del día, alojamiento."
                            },
                            {
                                name: "03 - Domingo. Brindisi - Alberobello - Matera - Nápoles",
                                description: "Hoy conocemos dos espectaculares y originales poblaciones. Alberobello, encantador pueblo famoso por sus singulares casas de forma cónica conocidas como “trulli”. Estas estructuras únicas, con sus techos de piedra apilada, son Patrimonio de la Humanidad y se consideran un ejemplo sobresaliente de la arquitectura rural italiana. Tras ello continuamos a Matera, ubicada en la región de Basilicata, conocida por su antiguo centro histórico con sus “Sassi di Matera”, complejo de antiguas viviendas y edificios tallados en la roca que se remontan a miles de años. Este sitio es Patrimonio de la Humanidad y es uno de los asentamientos humanos continuamente habitados más antiguos del mundo. Tiempo para pasear y almorzar. Por la tarde, recorremos la Basilicata y la Campania, entre bonitos paisajes montañosos. Llegada a Nápoles al final de la tarde. Tiempo libre."
                            },
                            {
                                name: "04 - Lunes. Nápoles - Capri - Sorrento - Salerno",
                                description: "Tiempo libre por Nápoles antes de embarcar a la Isla de Capri donde si lo desean podrán tomar la lancha a la Gruta Blanca o el funicular a Capri capital. Tras la hora del almuerzo embarcamos nuevamente en dirección a Sorrento, población llena de encanto y vida turística al pie del acantilado. Tiempo para pasear. Continuamos al Golfo de Salerno. Alojamiento en Salerno."
                            },
                            {
                                name: "05 - Martes. Salerno - Paola - Messina - Taormina",
                                description: "Viajamos hacia el sur de “la bota”. Atractivos paisajes del sur de Italia atravesando el Parque Nacional de Cilento. Llegamos a Paola, a orillas del mar Tirreno, visitamos el santuario de San Francisco de Paola y tenemos tiempo libre para almorzar en su bonito centro histórico. Continuamos la ruta a Villa San Giovanni donde embarcamos en corta travesía hacia la isla de Sicilia. Llegada a Messina en donde tendremos tiempo para dar un paseo junto a la catedral.  Continuación a Taormina."
                            },
                            {
                                name: "06 - Miércoles. Taormina",
                                description: "Al pie del volcán Etna la hermosísima ciudad de Taormina fue definida por Churchill como el lugar más bonito de la tierra. Tiempo para pasear por el casco antiguo de la ciudad lleno de vida, su teatro griego domina paisajes inolvidables. Puede también disfrutar de la playa. Almuerzo y cena incluidas."
                            },
                            {
                                name: "07 - Jueves. Taormina - Noto - Siracusa - Agrigento",
                                description: "Viajamos hacia Noto, pequeña ciudad parada en el tiempo, joya barroca de Sicilia. Seguimos posteriormente hacia Siracusa, ciudad que por su potencia y esplendor fue durante un tiempo rival de Atenas y Roma. Tiempo para visitar su magnífico centro histórico y almorzar. Continuamos a Agrigentodonde desde el autocar veremos el magnífico conjunto del Valle de los Templos. Cena incluida."
                            },
                            {
                                name: "08 - Viernes. Agrigento - Marsala - Erice - Palermo",
                                description: "Con guía local conocemos el Valle de los Templos, impresionante recinto arqueológico considerado como el más completo recinto de templos griegos (entrada incluida). Viajamos a Marsala, histórica ciudad con mucho ambiente y famoso vino, tiempo para almorzar. Continuamos bordeando las salinas de Marsala hacia Erice, población muy pintoresca de origen fenicio que domina el mar. Tiempo para pasear. Continuamos a Palermo. Cena incluida."
                            },
                            {
                                name: "09 - Sábado. Palermo - Monreale - Travesía nocturna",
                                description: "Tras el desayuno visita de Palermo incluyendo un traslado al hermoso pueblo (hoy extrarradio de la ciudad) de Monreale, con su hermosa catedral árabe-normanda (entrada incluida), Patrimonio de la Humanidad, por sus impresionantes mosaicos dorados que cubren el interior. La capital siciliana es una ciudad llena de contrastes con historias de árabes, normandos y de esplendor Barroco. Destaca la Catedral (entrada incluida) y el Palacio de los Normandos con su capilla Palatina. Tiempo libre al finalizar la visita. Al final de la tarde embarque en un moderno ferry nocturno. Acomodación en camarotes dobles con baño."
                            },
                            {
                                name: "10 - Domingo. Nápoles - Pompeya - Cassino - Roma",
                                description: "Llegada a Nápoles a primera hora de la mañana (desayuno no incluido) y continuación a Pompeya donde podrá admirar los restos de esta ciudad romana que quedó cubierta por las cenizas del Vesubio con guía local (entrada y visita con guía local incluidas). Tras ello, conocemos Nápoles, una de las ciudades más fascinantes de Italia y una de las más antiguas y continuamente habitadas de Europa. El casco antiguo de Nápoles es uno de los mayores de Europa y ha sido declarado Patrimonio de la Humanidad por la UNESCO. Tiempo para pasear y almorzar. Continuación a Cassino, podrá conocer la inmensa abadía donde se desarrolló una de las más duras batallas de la Segunda Guerra Mundial. Llegada a Roma al final del día."
                            },
                            {
                                name: "11 - Lunes. Roma",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Visita panorámica en: Agrigento, Palermo, Pompeya.\n" +
                            "Traslado: Monreale en Palermo.\n" +
                            "Entradas: Entrada y visita al Valle de los Templos en Agrigento, catedral de Palermo, Catedral de Monreale, Pompeya.\n" +
                            "Ferry: Nápoles/Capri/Sorrento, San Giovanni/Messina en Sicilia, Palermo/Nápoles.\n" +
                            "1 Almuerzo Incluido en: Taormina.\n"
                    },
                    {
                        name: "España y Francia Clásica",
                        duration: "8 días",
                        days: [
                            {
                                name: "01 - Martes / Miércoles / Domingo. Barcelona",
                                description: "Bienvenidos. Traslado al hotel y tiempo libre. Recibirá en la tarde información para el inicio de su circuito, o bien, a través de los carteles informativos localizados en la recepción del hotel."
                            },
                            {
                                name: "02 - Miércoles / Jueves / Lunes. Barcelona - Madrid",
                                description: "Por la mañana incluimos una visita panorámica de Barcelona. Podrá usted admirar esta dinámica ciudad. Tiempo para pasear. A las 13:00 h, salida de Barcelona hacia Madrid. Parada en un área de servicio de la autopista para almorzar. Madrid, llegada sobre las 21:00 horas."
                            },
                            {
                                name: "03 - Jueves / Viernes / Martes. Madrid",
                                description: "Por la mañana incluimos una visita panorámica de la ciudad, donde conoceremos los puntos monumentales más representativos de Madrid. Resto del día libre. Opcionalmente le ofrecemos conocer la histórica ciudad de Toledo."
                            },
                            {
                                name: "04 - Viernes / Sábado / Miércoles. Madrid - San Sebastián - Burdeos",
                                description: "Salimos temprano de Madrid, pasando por tierras de Castilla con hermosos paisajes en el País Vasco. San Sebastián, llegada a la hora del almuerzo. Tiempo para almorzar y pasear por el casco histórico o por la hermosa playa de la Concha. Continuación de ruta hacia Francia. Burdeos, llegada al final de la tarde. Sugerimos un paseo por el centro de esta ciudad declarada Patrimonio de la UNESCO."
                            },
                            {
                                name: "05 - Sábado / Domingo / Jueves. Burdeos - Blois - París",
                                description: "Atravesamos la campiña francesa, tierras de vinos: Burdeos, Cognac. En el Valle del Loira paramos en Blois, ciudad junto al río, con su castillo, uno de los ejemplos más bellos de arquitectura en el valle del Loira. Tiempo para almorzar y pasear. Continuación a París, donde llegamos al final de la tarde."
                            },
                            {
                                name: "06 - Domingo / Lunes / Viernes. París",
                                description: "Por la mañana incluimos un traslado al Palacio de Versalles donde dispondrá de tiempo libre para pasear por sus hermosos jardines o visitar el interior del palacio si lo desea (para los grupos con visita el lunes este traslado se realizará el martes por cierre del palacio). Regresaremos a París a la hora del almuerzo y nos dirigiremos al barrio bohemio de Montmartre con sus pequeños restaurantes y sus artistas al aire libre. Tras el almuerzo, realizamos una visita panorámica de París con guía local. Regreso al hotel. Opcionalmente, podrá asistir a un espectáculo de variedades."
                            },
                            {
                                name: "07 - Lunes / Martes / Sábado. París",
                                description: "Día libre en París. Si lo desea, podrá realizar la actividad opcional de la visita del Barrio Latino y paseo en el río Sena."
                            },
                            {
                                name: "08 - Martes / Miércoles / Domingo. París",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno continental.\n" +
                            "Traslado de llegada.\n" +
                            "Visita panorámica en: Barcelona, Madrid, París.\n" +
                            "Excursión: Versalles (entrada no incluida al palacio).\n" +
                            "Traslado: Barrio de Montmartre en París.\n"
                    },
                    {
                        name: "Dulce Francia",
                        duration: "8 días",
                        days: [
                            {
                                name: "01 - Miércoles. París - Giverny - Rouen - Arromanches - Caen",
                                description: "Salida temprano de París. Viajamos hacia Normandía. Paramos en Giverny, donde incluimos la entrada a la casa de Monet y sus impresionantes jardines; hablaremos del arte impresionista, cuyo centro estuvo en Normandía. Tras ello, llegamos a Rouen, capital de Normandía, con sus calles medievales y su catedral. Tiempo para pasear y almorzar. Continuamos a Arromanches, donde en sus playas recordamos el desembarco en Francia durante la II Guerra Mundial. Luego, seguimos a Caen. Tiempo para un paseo por el centro. Nota: De noviembre a marzo, la casa de Monet se encuentra cerrada a las visitas, por lo que no se realizará parada en Giverny."
                            },
                            {
                                name: "02 - Jueves. Caen - Mont St Michel - St. Malo - Dinan - Rennes",
                                description: "Viajamos a primera hora hacia el Mont Saint Michel, conocido como la maravilla. Tiempo para conocer la ciudad rodeada del mar y la abadía. Posteriormente, viajamos a Saint Malo en Bretaña, donde potentes murallas defienden la ciudad. Tiempo para almorzar. Por la tarde, un paseo en Dinan, un encantador pueblo bretón. Continuamos hacia Rennes, la capital de Bretaña, con su interesante y monumental centro histórico."
                            },
                            {
                                name: "03 - Viernes. Rennes - Angers - Tours - Villandry - Amboise",
                                description: "Salimos hacia Angers; los que deseen pueden conocer el castillo que guarda el mayor tapiz medieval sobre el Apocalipsis. Continuamos hacia el Valle del Loira: Tours, capital regional a orillas del Loira. Tiempo para pasear y almorzar. Por la tarde, conocemos el Palacio de Villandry, famoso por sus jardines y sus flores. Incluimos la entrada a los jardines. Continuación a Amboise, ciudad con interesante barrio medieval a orillas del Loira. Cena incluida y alojamiento."
                            },
                            {
                                name: "04 - Sábado. Amboise - Chenonceau - Chambord - Limoges",
                                description: "Día para conocer más a fondo la región del Loira. Un paseo por Amboise a primera hora hasta la mansión de Clos Lucé, donde Leonardo da Vinci pasó sus últimos tres años de vida hasta su fallecimiento. Incluimos la entrada al Castillo de Chenonceau, en forma de puente sobre el río. Conocemos Chambord, el mayor de los castillos del Loira (entrada al parque incluida). Posteriormente, viajamos hacia el centro de Francia. Limoges, ciudad conocida por su porcelana. Sugerimos pasear por su centro histórico, donde destaca la Catedral de San Esteban."
                            },
                            {
                                name: "05 - Domingo. Limoges - Lascaux - Sarlat - Beynac - Rocamadour",
                                description: "Salimos de Limoges hacia la Dordoña, otro de los grandes ríos históricos en el centro de Francia. Pocas regiones contienen tantos pueblos llenos de encanto y paisajes inolvidables. Llegamos a Lascaux e incluimos la entrada al museo y réplica de las cuevas, donde aprenderemos sobre la prehistoria con las magníficas pinturas rupestres. Tras ello, conocemos Sarlat, ciudad patrimonio de la humanidad, que conserva un centro histórico renacentista increíblemente bien conservado. Tiempo para pasear y almorzar. En Beynac, conocemos un castillo medieval dominando el río (entrada incluida). Rocamadour, hermosísimo pueblo medieval con un importante santuario “colgado” en un barranco; sugerimos bajar caminando por el vía crucis y subir con su ascensor. Cena incluida."
                            },
                            {
                                name: "06 - Lunes. Rocamadour - Padirac - Collonges la Rouge - St. Emilion - Burdeos",
                                description: "Salimos a las impresionantes cuevas de Padirac, donde se realiza un paseo en barco a 75 metros de profundidad (entradas incluidas con paseo en barco por el río subterráneo). Una parada en Collonges la Rouge, un pintoresco pueblo construido con piedra roja en el Camino de Santiago. Tiempo para almorzar. Tras el almuerzo, viajamos a Saint Emilion, en el centro de la región productora de los vinos de Burdeos. Tiempo para pasear. Burdeos, llegada al final de la tarde. Nota: De noviembre hasta finales de marzo o por inclemencia meteorológica que lo justifique, las cuevas de Padirac pueden estar cerradas."
                            },
                            {
                                name: "07 - Martes. Burdeos - París",
                                description: "Todo el centro de Burdeos ha sido declarado patrimonio de la humanidad. Le sorprenderá el “espejo de agua” y paseará por el casco antiguo con sus calles peatonales y su catedral del siglo XI. Incluiremos la entrada (visita libre con cascos de información en español, francés o inglés) a La Cité du Vin, un impresionante museo interactivo que permite conocer la historia del vino y su producción. Degustación de un vino incluido en el mirador sobre Burdeos. Tendrá tiempo libre posteriormente para pasear y almorzar a la orilla del río Garona, una zona llena de pequeños restaurantes. Tras el almuerzo, sobre las 13:00 hrs, le acompañaremos a la estación de tren donde incluimos billete en moderno tren de alta velocidad. En poco más de dos horas recorrerá los 500 km que separan Burdeos de París. Llegada a París - estación y traslado al hotel."
                            },
                            {
                                name: "08 - Miércoles. París",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Barco: Cuevas de Padirac.\n" +
                            "Entradas: Casa y Jardines de Monet en Giverny, Jardines del Palacio de Villandry, Castillo de Chenonceau, Parque del Castillo de Chambord, Museo y réplica de las cuevas de Lascaux, Castillo de Beynac, Cuevas de Padirac, La Cité du Vin en Burdeos.\n" +
                            "Tren de alta velocidad: Burdeos - París en clase turista.\n" +
                            "2 cenas incluidas en: Amboise, Rocamadour.\n"
                    },
                    {
                        name: "Circuito por el Norte de España y Francia",
                        duration: "9 días",
                        days: [
                            {
                                name: "01 - Martes. Barcelona - Gerona - Besalú - Colliure - Carcasona",
                                description: "¡Bienvenidos a su circuito de Europamundo! Viajamos hacia el norte de Cataluña. Gerona, tiempo en esta ciudad con magnífico casco histórico dominado por construcciones medievales y reminiscencias romanas, árabes y hebreas. Sugerimos visitar la Catedral de Santa María, un impresionante edificio gótico en la parte alta de la ciudad, y el antiguo barrio Judío con sus calles estrechas y pintorescas. Continuamos hacia la pequeña ciudad medieval de Besalú; tiempo para almorzar y pasear por sus callejas, su impresionante puente de piedra fortificado, su castillo, los baños judíos y el antiguo hospital de peregrinos. Tras el almuerzo, continuamos hacia la Cataluña francesa. Colliure es una pequeña población costera llena de encanto, con su playa, su puerto, sus callejas y su impresionante castillo construido sobre un antiguo castrum romano, que alojó la corte de los reyes de Mallorca. Si lo desea, puede ver la tumba del poeta Antonio Machado. Al final de la tarde, llegada a Carcasona, considerada la ciudad mejor amurallada de Europa. Sugerimos acercarse para ver las murallas iluminadas por la noche y cenar en alguno de sus cientos de pequeños restaurantes en “la cité”."
                            },
                            {
                                name: "02 - Miércoles. Carcasona - Narbona - Marsella",
                                description: "Mañana en Carcasona. Con nuestro guía, iremos a su impresionante castillo condal (entrada incluida) y sus murallas medievales. Dispondrá de tiempo libre posteriormente para pasear. Tras la hora del almuerzo, seguiremos a Narbona, donde tendremos tiempo para conocer esta histórica ciudad occitana con su pintoresco centro histórico. Al final de la tarde, llegamos a Marsella, la segunda ciudad de Francia; recomendamos un paseo por su “Vieux Port”, lleno de vida y pequeños restaurantes."
                            },
                            {
                                name: "03 - Jueves. Marsella - Arles - Les Baux - Aviñón - Lyon",
                                description: "Pueblos, paisajes, historia: hoy conocemos algunos de los lugares más hermosos de la Provenza. Arles, ciudad Patrimonio de la Humanidad, muestra su legado de la época romana y medieval y nos recuerda a Van Gogh. En Les Baux, tenemos ocasión de conocer este pequeño pueblo medieval e incluimos la entrada a Carrieres de Lumière, un impresionante espectáculo de arte, imagen y sonido bajo tierra, en unas antiguas canteras. Tiempo para almorzar y pasear en Aviñón, con su inmenso palacio de los Papas. Continuamos hacia Lyon, donde podremos caminar por el barrio viejo, declarado Patrimonio de la Humanidad, e incluimos el funicular para subir a Notre Dame de Fourviere, una inmensa basílica desde donde tenemos una gran vista sobre la ciudad. Nota: Cuando por cierre o afluencia de público no sea posible obtener las entradas para el espectáculo de Carrieres de Lumière, como alternativa se dará un traslado al acueducto romano de Pont du Gard."
                            },
                            {
                                name: "04 - Viernes. Lyon - Tournus - Beaune - Castillo de Savigny - Dijon",
                                description: "Saldremos de Lyon hacia la Borgoña. Paramos en Tournus, una agradable pequeña ciudad con su inmensa puerta de piedra y su gran abadía. Tras ello, viajamos a Beaune. Tiempo para pasear y almorzar en la capital de los vinos de Borgoña. Podrá visitar el museo del vino o conocer el histórico hospicio. Después, visitamos en el centro de esta región vinícola el Castillo de Savigny, construido en el siglo XV, que alberga algunos museos extraordinarios (museo de motos, coches de carrera, aviones y helicópteros). Continuación a Dijon, capital de Borgoña, con sus animadas calles y el magnífico conjunto urbano con numerosos edificios medievales y renacentistas, destacando el palacio de los duques."
                            },
                            {
                                name: "05 - Sábado. Dijon - Besanzon - Colmar - Riquewihr - Ribeauville - Estrasburgo",
                                description: "Todavía en la región de Borgoña, paramos en Besanzon, ciudad de origen romano con un bonito centro histórico y cuya ciudadela del siglo XVII domina la ciudad. Continuamos nuestra ruta hacia Alsacia, una región francesa disputada a lo largo de los siglos entre Francia y Alemania, que mantiene un dialecto alemán propio, “el alsaciano”, y una arquitectura y tradiciones de gran arraigo germánico. Parada en Colmar, una ciudad con arquitectura única, con casas de madera de colores y canales que la atraviesan. Es conocida por su vino blanco y su gastronomía. Tendremos tiempo para almorzar y pasear con tranquilidad. Por la tarde, conocemos dos de los pueblos más hermosos de Alsacia: Riquewihr, un pueblo medieval con calles empedradas y casas de colores que parecen salir de un cuento de hadas, y Ribeauville, con su centro histórico y castillos en ruinas que dominan la población. Llegada a Estrasburgo al final de la tarde. Sugerimos pasear por la zona de la catedral y la Petite France, llena de lugares encantadores y pequeños restaurantes."
                            },
                            {
                                name: "06 - Domingo. Estrasburgo - Friburgo - Triberg - Gengenbach - Baden Baden",
                                description: "Tiempo en Estrasburgo, la capital de Alsacia. La ciudad es sede del Parlamento Europeo. Su centro histórico es bellísimo, con sus calles medievales pintorescas, su impresionante catedral gótica y sus canales y flores. Incluimos un paseo en barco por los canales de la Petite France. A media mañana, pasamos a Alemania para conocer los bonitos paisajes de montaña y pueblos de la Selva Negra. En Friburgo, tenemos tiempo para almorzar y pasear por esta ciudad universitaria con su centro medieval y su catedral. Entre muy bonitos paisajes, seguimos a Triberg, uno de los pueblos más pintorescos de la Selva Negra, conocido por sus relojes de cuco. Incluimos la entrada a las cataratas de Triberg, las más altas de Alemania, a las que se accede por una bonita senda de naturaleza. Continuamos hacia Gengenbach, un pequeño pueblo con su bellísima plaza central. Alojamiento en Baden-Baden, una ciudad balnearia que atrajo a nobles y alta burguesía de toda Europa; sus casinos, su arquitectura y sus parques nos lo recuerdan."
                            },
                            {
                                name: "07 - Lunes. Baden Baden - Espira - Heidelberg - Frankfurt",
                                description: "Un tiempo en Baden Baden para pasear por sus parques antes de salir hacia Renania-Palatinado. Espira, a orillas del Rin, su gran catedral románica es Patrimonio de la Humanidad; podrá conocerla y pasear por el barrio antiguo, donde destaca la torre de Alte Munze. Continuamos nuestra ruta hacia Heidelberg, donde en funicular (entrada incluida) subiremos a su impresionante castillo (entrada al parque exterior del castillo, bodegas y museo de la farmacia incluido), con grandes vistas sobre la ciudad. Tras ello, tiempo para almorzar y pasear por el casco antiguo y su Markt Platz. A media tarde, llegaremos a Frankfurt, una gran ciudad financiera con sus modernos rascacielos, su casco antiguo, las vistas sobre el río Rin y su conocida vida nocturna."
                            },
                            {
                                name: "08 - Martes. Frankfurt - Luxemburgo - Reims - París",
                                description: "Hoy viajamos hacia Francia pasando por el Gran Ducado de Luxemburgo, un pequeño país de habla alemana (dialecto luxemburgués) y francesa. Llegada a esta cosmopolita ciudad que combina una rica historia con la modernidad de ser un centro financiero y sede de instituciones europeas. Además de su casco antiguo, Patrimonio de la Humanidad, destacan sus fortificaciones, el Palacio Gran Ducal y la Catedral de Notre-Dame. Tras la hora del almuerzo, pasamos a Francia. En el centro de la región productora del Champagne, paramos en Reims; tiempo para conocer su impresionante catedral gótica, lugar donde se realizaron las coronaciones de muchos de los reyes de Francia. Llegada a París al final del día."
                            },
                            {
                                name: "09 - Miércoles. París",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Barco: Por la Petite France en Estrasburgo.\n" +
                            "Entradas: Castillo de Carcasona, Carrieres de Lumiere en Les Baux, Castillo medieval de Savigny, Cataratas de Triberg, Castillo de Heidelberg (incluyendo la farmacia y la bodega).\n" +
                            "Funicular: Lyon, Heidelberg.\n"
                    },
                    {
                        name: "Reino Unido y Francia",
                        duration: "9 días",
                        days: [
                            {
                                name: "01 - MAR / SAB. París",
                                description: "¡Bienvenidos! Traslado al hotel y tiempo libre. Recibirá en la tarde información para el inicio de su circuito, o bien, a través de los carteles informativos localizados en la recepción del hotel."
                            },
                            {
                                name: "02 - MIE / DOM. París",
                                description: "Entramos en contacto con la historia de Francia en el inmenso Palacio de Versalles, con sus bellísimos jardines. Entrada incluida. Tras ello, regresando a París, nos dirigimos a Montmartre, y tomando el funicular (incluido), subimos a este barrio bohemio conocido por sus pintores, sus artistas y sus cabarets. Almuerzo incluido antes de comenzar la visita panorámica de la ciudad con guía local, para conocer sus impresionantes avenidas y monumentos. Regreso al hotel. Opcionalmente, podrá asistir al espectáculo de variedades."
                            },
                            {
                                name: "03 - JUE / LUN. París - Rouen",
                                description: "Nos trasladamos a la Torre Eiffel; incluimos el billete de subida al segundo piso en este impresionante monumento metálico. Tras ello, navegamos por el río Sena, un pequeño crucero de aproximadamente una hora y cuarto. Tiempo libre posteriormente. Sobre las 16:00 hrs saldremos de París hacia Normandía. Rouen, tiempo libre para pasear por el centro histórico de la capital de Normandía, activa ciudad cuyo origen se remonta a época romana, con encantadora arquitectura medieval e impresionante catedral gótica. Nota: En ocasiones las esperas para subir a la Torre Eiffel son largas, la subida podrá efectuarse en un momento diferente (o el domingo) si se considera conveniente por motivos organizativos."
                            },
                            {
                                name: "04 - VIE / MAR. Rouen - Canterbury - Londres",
                                description: "Tiempo para un paseo por el centro de Rouen. Tras ello, viajamos hacia el Norte de Francia, donde en Calais tomamos uno de los modernos ferrys que cruzan el Canal de la Mancha. Almuerzo incluido. Ya en Inglaterra, paramos en Canterbury, capital religiosa de Inglaterra con un muy atractivo centro histórico. A nuestra llegada a Londres, antes de ir al hotel, tendrá tiempo libre en la zona de Tower Bridge, uno de los símbolos emblemáticos de la ciudad. Traslado al hotel y alojamiento."
                            },
                            {
                                name: "05 - SAB / MIE. Londres",
                                description: "Vamos a conocer Londres. Incluimos una visita panorámica con guía local para conocer su historia, la zona del Parlamento, el Támesis, sus calles llenas de vida. Pararemos junto al Buckingham Palace donde podremos contemplar el famoso “Cambio de Guardia” (en los días en que se celebre). Al anochecer, nos citaremos en un punto de encuentro en Piccadilly para dar a pie un paseo por Leicester Square, China Town y el Soho, con su ambiente, sus teatros y su animación. Cena incluida antes de regresar al hotel."
                            },
                            {
                                name: "06 - DOM / JUE. Londres - Cambridge - York - Durham",
                                description: "Salimos de Londres hacia el norte de Inglaterra. En Cambridge, disponemos de tiempo para admirar sus hermosos colegios mayores. Tras la hora del almuerzo seguimos a York, su catedral, su ambiente, sus murallas de origen romano, sus activas calles comerciales le dejarán un muy grato recuerdo. Tiempo para pasear. Tras ello, seguimos hacia el norte. Durham. Llegada a esta hermosa pequeña ciudad medieval llena de vida con su catedral frente al castillo."
                            },
                            {
                                name: "07 - LUN / VIE. Durham - Alnwick - Edimburgo",
                                description: "Viajamos hacia Escocia. Paramos en Alnwick, pintoresca ciudad con hermosos jardines y su gran castillo medieval donde se rodó en parte la película de Harry Potter. Seguimos posteriormente a Edimburgo, llegada a medio día. Nos encontramos en la capital de Escocia y una de las ciudades más activas del norte de Europa; el centro monumental ha sido declarado Patrimonio de la Humanidad por la UNESCO; el castillo domina la ciudad, sus empinadas calles, sus parques. Visita panorámica con guía local incluida."
                            },
                            {
                                name: "08 - MAR / SAB. Edimburgo - Pitlochry - Inverness - Lago Ness - Glasgow",
                                description: "Hoy tenemos una emocionante etapa paisajística por el norte de Escocia (las tierras altas escocesas). Tiempo para dar un paseo y un café en Pitlochry, pueblo con encanto. Paso por la ciudad de Inverness en el norte de Escocia, ubicada muy próxima al misterioso Lago Ness. Tras la hora del almuerzo incluimos la entrada en el castillo medieval de Urquhart desde donde embarcaremos posteriormente para dar un paseo en barco por las oscuras aguas del lago. Pasaremos por Fort Augustus con su sistema de esclusas y por Fort William, turística ciudad al pie del Ben Nevis, la más alta cumbre del Reino Unido. Regresamos hacia el sur por altas mesetas donde no es difícil contemplar la nieve y posteriormente contorneando el lago Lomond. Glasgow, llegada al final del día para dar un breve paseo por el centro y alojamiento. NOTA MUY IMPORTANTE: Los grupos llegando a Edimburgo desde el 15 de noviembre hasta final del mes de febrero, debido a la corta duración de los días y problemas frecuentes de nieve en las carreteras, la etapa se realizará la etapa siguiente: Salida de Edimburgo hacia Pitlochry, pintoresca ciudad en el centro de la región escocesa donde más whisky se produce. Conocemos en la zona una tradicional destilería de whisky (entrada incluida). Continuamos hacia Stirling, de impresionante centro histórico dominado por el gran castillo, uno de los más importantes de Escocia al haber sido testigo de numerosos eventos históricos, incluyendo no sólo batallas históricas, sino también la coronación de varios de sus reyes. Reserva y entradas incluidas. Tiempo para almorzar. Tras ello, paramos para fotografiar Los Kelpies, enormes esculturas de dos caballos con cabezas de agua de la mitología escocesa. Continuación a Glasgow, tiempo para pasear por el centro de la ciudad y alojamiento."
                            },
                            {
                                name: "09 - MIE / DOM. Glasgow",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        notes: "NOTA MUY IMPORTANTE: Los grupos llegando a Edimburgo desde el 15 de noviembre hasta final del mes de febrero, debido a la corta duración de los días y problemas frecuentes de nieve en las carreteras, la etapa se realizará la etapa siguiente:\n" +
                            "Salida de Edimburgo hacia Pitlochry, pintoresca ciudad en el centro de la región escocesa donde más whisky se produce. Conocemos en la zona una tradicional destilería de whisky (entrada incluida). Continuamos hacia Stirling, de impresionante centro histórico dominado por el gran castillo, uno de los más importantes de Escocia al haber sido testigo de numerosos eventos históricos, incluyendo no sólo batallas históricas, sino también la coronación de varios de sus reyes. Reserva y entradas incluidas. Tiempo para almorzar. Tras ello, paramos para fotografiar Los Kelpies, enormes esculturas de dos caballos con cabezas de agua de la mitología escocesa. Continuación a Glasgow, tiempo para pasear por el centro de la ciudad y alojamiento.",
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada.\n" +
                            "Visita panorámica en: París, Londres, Edimburgo.\n" +
                            "Excursión: Versalles en París.\n" +
                            "Barco: Por el río Sena en París, por el Lago Ness.\n" +
                            "Traslado nocturno: Tower Bridge en Londres, barrio del Soho en Londres.\n" +
                            "Traslado: Barrio de Montmartre en París, Museo Británico/Camden en Londres.\n" +
                            "Entradas: Palacio de Versalles y Torre Eiffel en París, Castillo de Urquhart.\n" +
                            "Ferry: Calais (FR) - Dover (UK).\n" +
                            "Funicular: Montmartre en París.\n" +
                            "2 Almuerzos incluidos en: París, Canal de la Mancha.\n" +
                            "1 Cena incluida en: Londres.\n"
                    }
                ],
            },
            {
                name: "Europa 12 a 18 días",
                data: [
                    {
                        name: "Europa Clásica",
                        duration: "14 días",
                        days: [
                            {
                                name: "Día 1 - Sábado. Madrid",
                                description: "¡Bienvenidos a Madrid! Traslado al hotel! Por la tarde, realizaremos una visita panorámica durante la cual conoceremos los puntos monumentales más representativos de la ciudad. Por la noche, incluimos un traslado a la Plaza Mayor, donde podremos cenar y tomar unos vinos en algún mesón."
                            },
                            {
                                name: "Día 2 - Domingo. Madrid - Toledo - Madrid",
                                description: "Incluimos una excursión a la histórica ciudad de Toledo, protegida por el río Tajo. Sus calles medievales nos hablan de historias de cristianos, judíos y musulmanes. Tarde libre en Madrid. Esta noche, si lo desea, le proponemos opcionalmente un espectáculo flamenco."
                            },
                            {
                                name: "Día 3 - Lunes. Madrid - Zaragoza - Barcelona",
                                description: "Salimos hacia Aragón atravesando tierras de Castilla. En Zaragoza nos detenemos brevemente para conocer la Basílica del Pilar. Llegada a Barcelona y breve panorámica en autocar por sus principales arterias para familiarizarnos con la capital catalana, abierta al mar Mediterráneo, famosa por Gaudí, su arquitectura modernista, sus ramblas, y los recuerdos de las Exposiciones Universales y Olimpiadas que allí tuvieron lugar. Nota: En ocasiones la visita puede realizarse en la mañana del día siguiente."
                            },
                            {
                                name: "Día 4 - Martes. Barcelona - Costa Azul",
                                description: "Tiempo libre en Barcelona, donde podrá caminar por el Barrio Gótico, Las Ramblas, y visitar la catedral. A las 12:00 h salimos hacia Francia y la Costa Azul atravesando la Provenza francesa. Parada en ruta para almorzar. Continuación a Niza, llegada al final del día. Nota: En algunas fechas el alojamiento podrá ser en un punto próximo a Niza en la Costa Azul."
                            },
                            {
                                name: "Día 5 - Miércoles. Costa Azul - Mónaco - Venecia",
                                description: "Esta mañana conocemos la Costa Azul; estaremos un breve tiempo en Niza para disfrutar del Paseo de los Ingleses. Viajamos entre impresionantes paisajes al Principado Independiente de Mónaco; en ruta conoceremos las técnicas de fabricación tradicional de perfumes. Continuamos entre magníficos paisajes por la Riviera Italiana, donde los Alpes se encuentran con el Mediterráneo. Llegada a Venecia al final del día. Alojamiento en la zona de Mestre."
                            },
                            {
                                name: "Día 6 - Jueves. Venecia",
                                description: "Por la mañana, incluimos un traslado en barco hasta el área de San Marcos en Venecia, una ciudad construida sobre más de 100 islas unidas por más de 400 puentes. Incluimos una visita con guía local, a pie, por esta zona de la ciudad de los canales. Tiempo libre. Por la tarde, puede animarse a dar un paseo en góndola."
                            },
                            {
                                name: "Día 7 - Viernes. Venecia - Verona - Lago de Garda - Innsbruck",
                                description: "Saldremos de Venecia hacia Verona, donde tendremos tiempo para conocer la ciudad de Romeo y Julieta con su importante anfiteatro romano. Posteriormente, bordearemos el Lago de Garda, uno de los más hermosos lagos alpinos. Tendremos tiempo en el hermoso pueblo de Malcesine, desde donde embarcamos en un barquito para llegar a Limone, un pintoresco pueblo rodeado de impresionantes paisajes de montañas que caen sobre el lago. Continuamos a Austria. Llegada a Innsbruck a media tarde con tiempo para conocer su bonito centro histórico."
                            },
                            {
                                name: "Día 8 - Sábado. Innsbruck - Múnich - Ratisbona - Praga",
                                description: "Después del desayuno, saldremos hacia Alemania, la región de Baviera. Paramos en Múnich, la capital bávara, conocida por su arquitectura barroca, cervecerías tradicionales, y bonitos parques. Sugerimos que conozca la Marienplatz y la catedral. Tiempo para almorzar. Seguimos hacia Ratisbona, una pequeña ciudad a orillas del río Danubio con un casco medieval declarado Patrimonio de la Humanidad. Tiempo para pasear. Luego, cruzaremos a la República Checa. Llegada a Praga al final del día."
                            },
                            {
                                name: "Día 9 - Domingo. Praga",
                                description: "Por la mañana, incluimos una visita panorámica de esta ciudad, una de las más hermosas de Europa, donde destacan el Castillo, la Plaza del Ayuntamiento y el Puente de Carlos. Finalizamos el día con un traslado nocturno al centro de la ciudad, lugar en el que se ubican muchas cervecerías tradicionales donde podrá cenar."
                            },
                            {
                                name: "Día 10 - Lunes. Praga - Lednice - Gyor - Budapest",
                                description: "Salimos temprano de Praga. En la región de Moravia, conocemos Lednice, una ciudad declarada Patrimonio de la Humanidad por la UNESCO. Tiempo para conocer sus jardines y admirar su castillo. Pasando por Eslovaquia, entramos en Hungría. Gyor, tiempo para almorzar y pasear por la ciudad de los cuatro ríos. Continuamos a Budapest, llegada a media tarde. Incluimos por la noche un traslado a la zona peatonal llena de pequeños restaurantes típicos, muchos con música."
                            },
                            {
                                name: "Día 11 - Martes. Budapest",
                                description: "Incluimos una visita panorámica de la ciudad atravesada por el Danubio. Visitaremos Buda con su casco viejo y Pest con sus zonas comerciales. Tarde libre."
                            },
                            {
                                name: "Día 12 - Miércoles. Budapest - Bratislava - Viena",
                                description: "Saldremos temprano de Budapest. Viajamos a Bratislava, la capital de Eslovaquia, una ciudad junto al Danubio con un atractivo núcleo histórico. Tiempo para conocer la ciudad y almorzar. Continuamos a Viena. A la llegada, incluimos una visita panorámica de Viena con guía local. Por la noche, incluimos un traslado a la plaza del Ayuntamiento."
                            },
                            {
                                name: "Día 13 - Jueves. Viena",
                                description: "Día libre en la capital austriaca. Podrán disfrutar del encanto de esta hermosa ciudad, pasear y descubrir lugares inolvidables."
                            },
                            {
                                name: "Día 14 - Viernes. Viena",
                                description: "Después del desayuno, fin de nuestros servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Traslado de llegada.\n" +
                            "Visitas panorámicas en: Madrid, Barcelona, Venecia, Praga, Budapest, Viena.\n" +
                            "Excursión: Toledo en Madrid.\n" +
                            "Barco: Tronchetto / Plaza de San Marcos / Tronchetto en Venecia, Malcesine / Limone / Malcesine en Lago di Garda.\n" +
                            "Traslado nocturno: Plaza Mayor en Madrid, Centro histórico de Praga, Centro histórico de Budapest, Plaza del Ayuntamiento en Viena.\n" +
                            "Entradas: Fábrica de cristal de Murano en Venecia.\n"
                    },
                    {
                        name: "Bellezas de Europa",
                        duration: "14 días",
                        days: [
                            {
                                name: "01 - VIE. Viena.",
                                description: "Bienvenidos. Traslado al hotel y tiempo libre. Recibirá información sobre el inicio del circuito en la tarde, ya sea a través de los carteles informativos localizados en la recepción del hotel."
                            },
                            {
                                name: "02 - SAB. Viena.",
                                description: "Incluimos una visita panorámica de la ciudad con guía local. Podrá conocer sus majestuosas avenidas, hermosos palacios, grandes parques y el centro histórico. Tarde libre. Por la noche, incluimos un traslado al barrio de Grinzing, un antiguo pueblo vitícola anexado a la ciudad, famoso por sus tabernas tradicionales. Cena incluida en una de ellas."
                            },
                            {
                                name: "03 - DOM. Viena - Budapest.",
                                description: "En aproximadamente 3 horas de viaje llegamos a BUDAPEST, la capital de Hungría. Nos dirigimos primero a la animada zona de Vaci Utca. Almuerzo incluido. Posteriormente, tenemos una excursión en barco para descubrir la ciudad desde el río Danubio, con vistas espectaculares de la histórica Buda en una orilla y la cosmopolita Pest en la otra. Después, comenzaremos la visita panorámica de la ciudad conocida como la Reina del Danubio. Tiempo libre. Por la noche, se ofrecerá asistir de manera opcional a un espectáculo Magyar con cena incluida."
                            },
                            {
                                name: "04 - LUN. Budapest - Bratislava - Praga.",
                                description: "Salimos temprano de Budapest hacia BRATISLAVA, la capital de Eslovaquia, una ciudad junto al Danubio con un atractivo núcleo histórico. Tiempo libre para conocer la ciudad y almorzar. Luego, continuamos hacia la República Checa, llegando a PRAGA. Al final de la tarde, incluimos un traslado a la zona de la Plaza de la Ciudad Vieja, llena de vida y actividad turística, una zona fantástica para conocer Praga. Cena incluida."
                            },
                            {
                                name: "05 - MAR. Praga.",
                                description: "Hoy exploraremos esta capital, una joya barroca en Europa. Realizaremos una visita panorámica con guía local, quien nos hablará de la ciudad y nos mostrará sus principales monumentos; durante la visita, entraremos en el Castillo de Praga (entrada incluida). Luego, incluimos un maravilloso paseo en barco por el río Moldava. Almuerzo incluido. Tarde libre."
                            },
                            {
                                name: "06 - MIE. Praga - Múnich - Innsbruck.",
                                description: "Salimos temprano y viajamos hacia Baviera, Alemania. LLegada a MUNICH al mediodía, con tiempo para un paseo por su centro histórico y para almorzar. Por la tarde, viajamos a Austria, llegando a INNSBRUCK. Tiempo para un paseo en la capital del Tirol, en el corazón de los Alpes. Esta noche, incluimos una cena típica con un espectáculo folclórico tirolés, lleno de colorido, ritmo y alegría."
                            },
                            {
                                name: "07 - JUE. Innsbruck - Venecia.",
                                description: "A través de hermosos paisajes alpinos, viajamos hacia Italia. En VENECIA, incluimos un traslado en barco hasta la zona de la Plaza de San Marcos, donde realizamos una visita a pie con guía local por la ciudad de los canales. También podrá admirar el trabajo del cristal de Murano. Almuerzo incluido. Tiempo libre posterior ¿un paseo en góndola? Alojamiento en la zona de Mestre."
                            },
                            {
                                name: "08 - VIE. Venecia - Lugano - Lucerna - Zúrich.",
                                description: "Salimos siguiendo el valle del Po hacia Suiza, en una etapa de gran belleza paisajística entrando en la región de los lagos alpinos. LLEGADA a LUGANO, tiempo para pasear en la capital del cantón suizo de habla italiana, a la orilla del gran lago. Después del almuerzo, cruzamos los Alpes por el túnel de San Gotardo. Llegada a LUCERNA a media tarde. Parada junto al León Herido y tiempo libre en esta hermosísima ciudad suiza a la orilla del lago de los Cuatro Cantones. Llegada a ZÚRICH al final de la tarde, con un paseo junto a su lago. Cena incluida."
                            },
                            {
                                name: "09 - SAB. Zúrich - Estrasburgo - París.",
                                description: "Salimos de Zúrich hacia la región francesa de Alsacia para conocer su capital, ESTRASBURGO, una ciudad encantadora que combina influencias francesas y alemanas. Tiempo libre para conocer la ciudad. Almuerzo incluido. Luego, seguimos nuestra ruta hacia PARÍS, llegando al final del día."
                            },
                            {
                                name: "10 - DOM. París.",
                                description: "Visita al Palacio de Versalles (entrada incluida), con sus bellísimos jardines. Luego, regresamos a París y nos dirigimos a Montmartre, subiendo con el funicular (incluido) a este barrio bohemio conocido por sus pintores, artistas y cabarets. Almuerzo incluido antes de comenzar la visita panorámica de la ciudad con guía local, para conocer sus impresionantes avenidas y monumentos. Regreso al hotel. Opcionalmente, podrá asistir a un espectáculo de variedades."
                            },
                            {
                                name: "11 - LUN. París - Rouen.",
                                description: "Nos trasladamos a la Torre Eiffel e incluimos el billete de subida al segundo piso de este impresionante monumento metálico. Después, navegamos por el río Sena en un pequeño crucero de aproximadamente una hora y cuarto. Tiempo libre posteriormente. Sobre las 16:00 hrs, salimos de París hacia Normandía. En ROUEN, tiempo libre para pasear por el centro histórico de la capital de Normandía, una ciudad activa con un origen que se remonta a la época romana, con encantadora arquitectura medieval e impresionante catedral gótica."
                            },
                            {
                                name: "12 - MAR. Rouen - Canterbury - Londres.",
                                description: "Tiempo libre para un paseo por el centro de Rouen. Luego, viajamos hacia el norte de Francia, donde en Calais tomamos uno de los modernos ferris que cruzan el Canal de la Mancha. Almuerzo incluido. Ya en Inglaterra, paramos en CANTERBURY, la capital religiosa de Inglaterra, con un muy atractivo centro histórico. Al llegar a LONDRES, antes de ir al hotel, tendrá tiempo libre en la zona de TOWER BRIDGE, uno de los símbolos emblemáticos de la ciudad. Traslado al hotel y alojamiento."
                            },
                            {
                                name: "13 - MIE. Londres.",
                                description: "Vamos a conocer Londres. Incluimos una visita panorámica con guía local para conocer su historia, la zona del Parlamento, el Támesis, y sus calles llenas de vida. Pararemos junto al Buckingham Palace, donde podremos contemplar el famoso Cambio de Guardia (en los días en que se celebre). Al anochecer, nos citaremos en un punto de encuentro en Piccadilly para dar un paseo a pie por Leicester Square, Chinatown y el Soho, con su ambiente, teatros y animación. Cena incluida antes de regresar al hotel."
                            },
                            {
                                name: "14 - JUE. Londres.",
                                description: "Por la mañana, visitamos el Museo Británico, uno de los principales y más antiguos museos del mundo, donde podremos ver las huellas de culturas de todo el mundo. A la hora del almuerzo, daremos un traslado a la zona de Camden, un distrito famoso por sus tiendas y mercados, donde podremos ver objetos de culturas de todo el mundo. Tarde libre. Fin de nuestros servicios"
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, hotel y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada.\n" +
                            "Visitas panorámicas en: Viena, Budapest, Praga, Venecia, París, Londres.\n" +
                            "Excursión: Versalles en París.\n" +
                            "Paseo en barco: Río Danubio en Budapest, paseo por el río Moldava en Praga, Tronchetto/Plaza de San Marcos/Tronchetto en Venecia, por el río Sena en París.\n" +
                            "Traslado nocturno: Barrio de Grinzing en Viena, centro histórico de Praga, Tower Bridge en Londres, barrio del Soho en Londres.\n" +
                            "Traslado: Barrio de Montmartre en París, Museo Británico/Camden en Londres.\n" +
                            "Entradas: Castillo de Praga, espectáculo tirolés, fábrica de cristal de Murano en Venecia, Palacio de Versalles y Torre Eiffel en París.\n" +
                            "Ferry: Calais (FR)-Dover (UK).\n" +
                            "Funicular: Montmartre en París.\n" +
                            "6 almuerzos incluidos en: Budapest, Praga, Venecia, Estrasburgo, París, Canal de la Mancha.\n" +
                            "5 cenas incluidas en: Viena, Praga, Innsbruck, Zúrich, Londres.\n"
                    },
                    {
                        name: "Cuento de hadas",
                        duration: "14 días",
                        days: [
                            {
                                name: "01 JUE. Roma.",
                                description: "Bienvenidos. Traslado al hotel y tiempo libre."
                            },
                            {
                                name: "02 VIE. Roma.",
                                description: "Visita panorámica incluida de la capital de Italia finalizando en la Plaza de San Pedro del Vaticano. Traslado nocturno al Trastévere."
                            },
                            {
                                name: "03 SAB. Roma.",
                                description: "Día libre."
                            },
                            {
                                name: "04 LUN. Roma - Florencia.",
                                description: "Viajamos hacia la Toscana. En Florencia, tras una parada en el Mirador de Miguel Ángel para ver la hermosa vista de la ciudad, realizamos una visita panorámica. Tarde libre."
                            },
                            {
                                name: "05 JUE. Florencia - Venecia.",
                                description: "Salimos de Florencia hacia el norte atravesando los Apeninos. Vamos al centro de Venecia en barco y realizamos una visita a pie de la ciudad de los canales donde también podrá ver la técnica tradicional de trabajo en vidrio. Alojamiento en Mestre."
                            },
                            {
                                name: "06 VIE. Venecia - Lugano - Zúrich.",
                                description: "Etapa de gran belleza paisajística ya en Suiza, con localidades entre lagos y Alpes. Parada en Lugano y Lucerna. Llegada a Zúrich al final de la tarde."
                            },
                            {
                                name: "07 SAB. Zúrich - Basilea - Colmar - Riquewihr - Ribeauville - Estrasburgo.",
                                description: "Almuerzo en Suiza, a orillas del Rin que marca históricamente la frontera entre Alemania y Francia, conocemos Basilea de precioso centro histórico y catedral. Entrada en Francia, descubriremos el corazón de la región de Alsacia, de personalidad propia y tradiciones de arraigo germánico. Pararemos en los preciosos pueblos de Riquewihr, Ribeauville y la capital, Estrasburgo."
                            },
                            {
                                name: "08 DOM. Estrasburgo - Friburgo - Triberg - Gengenbach - Baden Baden.",
                                description: "Conoceremos Estrasburgo dando un paseo en barco por los canales de la Petite France. Continuaremos nuestro viaje adentrándonos en la Selva Negra alemana y sus cuidados pueblos: Friburgo, Triberg, donde accederemos a las cataratas más altas de Alemania, Gengenbach y finalmente, nos alojaremos en la ciudad balneario de Baden-Baden."
                            },
                            {
                                name: "09 LUN. Baden Baden - Espira - Heidelberg - Frankfurt.",
                                description: "Recorreremos hermosos paisajes de viñedos y pueblos medievales a orillas del río Rin hasta Frankfurt. Pararemos en Espira, conocida por su antigua catedral. Tomaremos el funicular de Heidelberg desde donde disfrutaremos de espectaculares vistas además de visitar el castillo, sus bodegas y museo. Llegada a Frankfurt al mediodía tarde. Tiempo para pasear."
                            },
                            {
                                name: "10 MAR. Frankfurt - Crucero - Cochem - Colonia - Dortmund.",
                                description: "Hoy conoceremos el valle del Rin y del Mosela. Incluimos un crucero por el sector más bello del Rin. Almorzaremos en Cochem, a orillas del río Mosela. Siguiendo el río llegaremos a Colonia, tiempo para visitar su impresionante emblemática catedral. Alojamiento en Dortmund."
                            },
                            {
                                name: "11 MIE. Dortmund - Ruta de las hadas - Hannover.",
                                description: "Realizamos una etapa de magia recordando los cuentos de los Hermanos Grimm y que conforma la Ruta de los Cuentos de Hadas. Pasaremos por Marburg, con su Torre de Rapunzel (entrada incluida), por los bosques de Hansel y Gretel, visitaremos el castillo de Cenicienta en Polle, seguiremos los pasos del Flautista de Hamelin en Hamelin. Pararemos también en Hoster, pequeña ciudad con antiguo monasterio. Alojamiento en Hannover."
                            },
                            {
                                name: "12 JUE. Hannover - Goslar - Quedlinburg - Berlín.",
                                description: "Conocemos una de las regiones más bonitas de Alemania por sus pueblos medievales y paramos en Goslar y Quedlinburg preciosos pueblos históricos típicos de casas de entramados de madera y bellas plazas. Llegada a Berlín a mediodía de tarde."
                            },
                            {
                                name: "13 VIE. Berlín.",
                                description: "Visita panorámica de la capital de Alemania que incluye el Memorial del Holocausto y el Museo-memorial del Muro de Berlín."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada\n" +
                            "Barco: Tronchetto/Plaza de San Marcos/Tronchetto en Venecia, por el Valle del Rin.\n" +
                            "Visita Panorámica en: Roma, Florencia, Venecia, Berlín.\n" +
                            "Traslado Nocturno: Trastévere en Roma.\n" +
                            "Mirador de Michelangelo en Florencia.\n" +
                            "Entradas: Fábrica de cristal de Murano en Venecia, cataratas de Triberg, castillo de Heidelberg incluyendo la farmacia y la bodega, Torre de Rapunzel en Trendelburg, Memorial del Holocausto; Museo del Muro en Berlín.\n" +
                            "Funicular: Heidelberg."
                    },
                    {
                        name: "Europa Atlántico",
                        duration: "14 días",
                        days: [
                            {
                                name: "01 JUE. Mont Saint Michel - St. Malo - Dinan - Rennes.",
                                description: "Desembarcamos en Normandía, ya en Francia. Viajamos hasta el islote rocoso de Mont Saint Michel, una de las maravillas de Francia. Posteriormente, nos adentramos en Bretaña, parando en la ciudad amurallada de St. Malo y dando un paseo en Dinan. Nos alojamos en Rennes, capital de la región."
                            },
                            {
                                name: "02 VIE. Rennes - Angers - Tours - Villandry - Amboise.",
                                description: "Hoy nos recorreremos el Valle del Loira con interesantes pueblos y castillos en ruta. Paramos en Angers, a orillas del río. Seguiremos hacia Tours, capital de la región. Por la tarde conoceremos los jardines del Palacio de Villandry para luego ir a alojarnos a otra de las ciudades del valle, Amboise. Cena incluida."
                            },
                            {
                                name: "03 SAB. Amboise - Chenonceaux - Chambord - París.",
                                description: "Día para conocer más a fondo la región del Loira. Tras un paseo por Amboise, entraremos en el castillo de Chenonceau. Continuaremos a Chambord, el mayor de los castillos del valle. Llegada a París a media tarde."
                            },
                            {
                                name: "04 DOM. París.",
                                description: "Después del desayuno, fin de los servicios."
                            },
                            {
                                name: "05 LUN. París - Canterbury - Londres.",
                                description: "Salimos hacia el norte de Francia donde tomamos un ferry para cruzar el Canal de la Mancha y entrar en Reino Unido. Parada en Canterbury, lugar de enorme relevancia cultural, histórica y religiosa del país. Continuación a Londres. Llegada al final de la tarde."
                            },
                            {
                                name: "06 MAR. Londres.",
                                description: "Visita panorámica de Londres por la mañana y por la noche paseo por Leicester Square, China Town y el Soho."
                            },
                            {
                                name: "07 MIE. Londres.",
                                description: "Día libre para conocer la ciudad."
                            },
                            {
                                name: "08 JUE. Londres - Cambridge - York - Durham.",
                                description: "Salimos hacia el norte de Inglaterra y la hermosa ciudad universitaria de Cambridge. Seguimos a York donde podremos pasear por el centro e impactante catedral. Nos alojaremos en Durham, pequeña ciudad de origen medieval."
                            },
                            {
                                name: "09 VIE. Durham - Alnwick - Edimburgo.",
                                description: "Salimos tras el desayuno hacia Alnwick, con su castillo de estilo de rod Harry Potter. Entramos en Escocia. En Edimburgo, su capital declarada Patrimonio de la Humanidad, realizamos una visita panorámica. Alojamiento."
                            },
                            {
                                name: "10 SAB. Edimburgo - Pitlochry - Inverness - Lago Ness - Glasgow.",
                                description: "Hoy tenemos una emocionante etapa paisajística por el norte de Escocia. Tras una parada en Pitlochry, y el lago por Inverness, llegaremos al misterioso Lago Ness. Visitaremos el castillo medieval de Urquhart y luego haremos un paseo en barco por sus oscuras aguas. Siguiendo las carreteras de lagos y pequeñas poblaciones montañosas llegamos a Glasgow. Debido a las dificultades meteorológicas, la etapa en invierno, la etapa variará realizando dos paradas en Pitlochry, destilería de whisky, Stirling (entrada al castillo incluida) y los Kelpies antes de llegar a Glasgow."
                            },
                            {
                                name: "11 DOM. Glasgow - Cairnryan - Belfast - Dublín.",
                                description: "Salimos hacia el sur para tomar un ferry hacia Irlanda del Norte. Tiempo en Belfast, capital de este país perteneciente al Reino Unido, que nos recuerda su pasado de difícil coexistencia entre católicos y protestantes. Continuación hacia la República de Irlanda. Llegada a Dublín al final del día."
                            },
                            {
                                name: "12 LUN. Dublín.",
                                description: "Visita panorámica de Dublín. Tarde libre."
                            },
                            {
                                name: "13 MAR. Dublín - Caernarfon - Liverpool.",
                                description: "Por la mañana embarcamos en ferry para cruzar de Irlanda hacia el Reino Unido. Tras algo más de 3 horas de trayecto, llegamos a Gales. Conoceremos Caernarfon, con su impresionante castillo antes de continuar a Liverpool, cuna de los Beatles."
                            },
                            {
                                name: "14 MIE. Liverpool - Stratford - Oxford - Portsmouth.",
                                description: "Conocemos Stratford upon Avon, la ciudad de Shakespeare. Tras ello viajamos a Oxford, cuya vida pasa y recinte gira en torno a su prestigiosa Universidad. Continuación hacia Portsmouth para embarcar en un ferry y dirigirnos a Francia. Noche a bordo."
                            },
                            {
                                name: "15 JUE. Mont St Michel - Fougeres - Chartres - París.",
                                description: "Desembarcamos en Normandía, ya en Francia. Viajamos hasta el islote rocoso de Mont Saint Michel, una de las maravillas de Francia. En Bretaña pararemos en la ciudad medieval de Fougeres. Tras ello conoceremos la magnífica catedral gótica de Chartres, Patrimonio de la Humanidad. Continuación a París. Fin de los servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje y desayuno tipo buffet.\n" +
                            "Barco: Por el lago Ness.\n" +
                            "Visita Panorámica en: Londres, Edimburgo, Dublín.\n" +
                            "Traslado Nocturno: Soho en Londres.\n" +
                            "Entradas: Castillo de Urquhart en Lago Ness.\n" +
                            "Ferry: Calais (FR)-Dover (UK), Escocia - Irlanda, Irlanda - Gales, Portsmouth (UK) - Francia.",
                        services_additional: "Entradas: Jardines del Palacio de Villandry, Castillo de Chenonceau, Parque del Castillo de Chambord.\n" +
                            "1 Almuerzo o Cena Incluido en: Amboise."
                    },
                    {
                        name: "Europa Moderna",
                        duration: "15 días",
                        days: [
                            {
                                name: "01 - LUN. Lisboa.",
                                description: "Bienvenidos. Traslado al hotel y tiempo libre. Por la tarde, recibirá información sobre el inicio del circuito a través de los carteles informativos en la recepción del hotel."
                            },
                            {
                                name: "02 - MAR. Lisboa.",
                                description: "Por la mañana, visita panorámica de Lisboa, la capital portuguesa de las 7 colinas, con sus callejuelas, azulejos, y luz atlántica. Visitaremos el barrio de Belém, punto de partida de los navegantes portugueses del siglo XV. Tarde libre para explorar el Barrio Alto y su mirador. También se ofrece una excursión opcional a Sintra, Cascais y Estoril. Por la noche, regreso desde la Plaza de los Restauradores hasta el hotel."
                            },
                            {
                                name: "03 - MIE. Lisboa - Estremoz - Cáceres - Madrid.",
                                description: "Salida temprana de Lisboa hacia Madrid, pasando por la región de Alentejo y haciendo una parada en Estremoz. Luego, paso a España para visitar Cáceres, donde se puede almorzar y recorrer su centro histórico. Llegada a Madrid al final del día."
                            },
                            {
                                name: "04 - JUE. Madrid.",
                                description: "Visita panorámica de Madrid para conocer el centro, la Plaza Mayor, avenidas y el parque del Retiro. Incluye entrada al estadio Santiago Bernabéu. Tarde libre y traslado a la Plaza Mayor. Cena incluida."
                            },
                            {
                                name: "05 - VIE. Madrid - San Sebastián - Burdeos.",
                                description: "Salida hacia San Sebastián para pasear por la playa de la Concha. Almuerzo incluido. Paso a Francia y llegada a Burdeos al final de la tarde. Sugerencia de paseo por el centro de la ciudad."
                            },
                            {
                                name: "06 - SAB. Burdeos - Chambord - París.",
                                description: "Recorrido por la región del Loira. Visita al castillo de Chambord (entrada incluida). Almuerzo incluido. Llegada a París a media tarde."
                            },
                            {
                                name: "07 - DOM. París.",
                                description: "Visita al Palacio de Versalles (entrada incluida) y luego Montmartre con su funicular. Almuerzo incluido y visita panorámica de París con guía local. Regreso al hotel. Opción de asistir a un espectáculo de variedades."
                            },
                            {
                                name: "08 - LUN. París - Rouen.",
                                description: "Visita a la Torre Eiffel (subida al segundo piso incluida) y crucero por el río Sena. Salida hacia Rouen para explorar su centro histórico. Posible reprogramación de la visita a la Torre Eiffel por motivos organizativos."
                            },
                            {
                                name: "09 - MAR. Rouen - Canterbury - Londres.",
                                description: "Paseo por Rouen y luego cruce en ferry del Canal de la Mancha hasta Inglaterra. Visita a Canterbury y llegada a Londres, con tiempo libre en la zona de Tower Bridge. Traslado al hotel."
                            },
                            {
                                name: "10 - MIE. Londres.",
                                description: "Visita panorámica de Londres con guía local, pasando por el Parlamento, el Támesis, y el Palacio de Buckingham. Paseo por Piccadilly, Leicester Square, China Town y el Soho. Cena incluida."
                            },
                            {
                                name: "11 - JUE. Londres - Colchester - Travesía.",
                                description: "Visita al Museo Británico y traslado al barrio de Camden. Salida hacia Colchester y embarque en ferry para una travesía nocturna. Cena incluida."
                            },
                            {
                                name: "12 - VIE. Travesía - Volendam - Ámsterdam.",
                                description: "Llegada a Holanda, visita a Volendam y luego a Ámsterdam con recorrido panorámico. Traslado a Rembrandtplein. Cena incluida."
                            },
                            {
                                name: "13 - SAB. Ámsterdam - Hamelin - Berlín.",
                                description: "Viaje de Ámsterdam a Berlín, con parada en Hamelin. Almuerzo incluido. Llegada a Berlín al final del día."
                            },
                            {
                                name: "14 - DOM. Berlín.",
                                description: "Visita guiada de Berlín, incluyendo el centro histórico, la Puerta de Brandemburgo, y el Memorial del Holocausto. Cena incluida en la zona de Kantstrasse."
                            },
                            {
                                name: "15 - LUN. Berlín.",
                                description: "Fin del circuito después del desayuno."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español y portugués, seguro básico de viaje, alojamiento en hotel y desayuno tipo buffet.\n" +
                            "Incluye traslado de llegada.\n" +
                            "Visitas panorámicas en: Lisboa, Madrid, París, Londres, Ámsterdam, Berlín.\n" +
                            "Excursión: Versalles en París.\n" +
                            "Paseo en barco: Por el río Sena en París.\n" +
                            "Traslados nocturnos: Desde la Plaza de los Restauradores en Lisboa, Plaza Mayor en Madrid, Tower Bridge y el barrio del Soho en Londres, Rembrandtplein en Ámsterdam, y la zona de Kantstrasse en Berlín.\n" +
                            "Traslados incluidos a: Parque del Retiro en Madrid, barrio de Montmartre en París, Museo Británico/Camden en Londres.\n" +
                            "Entradas incluidas a: Estadio Santiago Bernabéu en Madrid, Parque del Palacio de Chambord, Palacio de Versalles y Torre Eiffel en París, fábrica de diamantes en Ámsterdam, Museo del Muro y Memorial del Holocausto en Berlín.\n" +
                            "Ferry: Calais (Francia) - Dover (Reino Unido), Harwich (Reino Unido) - Hoek van Holland (Países Bajos).\n" +
                            "Funicular: Montmartre en París.\n" +
                            "5 almuerzos incluidos en: San Sebastián, Chambord, París, Canal de la Mancha, Hamelin.\n" +
                            "5 cenas incluidas en: Madrid, Londres, Ferry Colchester Volendam, Ámsterdam, Berlín."
                    },
                    {
                        name: "Joyas Nórdicas",
                        duration: "15 días",
                        days: [
                            {
                                name: "01 - MAR. Copenhague.",
                                description: "Bienvenidos. Traslado al hotel y tiempo libre."
                            },
                            {
                                name: "02 - MIE. Copenhague - Rostock - Szczecin.",
                                description: "03 - JUE. Szczecin - Bydgoszcz - Torun - Varsovia."
                            },
                            {
                                name: "03 - JUE. Szczecin - Bydgoszcz - Torun - Varsovia.",
                                description: "Atravesando las tranquilas regiones del oeste de Polonia, haremos nuestra primera parada en Bydgoszcz, una histórica ciudad que conecta los ríos Vístula y Oder; su belleza, sus parques y canales invitan a un paseo. Continuaremos hasta la ciudad gótica amurallada de Torun, hoy Patrimonio de la Humanidad. Llegaremos a Varsovia, la capital de Polonia, al final del día."
                            },
                            {
                                name: "04 - VIE. Varsovia.",
                                description: "Visita panorámica de Varsovia, capital de Polonia."
                            },
                            {
                                name: "05 - SAB. Varsovia - Olstzynek - Canal de Elblag - Malbork - Gdansk.",
                                description: "Recorremos el norte de Polonia. En Olsztynek, visitaremos el parque-museo de arquitectura popular. Pararemos en el canal navegable de Elblag y su museo. En Malbork, entraremos en su impresionante castillo medieval de la orden teutónica. Alojamiento en Gdansk, donde sugerimos dar un paseo por su bonito centro histórico."
                            },
                            {
                                name: "06 - DOM. Gdansk - Sopot - Ferry.",
                                description: "En Gdansk, conoceremos el Centro Europeo de Solidarność y el museo de reciente inauguración sobre la Segunda Guerra Mundial, que nos recuerda el impacto tan terrible de esta guerra en el país. Tras un tiempo para pasear, nos dirigimos a la vecina Sopot, un elegante balneario sobre el mar Báltico, para embarcarnos en un ferry hacia Suecia. Disfrute del trayecto."
                            },
                            {
                                name: "07 - LUN. Ferry - Nynashamn - Estocolmo.",
                                description: "Sobre las 12:00 hrs del mediodía, llegada en ferry a Nynashamn, en Suecia. Continuación a Estocolmo. Llegada a la hora del almuerzo. Tarde libre, donde recomendamos pasear por su centro histórico y conocer sus museos."
                            },
                            {
                                name: "08 - MAR. Estocolmo.",
                                description: "Visita panorámica de Estocolmo, una bellísima capital construida sobre 14 islas."
                            },
                            {
                                name: "09 - MIE. Estocolmo - Ferry, Noche a bordo.",
                                description: "Día libre en Estocolmo. Al final de la tarde, embarcamos en ferry para hacer una travesía nocturna rumbo a Finlandia."
                            },
                            {
                                name: "10 - JUE. Turku - Naantali - Helsinki.",
                                description: "Disfrute de las costas finlandesas con cientos de islas desde la cubierta del ferry antes de desembarcar. Llegada a Turku y tiempo libre para pasear. Parada en Naantali, un bello pueblo típico nórdico. Continuación a Helsinki y tarde libre."
                            },
                            {
                                name: "11 - VIE. Helsinki.",
                                description: "Paseo en barco a las islas de Suomenlinna, protegidas por la fortaleza, hoy Patrimonio de la Humanidad. Tiempo libre."
                            },
                            {
                                name: "11 - VIE. Helsinki - Tallin.",
                                description: "Después del paseo en barco a las islas de Suomenlinna, tomamos un ferry hacia Estonia por la tarde. Disfrute de los paisajes costeros y marinos a la salida de Helsinki. Llegada a Tallin, la capital de Estonia."
                            },
                            {
                                name: "12 - SAB. Tallin.",
                                description: "Visita panorámica de Tallin, la capital de Estonia. Tarde libre."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español, seguro básico de viaje y desayuno tipo buffet.\n" +
                            "Incluye:\n" +
                            "Traslado de llegada.\n" +
                            "Barco: A las islas de Suomenlinna en Helsinki.\n" +
                            "Visita Panorámica en: Varsovia, Estocolmo.\n" +
                            "Entradas: Parque-Museo de Arquitectura Popular de Olsztynek, museo del Canal de Elblag, Castillo de Malbork, Centro Europeo de Solidarność y Museo de la II Guerra Mundial en Gdansk.\n" +
                            "Ferry: Gedser (Dinamarca) / Rostock (Alemania), Sopot (Polonia) / Nynashamn (Suecia), Estocolmo (Suecia) / Turku (Finlandia).\n",
                        services_additional: "Barco: A las islas de Suomenlinna en Helsinki.\n" +
                            "Visita Panorámica en: Tallin.\n" +
                            "Ferry: Helsinki (Finlandia) / Tallin (Estonia).\n" +
                            "Visita Panorámica en: Riga.\n" +
                            "Traslado: Memorial en Salaspils.\n" +
                            "Entradas: Castillo de Turaida.\n" +
                            "1 Almuerzo o Cena Incluido en: Turaida\n"
                    },
                    {
                        name: "Escandinavia Fantástico",
                        duration: "16 días",
                        days: [
                            {
                                name: "01 - JUE. Varsovia.",
                                description: "Bienvenidos. Traslado al hotel y tiempo libre."
                            },
                            {
                                name: "02 - VIE. Varsovia.",
                                description: "Visita panorámica de Varsovia, capital de Polonia."
                            },
                            {
                                name: "03 - SAB. Varsovia - Olstzynek - Canal de Elblag - Malbork - Gdansk.",
                                description: "Recorremos el norte de Polonia. En Olsztynek visitaremos el parque-museo de arquitectura popular. Pararemos en el canal navegable de Elblag y su museo. En Malbork, entraremos en su impresionante castillo medieval de la orden teutónica. Alojamiento en Gdansk, donde sugerimos dar un paseo por su bonito centro histórico."
                            },
                            {
                                name: "04 - DOM. Gdansk - Sopot - Ferry.",
                                description: "En Gdansk, conoceremos el Centro Europeo de Solidarność y el museo de reciente inauguración sobre la Segunda Guerra Mundial, que nos recuerda el terrible impacto de esta guerra en el país. Tras un tiempo para pasear, nos dirigimos a la vecina Sopot, elegante balneario sobre el mar Báltico, para embarcarnos en un ferry hacia Suecia. Disfrute del trayecto."
                            },
                            {
                                name: "05 - LUN. Ferry - Nynashamn - Estocolmo.",
                                description: "lrededor de las 12:00 hrs, llegada en ferry a Nynashamn, en Suecia. Continuación a Estocolmo. Llegada a la hora del almuerzo. Tarde libre, donde recomendamos pasear por su centro histórico y conocer sus museos."
                            },
                            {
                                name: "06 - MAR. Estocolmo.",
                                description: "Visita panorámica de Estocolmo, bellísima capital construida sobre 14 islas."
                            },
                            {
                                name: "06 - MAR. Estocolmo - Mora.",
                                description: "Tras el desayuno, realizaremos una visita panorámica de la ciudad. Tras ello, tiempo libre para pasear por esta ciudad de islas. Salida sobre las 16:00 hrs a Mora, pequeña ciudad entre lagos con un bonito paseo."
                            },
                            {
                                name: "07 - MIE. Mora - Funasdalen - Roros - Trondheim.",
                                description: "Entre lagos y bosques, salimos hacia Noruega. Parada en la popular estación de esquí de Funasdalen. Almuerzo en Roros, considerado uno de los pueblos más bonitos de Noruega. Llegada a Trondheim y tiempo para pasear en la tercera ciudad noruega, cuya historia se remonta a la era vikinga, donde destaca su catedral, puente y gran vida nocturna."
                            },
                            {
                                name: "08 - JUE. Trondheim - Kristiansund - Kvernes - Carretera del Atlántico - Molde.",
                                description: "Hoy tenemos una etapa de impresionantes paisajes de fiordos; algunos los atravesaremos en ferry, otros los recorreremos por la carretera junto al océano, considerada la más bonita de Noruega. ¡Prepare las fotos! Además, conoceremos Kristiansund, una preciosa ciudad en un hermoso entorno natural, visitaremos la iglesia de madera de Kvernes y nos alojaremos en Molde, ciudad llena de vida junto a su fiordo."
                            },
                            {
                                name: "09 - VIE. Molde - Geiranger - Briksdalsbre - Loen.",
                                description: "Nueva etapa de paisajes fantásticos por los fiordos noruegos, patrimonio de la humanidad. Tomaremos ferries, veremos cumbres nevadas y pararemos en el espectacular Mirador del Águila. Tiempo libre en Geiranger, uno de los lugares paisajísticos más bellos del mundo. Conoceremos Briksdalsbre, un impresionante glaciar nórdico. Alojamiento en la pequeña población de Loen. Cena incluida."
                            },
                            {
                                name: "10 - SAB. Loen - Bergen.",
                                description: "Siguiendo la orilla de los fiordos, entre montañas y ferries, llegamos a Bergen a la hora del almuerzo. Incluimos un funicular para alcanzar la montaña de Floyen, donde podremos contemplar vistas impresionantes sobre la ciudad. Tiempo libre."
                            },
                            {
                                name: "11 - DOM. Bergen - Eidfjord - Voringfossen - Geilo - Oslo.",
                                description: "Viajamos hacia el interior de Noruega y, antes de adentrarnos en los paisajes de altas mesetas frías, con lagos y neveros, paramos en la pequeña población de Eidfjord, al borde de su fiordo, y conoceremos la impresionante catarata de Voringfossen. Tiempo para almorzar en la estación de esquí de Geilo. Llegada a Oslo, capital de Noruega, al final de la tarde. Recomendamos un paseo por su centro."
                            },
                            {
                                name: "12 - LUN. Oslo - Ferry.",
                                description: "Tras el desayuno, visita panorámica de Oslo y tiempo libre para disfrutar. Tras el almuerzo, embarcamos en un ferry nocturno hacia Dinamarca. Noche a bordo."
                            },
                            {
                                name: "13 - MAR. Ferry - Copenhague.",
                                description: "Llegada a Copenhague por la mañana y, tras el desembarque, visita panorámica de la capital danesa. Tarde libre."
                            },
                            {
                                name: "14 - MIE. Copenhague - Rostock - Szczecin.",
                                description: "Desde Gedser, en el sur de Escandinavia, embarcamos en ferry para cruzar el mar Báltico hacia Alemania. Tiempo en Rostock, el mayor puerto alemán del Báltico con un bonito centro. Continuamos a Polonia para pasar la noche en Szczecin, histórica ciudad universitaria a orillas del Oder, con un hermoso castillo. Tiempo libre para pasear."
                            },
                            {
                                name: "15 - JUE. Szczecin - Bydgoszcz - Torun - Varsovia.",
                                description: "Atravesando tranquilas regiones del oeste de Polonia, haremos nuestra primera parada en Bydgoszcz, una histórica ciudad que conecta los ríos Vístula y Oder; su belleza, parques y canales invitan a un paseo. Continuaremos hasta la ciudad gótica amurallada de Torun, hoy patrimonio de la Humanidad. Llegaremos a Varsovia, capital de Polonia, al final del día."
                            },
                            {
                                name: "16 - VIE. Varsovia.",
                                description: "Después del desayuno, fin de los servicios."
                            }
                        ],
                        services_included: "Recorrido en autocar con guía en español, seguro básico de viaje y desayuno tipo buffet.\n" +
                            "Incluye:\n" +
                            "Traslado de llegada.\n" +
                            "Visita Panorámica en: Varsovia, Estocolmo.\n" +
                            "Entradas: Parque-Museo de Arquitectura Popular de Olsztynek, museo del Canal de Elblag, Castillo de Malbork, Centro Europeo de Solidarność y Museo de la II Guerra Mundial en Gdansk.\n" +
                            "Ferry: Sopot (Polonia) / Nynashamn (Suecia).\n",
                        services_additional: "Visita Panorámica en: Estocolmo, Oslo.\n" +
                            "Entradas: iglesia de madera de Kvernes.\n" +
                            "Ferry: Fiordo cerca de Kristiansund, Fiordo de Romsdal, Fiordo de Storjfjorden.\n" +
                            "Funicular: funicular a la montaña de Floyen en Bergen.\n" +
                            "1 Almuerzo o Cena incluidos en: Loen.\n" +
                            "Visita Panorámica en: Oslo, Copenhague.\n" +
                            "Ferry: Oslo / Copenhague.\n" +
                            "Ferry: Gedser (Dinamarca) / Rostock (Alemania).\n"
                    }
                ]
            }
        ]
    }
}

export {
    awards as awards_es,
    about_us as about_us_es,
    destinations as destinations_es,
    tours as tours_es,
    footer as footer_es,
    gastronomy as gastronomy_es,
    menu as menu_es,
    world_destinations as world_destinations_es,
}