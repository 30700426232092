import React from 'react';
import styles from './styles.module.css';

function Country({data, country, setCountry, index}) {
    return (
        <div key={index} className={`mx-2 ${styles.pill} ${data?.name === country?.name ? styles.selected : ''}`} onClick={() => setCountry(data)}>
            {data?.flag ? <div className={'me-2'}>
                <img alt={'flag'} src={`/flags/${data?.flag}`}/>
            </div> : <div style={{width:'8px', height:'8px'}}/>}
            <div>{data?.name}</div>
        </div>
    )
}

export default function CountryPills({selectedContinent, country, setCountry}) {
    return (
        <div className={styles.container}>
            <div>
                {selectedContinent?.data?.map((item, index) => (
                    <Country data={item} index={index} setCountry={setCountry} country={country} />
                ))}
            </div>
        </div>
    )
}